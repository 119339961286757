import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { isNotNull } from '../../helpers/utils';
import { FilterDataService } from '../../services/filter/filter-data.service';
import * as CountriesActions from '../countries/countries.actions';
import * as ResultCountActions from '../result-count/result-count.actions';
import { RootStoreService } from '../root-store.service';
import * as ComponentsActions from './components.actions';

@Injectable()
export class ComponentsEffects {
    public requestComponents$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CountriesActions.updateSelectedCountry),
            switchMap(() => this.rootStoreService.getSelectedCountry()),
            filter((selectedCountry) => isNotNull(selectedCountry)),
            distinctUntilChanged(),
            switchMap((selectedCountry) =>
                selectedCountry ? this.filterDataService.getComponentsByCountry(selectedCountry.id) : of([])
            ),
            withLatestFrom(
                this.rootStoreService.getRouterParams(),
                this.rootStoreService.getSelectedComponent(),
                (components, params, selectedComponent) => ({
                    components,
                    params,
                    selectedComponent,
                })
            ),
            tap(({ params, selectedComponent }) => {
                if (selectedComponent === null) {
                    const component = params.component;
                    if (component) {
                        this.rootStoreService.updateSelectedComponentByProperty(component, 'name');
                    }
                } else {
                    this.rootStoreService.updateSelectedComponentByProperty(selectedComponent.id, 'id');
                }
            }),
            map(({ components }) => ComponentsActions.receiveComponents({ payload: { components } }))
        )
    );

    public updateSelectedComponentByProperty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ComponentsActions.updateSelectedComponentByProperty),
            switchMap(({ payload: { value }, property }) =>
                this.rootStoreService.getComponents().pipe(
                    filter((components) => components.length > 0),
                    map((components) => {
                        const filteredComponents = components.filter(
                            (component) => component[property] === value
                        );

                        return filteredComponents.length ? filteredComponents[0] : null;
                    })
                )
            ),
            tap((selectedComponent) => {
                if (selectedComponent === null) {
                    this.rootStoreService.updateSelectedRegulationById(null);
                }
            }),
            switchMap((selectedComponent) => [
                ComponentsActions.updateSelectedComponent({ payload: { selectedComponent } }),
                ResultCountActions.calculateResultCount(),
            ])
        )
    );

    public replaceSelectedComponentByProperty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ComponentsActions.replaceSelectedComponentByProperty),
            switchMap(({ payload: { value }, property }) =>
                this.rootStoreService.getComponents().pipe(
                    filter((components) => components.length > 0),
                    map((components) => {
                        const filteredComponents = components.filter(
                            (component) => component[property] === value
                        );

                        return filteredComponents.length ? filteredComponents[0] : null;
                    })
                )
            ),
            switchMap((selectedComponent) => [
                ComponentsActions.updateSelectedComponent({ payload: { selectedComponent } }),
                ResultCountActions.calculateResultCount(),
            ])
        )
    );

    public constructor(
        private actions$: Actions,
        private filterDataService: FilterDataService,
        private rootStoreService: RootStoreService
    ) {}
}
