<div class="label-cell">
    {{ headerItem.longDescription }}
</div>
<osc-icon-button
    class="hint-icon"
    *ngIf="headerItem.infoText"
    icon="info"
    [tooltip]="'COMPARE.SHOW_HINTS' | translate"
    [oscCustomTooltip]="customTooltipContent"
>
</osc-icon-button>

<ng-template #customTooltipContent>
    <osc-compare-label-tooltip [tooltip]="$any(headerItem.infoText)"></osc-compare-label-tooltip>
</ng-template>
