import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { ModalWrapperComponent } from '../../components/modals/modal-wrapper/modal-wrapper.component';
import { ModalRef } from './modal-ref';
import { Constructor, MODAL_TOKEN, ModalWrapper } from './modal.helper';

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    public constructor(private overlay: Overlay, private injector: Injector) {}

    public openDialog<C extends Constructor>(modalWrapper: ModalWrapper<C>): ModalRef<C> {
        const modalWrapperWithDefaultValues = this.getModalWrapperWithDefaultValues(modalWrapper);

        return this.open<C>(modalWrapperWithDefaultValues);
    }

    private open<C extends Constructor>(modalWrapper: ModalWrapper<C>): ModalRef<C> {
        const overlayRef = this.overlay.create(this.getOverlayConfig());

        const modalRef = new ModalRef<C>(overlayRef, modalWrapper);

        const injector = this.createInjector(modalRef, this.injector);
        overlayRef.attach(new ComponentPortal(ModalWrapperComponent, null, injector));

        return modalRef;
    }

    private getOverlayConfig(): OverlayConfig {
        return new OverlayConfig({
            hasBackdrop: true,
            backdropClass: 'modal-backdrop',
            positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
        });
    }

    private createInjector(modalRef: ModalRef<any>, injector: Injector) {
        return Injector.create({
            providers: [
                {
                    provide: MODAL_TOKEN,
                    useValue: modalRef,
                },
            ],
            parent: injector,
        });
    }

    private getModalWrapperWithDefaultValues<C extends Constructor>(modalWrapper: ModalWrapper<C>) {
        const {
            type = 'info',
            open = true,
            size = 'm',
            closeButton = true,
            ...configuration
        } = modalWrapper.configuration;
        const modalWrapperWithDefaultValues: ModalWrapper<C> = {
            ...modalWrapper,
            configuration: {
                ...configuration,
                type,
                open,
                size,
                closeButton,
            },
        };

        return modalWrapperWithDefaultValues;
    }
}
