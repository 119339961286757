import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Constructor } from './constructor.helper';

interface HasOnDestroy {
    destroySubject: Subject<void>;
    ngOnDestroy(): void;
}

/**
 * Using mixin functions to allow our base class to extend multiple classes
 * https://blog.bitsrc.io/multiple-inheritance-or-typescript-mixins-10076c4f136a
 *
 * @param base
 * @returns
 */
export const mixinOnDestroy = <T extends Constructor>(base: T): Constructor<HasOnDestroy> & T =>
    class extends base implements OnDestroy {
        protected destroySubject = new Subject<void>();

        public constructor(...args: any[]) {
            super(...args);
        }

        public ngOnDestroy(): void {
            this.destroySubject.next();
            this.destroySubject.complete();
        }
    };

export const OnDestroyBase = mixinOnDestroy(class {});
