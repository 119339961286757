import { Inject, InjectionToken } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OAuthTokenScope } from '@os-components';
import { NEVER, Observable, combineLatest, race } from 'rxjs';
import { catchError, filter, startWith, timeout } from 'rxjs/operators';
import { FooterLink } from '../../interfaces/footer-links.interface';
import { HeaderConfigService } from '../../services/public-services';

type Environment = any & {
    defaultLang: string;
};

export const ENVIRONMENT = new InjectionToken<Environment>('Environment');

export class BaseComponent {
    public baseURL = '';
    public language = '';
    public caasBaseUrl = '';
    public apiBaseUrl = '';
    public contactButtonUrl = '';
    public countryCode = '';
    public footerLinks: FooterLink[] = [];
    public displayFooterBlogPost = false;
    public authConfig: OAuthTokenScope | undefined;

    public languageObservable: Observable<string>;

    public constructor(
        public sewConfigService: HeaderConfigService,
        public translateService: TranslateService,
        @Inject(ENVIRONMENT) public environment: Environment
    ) {
        this.authConfig = this.environment.auth;
        const cmsProject = this.sewConfigService.cmsProject.pipe(filter((project) => project.length > 0));

        // TODO: move language related stuff into LanguageService
        const languageChange = this.sewConfigService.language.pipe(filter((project) => project.length > 0));
        this.languageObservable = race(
            languageChange,
            NEVER.pipe(
                timeout(5000),
                catchError(() => languageChange.pipe(startWith(this.environment.defaultLang)))
            )
        ).pipe(filter((language) => language !== null));

        combineLatest([cmsProject, languageChange]).subscribe(([project, language]) => {
            this.contactButtonUrl = `${
                this.caasBaseUrl
            }/direct/${project}/contact-button?filter={'fs_language':'${language.toUpperCase()}'}`;
        });

        this.sewConfigService.countryShort.subscribe((countryShort) => (this.countryCode = countryShort));

        this.sewConfigService.projectMetaDataChange.subscribe(
            (projectMetaData) => (this.displayFooterBlogPost = projectMetaData.displayBlogPostFooter)
        );
    }

    protected setFooterLinks() {
        const licenseText = this.translateService.instant('GENERAL.LICENSES');
        this.footerLinks = [
            {
                text: licenseText,
                title: licenseText,
                url: '3rdpartylicenses.txt',
                target: '_blank',
            },
        ];
    }
}
