import { IconRegistryService } from './../../services/icon-registry/icon-registry.service';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'osc-product-image-display',
    templateUrl: './product-image-display.component.html',
    styleUrls: ['./product-image-display.component.scss'],
})
export class ProductImageDisplayComponent {
    /**
     * image source URL
     *
     * - `null`: show loader
     * - `''` (empty string): show "no image" placeholder
     * - `'<string>'`: show image at given URL
     */
    @Input() public imageUrl: string | null = null;

    public constructor(iconRegistry: IconRegistryService) {
        iconRegistry.addSvgIcon('gear-motor');
    }
}
