import { Directive, ElementRef, forwardRef, HostListener, Provider } from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from '@angular/forms';

interface RichTextNativeAttributes {
    bold?: boolean;
    italic?: boolean;
    underline?: boolean;
    link?: string;
}
interface RichTextNativeData {
    attributes?: RichTextNativeAttributes;
    insert: string | { image: string };
}
interface RichTextNativeResponse {
    ops: RichTextNativeData[];
}
interface RichTextResponse {
    json: RichTextNativeResponse;
    html: string;
}

const SEW_RICH_TEXT_VALUE_ACCESSOR: Provider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SewRichtTextDirective),
    multi: true,
};

const SEW_RICH_TEXT_VALIDATOR: Provider = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => SewRichtTextDirective),
    multi: true,
};

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: 'sew-richtext-editor',
    providers: [SEW_RICH_TEXT_VALUE_ACCESSOR, SEW_RICH_TEXT_VALIDATOR],
})
export class SewRichtTextDirective implements ControlValueAccessor, Validator {
    public val = '';
    public constructor(private elRef: ElementRef) {}

    @HostListener('richtextChange', ['$event.detail'])
    public onHostChange(data: RichTextResponse) {
        this.value = data.html;
    }

    // eslint-disable-next-line no-empty-function
    public onChange: any = () => {};

    // eslint-disable-next-line no-empty-function
    public onTouch: any = () => {};

    public validate(control: AbstractControl): ValidationErrors | null {
        return control.hasValidator(Validators.required)
            ? control.value !== '' || control.value !== null
                ? null
                : { empty: true }
            : null;
    }

    // eslint-disable-next-line no-empty-function
    public registerOnValidatorChange?(_fn: () => void): void {}

    private set value(value: string) {
        if (!value) {
            return;
        }

        this.val = value;

        this.elRef.nativeElement.value = value;

        this.onChange(value);
        this.onTouch(value);
    }

    public registerOnChange(fn: any) {
        this.onChange = fn;
    }

    public registerOnTouched(fn: any) {
        this.onTouch = fn;
    }

    public writeValue(value: string) {
        this.val = value;
        //DESCHEUS: WebComponent benötigt property "text" (standard in den Komponents ist aber 'eig' value)
        this.elRef.nativeElement.text = value;
        this.elRef.nativeElement.value = value;
    }
}
