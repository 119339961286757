import { createReducer, on } from '@ngrx/store';
import { Components, MarketComponent } from '../../models/data.types';
import * as ComponentsActions from './components.actions';

export const componentsFeatureKey = 'components';

export interface ComponentsState {
    components: Components;
    selectedComponent: MarketComponent | null;
}

export const initialState: ComponentsState = {
    components: [],
    selectedComponent: null,
};

export const reducer = createReducer(
    initialState,

    on(ComponentsActions.receiveComponents, (state, { payload: { components } }) => ({
        ...state,
        components,
    })),

    on(ComponentsActions.updateSelectedComponent, (state, { payload: { selectedComponent } }) => ({
        ...state,
        selectedComponent,
    })),

    on(ComponentsActions.replaceSelectedComponent, (state, { payload: { selectedComponent } }) => ({
        ...state,
        selectedComponent,
    }))
);
