import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { formatValue } from '../../../services/value-formatter/value-formatter.helper';
import { ValueFormatProps } from '../../../services/value-formatter/value-formatter.interface';
import { IconRegistryService } from './../../../services/icon-registry/icon-registry.service';

/**
 * Editable and readonly texts. If a text is editable you can toggle the view into an input and edit it.
 */
@Component({
    selector: 'osc-editable-text',
    templateUrl: './editable-text.component.html',
    styleUrls: ['./editable-text.component.scss'],
})
export class EditableTextComponent implements OnChanges {
    /**
     * Text value
     */
    @Input() public value!: string | string[];

    /**
     * Format properties for formatting the text in readonly state
     */
    @Input() public formatProps!: ValueFormatProps;

    @Input() public showWhiteSpace = false;

    /**
     * Event for text changes
     */
    @Output() public changeValue = new EventEmitter<string>();

    @ViewChild('editInput') public editInput!: ElementRef;
    public stringValue = '';
    public editing = false;

    public constructor(iconRegistry: IconRegistryService) {
        iconRegistry.addSvgIcon('edit', 'done');
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.value) {
            const newValue = changes.value.currentValue;
            if (Array.isArray(newValue)) {
                this.stringValue = newValue.join(',');
            } else {
                this.stringValue = newValue;
            }
        }
    }

    public startEditing() {
        this.editing = true;
        requestAnimationFrame(() => {
            this.editInput.nativeElement.focus();
        });
    }

    public stopEditing() {
        this.editing = false;
        this.value = this.stringValue;
        this.changeValue.emit(this.stringValue);
    }

    public get formattedValue() {
        return formatValue(this.value, this.formatProps);
    }
}
