import { Directive, Input } from '@angular/core';
import { provideMediaQuerySwitchCase } from '../../media-query.helper';
import { MediaQueryMatchPriority } from '../../media-query.interface';
import { mediaQueryMatch } from '../common/media-query-match.decorator';
import { MediaQueryToggleDirective } from '../common/media-query-toggle.directive';

/**
 * Defines one or more exact media queries in which the item should be displayed.
 *
 * Example:
 * Let's say you have 4 media queries: MQ1, MQ2, MQ3 and MQ4
 *
 * A value of `'MQ2'` will match *only* for MQ2 - but **not** for MQ1 and **not** for MQ3 and MQ4.
 *
 * A value of `['MQ2', 'MQ3']` will match MQ2 + MQ3, but not MQ1 and MQ4.
 *
 * A value of `['MQ1', 'MQ4']` will match MQ1 and MQ4, but not MQ2 and MQ3.
 */
@Directive()
abstract class MediaQueryExact extends MediaQueryToggleDirective {
    public abstract mediaQueryExact?: string | string[];

    public match(matchingMediaQueries: string[]): boolean {
        const matchMQ = this.mediaQueryExact;

        return (
            typeof matchMQ !== 'undefined' &&
            (Array.isArray(matchMQ)
                ? matchMQ.some((mq) => matchingMediaQueries.indexOf(mq) === 0)
                : matchingMediaQueries.indexOf(matchMQ) === 0)
        );
    }

    public get matchExpression() {
        return this.mediaQueryExact;
    }

    public get matchPriority() {
        return MediaQueryMatchPriority.EXACT;
    }
}

/**
 * @see {@link MediaQueryExact}
 */
@Directive({
    selector: '[oscMediaQuerySwitchExact]',
    providers: [provideMediaQuerySwitchCase(MediaQuerySwitchExactDirective)],
})
export class MediaQuerySwitchExactDirective extends MediaQueryExact {
    @Input('oscMediaQuerySwitchExact') public mediaQueryExact?: string | string[];
}

/**
 * @see {@link MediaQueryExact}
 */
@Directive({
    selector: '[oscMediaQueryExact]',
})
@mediaQueryMatch<MediaQueryExactDirective>('mediaQueryExact')
export class MediaQueryExactDirective extends MediaQueryExact {
    @Input('oscMediaQueryExact') public mediaQueryExact?: string | string[];
}
