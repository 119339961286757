import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeaderConfigService } from '@os-components';
import { combineLatest, Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

const cloneRequestHeaders = (
    request: HttpRequest<unknown>,
    addHeaders: { [key: string]: string },
    removeHeaders: string[] = []
) => {
    // Copy all headers from original request to the new request headers
    const newHeaders = request.headers
        .keys()
        .reduce((headers, header) => ({ ...headers, [header]: request.headers.get(header) as string }), {
            ...addHeaders,
        });

    for (const header of removeHeaders) {
        delete newHeaders[header];
    }

    return new HttpHeaders(newHeaders);
};

/**
 * Token for translate request intercepter to refresh requests after language changes
 */
export const TRANSLATE_REQUEST_HEADER = 'translateRequest';

/**
 * Header object for a request to indicate it as a translation request.
 */
export const getOptionsWithTranslateHeader = () => ({
    headers: {
        [TRANSLATE_REQUEST_HEADER]: 'true',
    },
});

/**
 * Interceptor to find requests with a translate header. After language changes it refreshes this requests.
 */
@Injectable({ providedIn: 'root' })
export class TranslateInterceptor implements HttpInterceptor {
    public constructor(public configService: HeaderConfigService) {}

    public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!request.headers.get(TRANSLATE_REQUEST_HEADER)) {
            return next.handle(request);
        }

        return combineLatest([this.configService.language, this.configService.cmsProject]).pipe(
            filter(([language, cmsProject]) => language.length > 0 && cmsProject.length > 0),
            switchMap(([language]) => {
                const filterKey = request.params.has('avars')
                    ? 'avars'
                    : request.params.has('filter')
                    ? 'filter'
                    : null;
                let filterParams = request.params.get(filterKey ?? '');

                if (!filterParams || filterKey === null) {
                    return next.handle(
                        request.clone({
                            headers: cloneRequestHeaders(request, {}, [TRANSLATE_REQUEST_HEADER]),
                        })
                    );
                }

                filterParams = filterParams.replace(
                    "'fs_language':'XX_XX'",
                    `'fs_language':'${language.toUpperCase()}'`
                );

                const newRequest = next.handle(
                    request.clone({
                        setParams: {
                            [filterKey]: filterParams,
                        },
                        headers: cloneRequestHeaders(request, {}, [TRANSLATE_REQUEST_HEADER]),
                    })
                );

                return newRequest;
            })
        );
    }
}
