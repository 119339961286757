import { Component, Input } from '@angular/core';
import { EventService } from '../../services/event.service';

@Component({
    selector: 'osc-control-panel',
    templateUrl: './control-panel.component.html',
    styleUrls: ['./control-panel.component.scss'],
})
export class ControlPanelComponent {
    @Input() public showDifferences = false;

    public constructor(private eventService: EventService) {}

    public toggleDifferences() {
        this.eventService.showOnlyDifferences(this.showDifferences);
    }
}
