import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateChildModule } from '../../helpers/translations/translate-child.module';
import { WithLoadingModule } from '../../pipes/public-pipes';
import { SecondaryButtonModule } from '../buttons';
import { InteractiveProductPreviewModule } from '../public-components';
import { ProductImageDisplayModule } from './../product-image-display/product-image-display.module';
import { ProductImageComponent } from './product-image.component';

/**
 * Module for loading and displaying a product image
 */
@NgModule({
    declarations: [ProductImageComponent],
    imports: [
        CommonModule,
        ProductImageDisplayModule,
        InteractiveProductPreviewModule,
        SecondaryButtonModule,
        TranslateChildModule,
        WithLoadingModule,
        MatProgressSpinnerModule,
    ],
    exports: [ProductImageComponent],
})
export class ProductImageModule {}
