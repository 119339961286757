<ng-container *ngIf="staticPictureUrl | withLoading | async as staticPictureUrl">
    <div
        [ngClass]="{
            large: large
        }"
    >
        <ng-container *ngIf="!staticPictureUrl.loading">
            <ng-container *ngIf="!showCadPreview">
                <osc-product-image-display
                    [imageUrl]="staticPictureUrl.value === undefined ? null : staticPictureUrl.value"
                ></osc-product-image-display>
                <div class="button-wrapper" *ngIf="enableCadPreview && staticPictureUrl.value !== ''">
                    <osc-secondary-button
                        (click)="turnOnCadPreview()"
                        [text]="'PRODUCT.DISPLAY_CAD_PREVIEW' | translate"
                    ></osc-secondary-button>
                </div>
            </ng-container>
            <osc-interactive-product-preview
                *ngIf="showCadPreview"
                [productID]="productID"
                (noPreviewAvailable)="showCadPreview = false; enableCadPreview = false"
            ></osc-interactive-product-preview
        ></ng-container>
    </div>
    <mat-spinner *ngIf="staticPictureUrl.loading" class="spinner" [diameter]="60"></mat-spinner>
</ng-container>
