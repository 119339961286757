<div
    *ngIf="!readonly; else readonlyTemplate"
    class="dropdown"
    role="combobox"
    #dropdownElement
    tabindex="0"
    [attr.aria-activedescendant]="
        this.dropdownOptions[this.selectedIndex] ? this.dropdownOptions[this.selectedIndex].templateId : null
    "
    (click)="toggleOpen()"
    (focusout)="!opened && toggleOpen(false)"
    (keydown.space)="$event.preventDefault(); toggleOpen(true)"
    (keydown.arrowup)="
        $event.preventDefault();
        updateSelection(selectedIndex === 0 ? dropdownOptions.length - 1 : selectedIndex - 1)
    "
    (keydown.arrowdown)="
        $event.preventDefault();
        updateSelection(selectedIndex === dropdownOptions.length - 1 ? 0 : selectedIndex + 1)
    "
>
    <ng-container
        *ngTemplateOutlet="
            optionTemplate;
            context: { $implicit: dropdownOptions[selectedIndex], readonly: true }
        "
    ></ng-container>

    <!-- TODO: use SVG icon -->
    <mat-icon class="icon" [class.icon_opened]="opened">keyboard_arrow_down</mat-icon>

    <div
        class="options"
        [class.-hidden]="!opened"
        (keydown.esc)="toggleOpen(false, true)"
        (keydown.space)="$event.preventDefault(); $event.stopPropagation()"
        (focusout)="checkClose($event.relatedTarget)"
    >
        <div
            *ngFor="let item of dropdownOptions; index as index; count as count; trackBy: trackOptionId"
            class="option"
            role="option"
            [id]="item.templateId"
            [attr.aria-selected]="selectedIndex === index"
            (click)="$event.stopPropagation(); updateSelection(index)"
            (keydown.enter)="updateSelection(index)"
            (keydown.tab)="updateSelection(index)"
            (keydown.shift.tab)="updateSelection(index)"
            oscDropdownItem
            tabindex="-1"
            (mouseenter)="focusElement(index)"
            (keydown.home)="focusElement(0, $event)"
            (keydown.end)="focusElement(count - 1, $event)"
            (keydown.arrowup)="focusElement(index === 0 ? count - 1 : index - 1, $event)"
            (keydown.arrowdown)="focusElement(index === count - 1 ? 0 : index + 1, $event)"
        >
            <ng-container
                *ngTemplateOutlet="
                    optionTemplate;
                    context: { $implicit: item, selected: selectedIndex === index }
                "
            ></ng-container>
        </div>
    </div>
</div>

<ng-template #readonlyTemplate>
    <ng-container
        *ngTemplateOutlet="
            optionTemplate;
            context: { $implicit: dropdownOptions[selectedIndex], readonly: true, selected: true }
        "
    ></ng-container>
</ng-template>
