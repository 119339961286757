import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCountries from './countries.reducer';

export const selectCountriesState = createFeatureSelector<fromCountries.CountriesState>(
    fromCountries.countriesFeatureKey
);

export const selectCountries = createSelector(
    selectCountriesState,
    (countriesState: fromCountries.CountriesState) => countriesState.countries
);

export const selectSelectedCountry = createSelector(
    selectCountriesState,
    (countriesState: fromCountries.CountriesState) => countriesState.selectedCountry
);
