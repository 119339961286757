import { Component, Input } from '@angular/core';

@Component({
    selector: 'osc-secondary-button',
    templateUrl: './secondary-button.component.html',
    styleUrls: ['./secondary-button.component.scss'],
})
export class SecondaryButtonComponent {
    @Input() public icon!: string;
    @Input() public text!: string;
    @Input() public disabled!: boolean;
}
