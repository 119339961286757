import { HttpParams } from '@angular/common/http';

/**
 * constructs the parameters for the http request
 *
 * @param params concatenated parameters
 * @returns complete parameter list
 */
export const getParams = (parameterName: string, additionalParams?: string) => {
    let params = getLanguageParam();

    if (additionalParams !== undefined) {
        params = `{${params},${additionalParams}}`;
    } else {
        params = `{${params}}`;
    }

    return new HttpParams().set(parameterName, params);
};

/**
 * resolves the language
 *
 * @returns language as a parameter
 */
export const getLanguageParam = () => "'fs_language':'XX_XX'";
