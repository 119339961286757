import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconRegistryService } from './../../../../services/icon-registry/icon-registry.service';
import { DropdownOption } from './../dropdown.interface';

@Component({
    selector: 'osc-icon-text-dropdown',
    templateUrl: './icon-text-dropdown.component.html',
    styleUrls: ['./icon-text-dropdown.component.scss'],
})
export class IconTextDropdownComponent {
    /**
     * Flag to enable editing
     */
    @Input() public editable = false;

    /**
     * Prefix for the translation keys
     */
    @Input() public translateKeyPrefix = '';

    /**
     * If the currently selected index is clicked, this event will be emmited again.
     * Default value is `false`.
     *
     * Introduced due to requirement #364136
     */
    @Input() public reEmitSelectedIndex = false;

    /**
     * Event for select changes
     */
    @Output() public selectedKeyChange = new EventEmitter<string>();

    /**
     * The selected key
     */
    @Input() public set selectedKey(key: string) {
        this.selectedKeyValue = key;
        this.updateSelectedIndex();
    }

    /**
     * the initial selected key
     */
    @Input() public set initialSelectedKey(key: string) {
        this.initialSelectedKeyValue = key;
        this.updateSelectedIndex();
    }

    /**
     * List of items for the select
     */
    @Input() public set items(newItems: readonly DropdownOption[]) {
        this.iconRegistry.addSvgIcons(newItems.map(({ icon }) => icon));

        this.dropdownOptions = newItems;
        this.updateSelectedIndex();
    }

    public dropdownOptions: readonly DropdownOption[] = [];
    public selectedIndex!: number;

    private initialSelectedKeyValue!: string;
    private selectedKeyValue!: string;

    public constructor(private iconRegistry: IconRegistryService) {}

    public selectedIndexChange(index: number) {
        this.selectedKeyChange.emit(this.dropdownOptions[index].key);
    }

    private updateSelectedIndex() {
        const findIndex = (key: string) => this.dropdownOptions.findIndex((item) => item.key === key);

        const newIndex = findIndex(this.selectedKeyValue);
        this.selectedIndex = newIndex !== -1 ? newIndex : findIndex(this.initialSelectedKeyValue);
    }
}
