import { Inject, Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export const INITIAL_LANGUAGE = new InjectionToken<string>('initial application language');

@Injectable({
    providedIn: null,
})
export class LanguageService {
    private currentLanguage = new BehaviorSubject<string>(this.initialLanguage);

    public constructor(@Inject(INITIAL_LANGUAGE) private initialLanguage: string) {}

    public set language(language: string) {
        this.currentLanguage.next(language);
    }

    public get language() {
        return this.currentLanguage.value;
    }

    public get onLanguageChange() {
        return this.currentLanguage.asObservable();
    }
}
