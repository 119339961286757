import { KeyValue } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    TrackByFunction,
} from '@angular/core';
import { takeUntil, tap } from 'rxjs/operators';
import { OnDestroyBase } from '../../helpers/public-helpers';
import { InteractiveProductPreviewService } from '../interactive-product-preview/interactive-product-preview.service';
import { StaticPictureService } from '../product-image/static-picture.service';
import { arrayEqualsShallow } from './../../helpers/arrays/array-equals-shallow';
import { getCompareData } from './compare.helper';
import {
    CompareDataResponse,
    CompareTable,
    MappedProductAttributesType,
    ProductChanges,
    ProductCompareDetail,
    RowHeader,
} from './compare.interface';
import { EventService } from './services/event.service';
import { PropertyInformationService } from './services/property-information.service';

type PropertiesInformation = KeyValue<string, string>[];

/**
 * Component for comparison of products.
 */
@Component({
    selector: 'osc-product-compare',
    templateUrl: './product-compare.component.html',
    styleUrls: ['./product-compare.component.scss'],
    providers: [EventService, PropertyInformationService, InteractiveProductPreviewService],
})
export class ProductCompareComponent extends OnDestroyBase implements OnChanges {
    /**
     * The data for the comparison
     */
    @Input() public compareData!: CompareDataResponse | null | undefined;

    /**
     * Prefix for the product backend requests (e.g. image or cad data).
     */
    @Input() public backendRequestPrefix = '';

    /**
     * Hide all product attributes and heading column.
     */
    @Input() public hideAttributes = false;

    /**
     * Event for reference product changes
     */
    @Output() public referenceProductChange = new EventEmitter<string>();

    /**
     * Event for product remove
     */
    @Output() public productRemove = new EventEmitter<string>();

    /**
     * Event for showOnlyDifferences changes
     */
    @Output() public showOnlyDifferencesChange = new EventEmitter<boolean>();

    /**
     * Event for product attribute changes (on editable attributes)
     */
    @Output() public attributeChange = new EventEmitter<ProductChanges[]>();

    /**
     * Event for adding a product to the shopping cart
     */
    @Output() public addToShoppingCart = new EventEmitter<string>();

    /**
     * Event to open a product
     */
    @Output() public productOpen = new EventEmitter<string>();

    public compareTable!: CompareTable;
    public showOpen = true;
    public showShoppingCart = true;
    public heightLarge?: boolean;
    public productsWithActiveCadPreview?: string[];

    private propertyInformation: PropertiesInformation = [];

    public constructor(
        eventService: EventService,
        private staticPictureService: StaticPictureService,
        private interactiveProductPreviewService: InteractiveProductPreviewService,
        private propertyInformationService: PropertyInformationService
    ) {
        super();
        eventService.changes.subscribe((changes) => this.attributeChange.emit(changes));
        eventService.openProductRequest.subscribe((productId) => this.productOpen.emit(productId));
        eventService.deletions.subscribe((productId) => this.productRemove.emit(productId));
        eventService.referenceProductChange.subscribe((productId) =>
            this.referenceProductChange.emit(productId)
        );
        eventService.showOnlyDifferencesChange.subscribe((differences) =>
            this.showOnlyDifferencesChange.emit(differences)
        );
        eventService.addToShoppingCartRequest.subscribe((productId) =>
            this.addToShoppingCart.emit(productId)
        );

        this.interactiveProductPreviewService.someProductHasActivatedCadPreview$
            .pipe(takeUntil(this.destroySubject))
            .subscribe((someProductHasActivatedCadPreview) => {
                this.heightLarge = someProductHasActivatedCadPreview;
            });

        this.interactiveProductPreviewService.productsWithActiveCadPreview$
            .pipe(takeUntil(this.destroySubject))
            .subscribe((productsWithActiveCadPreview) => {
                this.productsWithActiveCadPreview = productsWithActiveCadPreview;
            });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.compareData) {
            this.updateCompareTable(changes.compareData);
        }

        if (changes.backendRequestPrefix?.currentValue && this.backendRequestPrefix !== '') {
            this.staticPictureService.pathPrefix = this.backendRequestPrefix;
            this.interactiveProductPreviewService.pathPrefix = this.backendRequestPrefix;
        }

        this.showOpen = this.productOpen.observers.length > 0;
        this.showShoppingCart = this.addToShoppingCart.observers.length > 0;
    }

    public trackByProductId: TrackByFunction<ProductCompareDetail> = (_, item) => item.productId;

    private updateCompareTable({
        currentValue,
        previousValue,
    }: {
        currentValue: CompareDataResponse | null | undefined;
        previousValue: CompareDataResponse | null | undefined;
    }) {
        if (!currentValue) {
            return;
        }

        const mapToColId = (arr: RowHeader[]) => arr.map((header) => header.columnId);
        const equalRowHeaders = (current: RowHeader[], previous: RowHeader[]) =>
            arrayEqualsShallow(mapToColId(current), mapToColId(previous));

        this.compareTable = this.setPropertiesInformation(
            getCompareData(currentValue),
            this.propertyInformation
        );

        if (previousValue && equalRowHeaders(currentValue.rowHeadings, previousValue.rowHeadings)) {
            // headers unchanged, no need to fetch tooltips again
            return;
        }

        this.propertyInformationService
            .getProductPropertiesInformation(this.compareTable)
            .pipe(tap((propertyInformation) => (this.propertyInformation = propertyInformation)))
            .subscribe(
                (propertiesInformation) =>
                    (this.compareTable = this.setPropertiesInformation(
                        this.compareTable,
                        propertiesInformation
                    ))
            );
    }

    private setPropertiesInformation(
        compareTable: CompareTable,
        propertiesInformation: PropertiesInformation
    ): CompareTable {
        return {
            ...compareTable,
            rowHeadings: {
                ...compareTable.rowHeadings,
                ...[
                    MappedProductAttributesType.CUSTOMER,
                    MappedProductAttributesType.TECHNICAL,
                    MappedProductAttributesType.PROD_INFO,
                ].reduce(
                    (rowHeadings, current) => ({
                        ...rowHeadings,
                        [current]: compareTable.rowHeadings[current].map((attribute: RowHeader) => {
                            const infoText = propertiesInformation.find(
                                (information) => information.key === attribute.columnId
                            )?.value;

                            return infoText ? { ...attribute, infoText } : attribute;
                        }),
                    }),
                    {}
                ),
            },
        };
    }
}
