import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateChildModule } from '../../helpers/translations/translate-child.module';
import { DialogModule } from '../dialogs/dialog.module';
import { ApprovalStateModule } from '../form-fields/approval-state/approval-state.module';
import { BooleanAttributeModule } from '../form-fields/boolean-attribute/boolean-attribute.module';
import { EditableTextModule } from '../form-fields/editable-text/editable-text.module';
import { HeadlineModule } from '../headline/headline.module';
import { ListFilterModule } from '../list-filter/list-filter.module';
import { ProductImageModule } from '../product-image/product-image.module';
import { CustomTooltipModule } from './../../directives/custom-tooltip/custom-tooltip.module';
import { IconButtonModule } from './../buttons/icon-button/icon-button.module';
import { AttributesSectionComponent } from './components/attributes-section/attributes-section.component';
import { CompareAttributeComponent } from './components/compare-attribute/compare-attribute.component';
import { CompareLabelTooltipComponent } from './components/compare-label-tooltip/compare-label-tooltip.component';
import { CompareLabelComponent } from './components/compare-label/compare-label.component';
import { CompareProductControlBarComponent } from './components/compare-product-control-bar/compare-product-control-bar.component';
import { CompareProductHeaderComponent } from './components/compare-product-header/compare-product-header.component';
import { CompareProductInfoComponent } from './components/compare-product-info/compare-product-info.component';
import { ControlPanelComponent } from './components/control-panel/control-panel.component';
import { ProductCompareComponent } from './product-compare.component';
import { CAAS_CONFIG, CaasConfig, DOM_PARSER } from './services/property-information.interface';

/**
 * Module for comparison of one or more products.
 */
@NgModule({
    declarations: [
        ProductCompareComponent,
        CompareLabelComponent,
        CompareAttributeComponent,
        CompareProductHeaderComponent,
        CompareProductControlBarComponent,
        CompareProductInfoComponent,
        ControlPanelComponent,
        AttributesSectionComponent,
        CompareLabelTooltipComponent,
    ],
    imports: [
        CommonModule,
        MatSlideToggleModule,
        MatIconModule,
        ApprovalStateModule,
        EditableTextModule,
        HeadlineModule,
        ProductImageModule,
        DialogModule,
        BooleanAttributeModule,
        IconButtonModule,
        TranslateChildModule,
        CustomTooltipModule,
        ListFilterModule,
        FormsModule,
    ],
    exports: [ProductCompareComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ProductCompareModule {
    public constructor(@Optional() @SkipSelf() public parentModule: ProductCompareModule) {
        if (parentModule) {
            throw new Error('ProductCompareModule is already loaded. Import it once only!');
        }
    }

    /**
     *
     * @param config Object with the app name and the track events for this app to use the [TrackClickDirective]{@link TrackClickDirective}
     */
    public static forRoot(config: {
        caas: CaasConfig;
        domParser: DOMParser;
    }): ModuleWithProviders<ProductCompareModule> {
        return {
            ngModule: ProductCompareModule,
            providers: [
                { provide: CAAS_CONFIG, useValue: config.caas },
                { provide: DOM_PARSER, useValue: config.domParser },
            ],
        };
    }
}
