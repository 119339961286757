<ng-container [ngSwitch]="imageUrl">
    <div *ngSwitchCase="null" class="container">
        <mat-spinner class="spinner" [diameter]="60"></mat-spinner>
    </div>

    <div *ngSwitchCase="''" class="container">
        <mat-icon
            class="placeholder"
            svgIcon="gear-motor"
            [title]="'PRODUCT.NO_IMAGE_AVAILABLE' | translate"
        ></mat-icon>
    </div>

    <div *ngSwitchDefault class="picture">
        <img [src]="imageUrl" />
    </div>
</ng-container>
