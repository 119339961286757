const dateFormat = (language?: string) =>
    new Intl.DateTimeFormat(language, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });

const dateFormatWithTime = (language?: string) =>
    new Intl.DateTimeFormat(language, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    });

/**
 * DateFormat YYYY-MM-DD
 * Can be used in {@link formatDateWithFormat}
 */
export const dateFormatYearMonthDay = new Intl.DateTimeFormat('fr-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
});

/**
 * Format Date with a certain format
 *
 * @param date date to be formatted
 * @param format format to be used
 * @returns date as text
 */
export const formatDateWithFormat = (date: Date, format: Intl.DateTimeFormat): string => format.format(date);

export const formatDate = (date: Date | string, showTime = false, language?: string): string => {
    if (!date) {
        return '';
    }

    return formatDateWithFormat(
        typeof date === 'string' ? new Date(date) : date,
        showTime ? dateFormatWithTime(language) : dateFormat(language)
    );
};
