<sew-switch class="difference">
    <input
        type="checkbox"
        id="show-only-differences"
        [(ngModel)]="showDifferences"
        (change)="toggleDifferences()"
    />
    <label for="show-only-differences" class="off"></label>
    <label for="show-only-differences" class="on"></label>
</sew-switch>
<label for="show-only-differences" class="difference-label">{{
    'COMPARE.ONLY_DIFFERENT_ATTRIBUTES' | translate
}}</label>
<span class="only-print">
    {{ (showDifferences ? 'COMPARE.ONLY_DIFFERENT_ATTRIBUTES' : 'COMPARE.ALL_ATTRIBUTES') | translate }}:
</span>
