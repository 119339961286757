export const breakpoints = {
    mq2: '768px',
    mq3: '1280px',
    mq4: '1600px',
    mq5: '1920px',
    mq6: '2560px',
};

const minWidthQuery = (breakpoint: string) => `(min-width: ${breakpoint})`;

export const mediaQueries = {
    mq2: minWidthQuery(breakpoints.mq2),
    mq3: minWidthQuery(breakpoints.mq3),
    mq4: minWidthQuery(breakpoints.mq4),
    mq5: minWidthQuery(breakpoints.mq5),
    mq6: minWidthQuery(breakpoints.mq6),
};
