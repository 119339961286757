import { createReducer, on } from '@ngrx/store';
import { Countries, Country } from '../../models/data.types';
import * as CountriesActions from './countries.actions';

export const countriesFeatureKey = 'countries';

export interface CountriesState {
    countries: Countries;
    /**
     * selected country by user. The country is null in the initial state or when the user resets
     * the country.
     */
    selectedCountry: Country | null;
}

export const initialState: CountriesState = {
    countries: [],
    selectedCountry: null,
};

export const reducer = createReducer(
    initialState,

    on(CountriesActions.receiveCountries, (state, { payload: { countries } }) => ({
        ...state,
        countries,
    })),

    on(CountriesActions.updateSelectedCountry, (state, { payload: { selectedCountry } }) => ({
        ...state,
        selectedCountry,
    })),

    on(CountriesActions.replaceSelectedCountry, (state, { payload: { selectedCountry } }) => ({
        ...state,
        selectedCountry,
    }))
);
