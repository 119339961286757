<button
    *ngIf="displayShowAllButton"
    (click)="resetAllFilters()"
    class="reset-button"
    [disabled]="!activeFilterCount"
    translate
>
    LIST_FILTER.SHOW_ALL
</button>
<ng-content></ng-content>
