import { Component } from '@angular/core';
import { Language } from '@sew/ui-components/dist/types/components/website-components/sew-ws-header/types/language.types';
import { allAvailableCmsCountries } from '../../helper/language.helper';
import { PreviewDashboardService } from './preview-dashboard.service';

@Component({
    selector: 'reg-preview-dashboard',
    templateUrl: './preview-dashboard.component.html',
    styleUrls: ['./preview-dashboard.component.scss'],
})
export class PreviewDashboardComponent {
    public allAvailableCmsCountries = allAvailableCmsCountries;
    public allAvailableLanguages: Language[] = [];
    public selectedCountry = '';
    public selectedLangauge = '';

    public constructor(private previewDashboardService: PreviewDashboardService) {}

    public changeCountry(countryCode: string) {
        this.selectedCountry = countryCode;

        this.previewDashboardService
            .getAvailableLanguagesByCountryCode(countryCode.toLowerCase())
            .subscribe((countrySelector) => {
                this.allAvailableLanguages = countrySelector.languages;
            });

        this.selectedLangauge = '';
    }

    public changeLocalization() {
        const url = `${window.location.origin}/${this.removeTrailingSlash(
            window.location.pathname
        )}?country=${this.selectedCountry.toUpperCase()}&language=${this.selectedLangauge}`;
        window.location.href = url;
    }

    private removeTrailingSlash(inputString: string): string {
        if (inputString.endsWith('/')) {
            return inputString.slice(0, -1);
        } else {
            return inputString;
        }
    }
}
