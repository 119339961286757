import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DropdownOption } from '../dropdowns/dropdown.interface';
import { BooleanAttribute } from './boolean-attribute.interface';

/**
 * Boolean select component
 */
@Component({
    selector: 'osc-boolean-attribute',
    templateUrl: './boolean-attribute.component.html',
    styleUrls: ['./boolean-attribute.component.scss'],
})
export class BooleanAttributeComponent implements OnInit {
    /**
     * Flag to enable editing
     */
    @Input() public editable = false;

    /**
     * Value of the select
     */
    @Input() public value: 'X' | '' = '';

    /**
     * Event for select changes
     */
    @Output() public readonly valueChange = new EventEmitter<string>();

    /**
     * When BooleanAttributeComponent is used in filter, it adds a field "All"
     */
    @Input() public isFilter = false;

    public selectOptions: DropdownOption[] = [
        { key: 'X', textKey: BooleanAttribute.TRUE, icon: 'check', iconColor: '#6fc42d' },
        { key: '', textKey: BooleanAttribute.FALSE, icon: 'clear', iconColor: '#b40000' },
    ];

    public ngOnInit(): void {
        if (this.isFilter) {
            this.selectOptions.unshift({
                key: '**ALL**',
                icon: 'checklist',
                iconColor: '#4a535a',
                textKey: BooleanAttribute.ALL,
            } as DropdownOption);
        }
    }

    public selectedKeyChange(key: string) {
        this.valueChange.emit(key);
    }
}
