import { Component, Input } from '@angular/core';
import { ProductCompareDetail } from '../../compare.interface';

@Component({
    selector: 'osc-compare-product-header',
    templateUrl: './compare-product-header.component.html',
    styleUrls: ['./compare-product-header.component.scss'],
})
export class CompareProductHeaderComponent {
    @Input() public product!: ProductCompareDetail;
    @Input() public isLoggedIn = false;
}
