import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HeaderConfigService, OnDestroyBase } from '@os-components';
import { Country, MapState } from '@reg/models/data.types';
import { Labels } from '@sew/ui-components';
import { filter, first, takeUntil } from 'rxjs/operators';
import { RootStoreService } from '../../../../stores/root-store.service';

const initialMapConfig = {
    maxZoom: 15,
    minZoom: 2,
};

interface Marker {
    latitude: number;
    longitude: number;
    text: string;
    id?: string;
}

interface Zoom {
    prevZoom: number;
    currZoom: number;
}

@Component({
    selector: 'reg-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
})
export class MapComponent extends OnDestroyBase implements OnInit, AfterViewInit, OnDestroy {
    public labels?: Labels;
    public mapState?: MapState;
    public localization?: { region: string; language: string };

    public mapConfig = initialMapConfig;

    @ViewChild('sewMap') private sewMap!: ElementRef<HTMLSewMapElement>;

    private allCountries: Country[] = [];
    private selectedCountry: Country | null = null;
    private counter = 0;
    private markers: Marker[] = [];
    private isFirst = true;

    public constructor(
        private rootStoreService: RootStoreService,
        private configService: HeaderConfigService
    ) {
        super();
    }

    public ngOnInit(): void {
        this.configService.labelsChange
            .pipe(takeUntil(this.destroySubject))
            .subscribe((labels) => (this.labels = labels));

        this.configService.language
            .pipe(
                takeUntil(this.destroySubject),
                filter((language) => language.length > 0)
            )
            .subscribe((language) => {
                this.localization = {
                    language: language.split('_')[0],
                    region: language.split('_')[1],
                };
                if (!this.isFirst) {
                    // TODO: remove in future release with callback from map
                    setTimeout(() => {
                        this.loadMarkers();
                    }, 3000);
                }
                this.isFirst = false;
            });

        this.rootStoreService
            .getCountries()
            .pipe(takeUntil(this.destroySubject))
            .subscribe((countries) => {
                this.allCountries = countries;
            });

        this.rootStoreService
            .getSelectedCountry()
            .pipe(takeUntil(this.destroySubject))
            .subscribe((country) => {
                this.selectedCountry = country ?? null;
            });
    }

    public ngAfterViewInit(): void {
        const initConfig = {
            latitude: 24,
            longitude: 0,
            zoom: 3,
        };

        this.rootStoreService
            .getSelectedCountry()
            .pipe(takeUntil(this.destroySubject))
            .subscribe((country) => {
                if (!country) {
                    this.rootStoreService
                        .getCountries()
                        .pipe(
                            filter((countries) => countries.length > 0),
                            first()
                        )
                        .subscribe((countries) => {
                            if (countries.length) {
                                this.setContinentMarkers(countries);
                            }
                        });
                    this.sewMap.nativeElement.displayMapState(initConfig);

                    return;
                }
                this.selectedCountry = country;
                this.setCountryMarker(this.selectedCountry);
                this.sewMap.nativeElement.displayMapState({
                    latitude: country.coordinates[0],
                    longitude: country.coordinates[1],
                    zoom: country.zoom,
                });
            });

        this.updateCounter();
    }

    public zoomChanged(event: any) {
        if (this.selectedCountry) {
            return;
        }

        const zoom: Zoom = event.detail;

        if (zoom.currZoom >= 4) {
            this.setCountryMarkers(this.allCountries);
        } else {
            this.setContinentMarkers(this.allCountries);
        }
    }

    public markerClicked(event: any) {
        const marker: Marker = event.detail;
        const id = marker.id;
        if (id) {
            this.rootStoreService.updateSelectedCountryById(parseInt(id, 10));
        } else {
            this.sewMap.nativeElement.displayMapState({
                latitude: marker.latitude,
                longitude: marker.longitude,
                zoom: 4,
            });
        }
    }

    private setCountryMarkers(countries: Country[]) {
        let markers: Marker[] = [];
        countries.forEach((country) => {
            markers = [
                ...markers,
                {
                    latitude: country.coordinates[0],
                    longitude: country.coordinates[1],
                    text: country.count.toString(),
                    id: country.id.toString(),
                },
            ];
        });
        this.storeMarkers(markers);
        this.sewMap.nativeElement.displayMarkersAnimated(markers, 1000, 100);
    }

    private setCountryMarker(country: Country) {
        const marker = {
            latitude: country.coordinates[0],
            longitude: country.coordinates[1],
            text: this.counter < country.count ? this.counter.toString() : country.count.toString(),
        };
        this.setMarker(marker);
    }

    private setMarker(marker: Marker) {
        this.storeMarkers([marker]);
        this.sewMap.nativeElement.displayMarkersAnimated([marker], 1, 1);
    }

    private setContinentMarkers(countries: Country[]) {
        let countAfrica = 0;
        let countAsia = 0;
        let countAustralia = 0;
        let countEurope = 0;
        let countNorthAmerica = 0;
        let countSouthAmerica = 0;
        countries.forEach((country) => {
            switch (country.continent) {
                case 'Africa':
                    countAfrica = countAfrica + country.count;
                    break;
                case 'Asia':
                    countAsia = countAsia + country.count;
                    break;
                case 'Australia':
                    countAustralia = countAustralia + country.count;
                    break;
                case 'Europe':
                    countEurope = countEurope + country.count;
                    break;
                case 'North America':
                    countNorthAmerica = countNorthAmerica + country.count;
                    break;
                case 'South America':
                    countSouthAmerica = countSouthAmerica + country.count;
                    break;
                default:
                    break;
            }
        });
        const markers = [
            { latitude: 9.1021, longitude: 18.2812, text: countAfrica.toString() },
            { latitude: 34.0479, longitude: 100.6197, text: countAsia.toString() },
            { latitude: -25.2744, longitude: 133.7751, text: countAustralia.toString() },
            { latitude: 47.549183377277316, longitude: 11.492820773810783, text: countEurope.toString() },
            { latitude: 54.526, longitude: -105.2551, text: countNorthAmerica.toString() },
            { latitude: -8.7832, longitude: -55.4915, text: countSouthAmerica.toString() },
        ];
        this.storeMarkers(markers);
        this.sewMap.nativeElement.displayMarkers(markers);
    }

    private storeMarkers(markers: Marker[]) {
        this.markers = markers;
    }

    private loadMarkers() {
        this.sewMap.nativeElement.displayMarkersAnimated([...this.markers]);
    }

    private updateCounter() {
        this.rootStoreService
            .getResultCount()
            .pipe(takeUntil(this.destroySubject))
            .subscribe((count) => {
                this.counter = count;
                if (!this.selectedCountry) {
                    return;
                }

                this.setMarker({
                    latitude: this.selectedCountry?.coordinates[0],
                    longitude: this.selectedCountry?.coordinates[1],
                    text: count.toString(),
                });
            });
    }
}
