import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RowHeader } from '../compare.interface';

export interface CheckableRowHeader {
    isChecked: boolean;
    rowHeader: RowHeader;
}

@Injectable({
    providedIn: 'root',
})
export class ActionBarService {
    private checkableRowHeaders: CheckableRowHeader[] = [];
    private checkableRowHeaderArrayChanged$ = new BehaviorSubject<CheckableRowHeader[]>(
        this.checkableRowHeaders
    );

    private isCompareRowHeadersCheckable = false;
    private isCompareRowHeadersCheckable$ = new BehaviorSubject<boolean>(this.isCompareRowHeadersCheckable);

    public get checkableRowHeaderArrayChanged(): Observable<CheckableRowHeader[]> {
        return this.checkableRowHeaderArrayChanged$.asObservable();
    }

    public get isCompareRowHeadersCheckableChanged(): Observable<boolean> {
        return this.isCompareRowHeadersCheckable$.asObservable();
    }

    public set setIsRowHeaderCheckable(isRowHeadersCheckable: boolean) {
        this.isCompareRowHeadersCheckable = isRowHeadersCheckable;
        this.isCompareRowHeadersCheckable$.next(this.isCompareRowHeadersCheckable);
    }

    public addCheckableRowHeader(attribute: CheckableRowHeader): void {
        this.checkableRowHeaders.push(attribute);
        this.checkableRowHeaderArrayChanged$.next([...this.checkableRowHeaders]);
    }

    public removeCheckableRowHeader(attribute: CheckableRowHeader): void {
        const attributeIndex = this.checkableRowHeaders.findIndex(
            (listAttribute) => listAttribute.rowHeader.columnId === attribute.rowHeader.columnId
        );
        if (attributeIndex > -1) {
            this.checkableRowHeaders.splice(attributeIndex, 1);
            this.checkableRowHeaderArrayChanged$.next([...this.checkableRowHeaders]);
        }
    }

    public removeAllCheckableRowHeaders() {
        this.checkableRowHeaders = [];
        this.checkableRowHeaderArrayChanged$.next([...this.checkableRowHeaders]);
    }
}
