import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CountrySelector } from '@sew/ui-components/dist/types/components/website-components/sew-ws-header/types/language.types';
import { Subject } from 'rxjs';
import { environment } from '../../../../../environments/environment';

@Injectable()
export class PreviewDashboardService {
    private switchCountryLanguageSubject = new Subject<void>();

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public readonly switchCountryLanguage = this.switchCountryLanguageSubject.asObservable();

    public constructor(private httpClient: HttpClient) {}

    public getAvailableLanguagesByCountryCode(countryCode: string) {
        return this.httpClient.get<CountrySelector>(
            `${environment.baseURLs.api.domain}/os/api/service/country-selector/${countryCode}/en_US?SEWNoSSO=true`
        );
    }
}
