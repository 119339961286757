import { createReducer, on } from '@ngrx/store';
import * as ResultCountActions from './result-count.actions';

export const resultCountFeatureKey = 'result_count';

export interface ResultCountState {
    resultCount: number;
}

export const initialState: ResultCountState = {
    resultCount: 0,
};

export const reducer = createReducer(
    initialState,

    on(ResultCountActions.updateResultCount, (state, { payload: { resultCount } }) => ({
        ...state,
        resultCount,
    }))
);
