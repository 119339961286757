/**
 * Converts a country code to a languageKey for SAP
 */
export const getTranslationLanguageKey = (languageCountry: string): string => {
    const [, lang, country] = languageCountry.match('(^.{2})_(.{2}$)') ?? [, 'en', 'US'];

    return `${(lang as string).toLowerCase()}_${(country as string).toUpperCase()}`;
};

/**
 *
 * @param languageCountry languageCountry (e.x. de_DE or de-de or DE-DE)
 * @returns  ISO-639-1-Codes (e.x. de, en, fr,...)
 */
export const getTwoLetterLanguageCode = (languageCountry: string): string => {
    const [, lang] = languageCountry.match('(^.{2})') ?? [, 'en'];

    return (lang as string).toLowerCase();
};

const availableWikiLanguages = ['en', 'de', 'fr'];
/**
 * Converts the common language format to special format for online-Help (only Startpage, not API)
 * Please DO NOT use this language in any other context! (no ISO standard)
 * ... & please don't ask me why :-)
 */
export const getWikiLanguageKey = (languageCountry: string): string => {
    const defaultWikiLanguage = 'en-EN';
    const separator = languageCountry.includes('_') ? '_' : '-';
    let [, lang, country] = languageCountry.match(`(^.{2})${separator}(.{2}$)`) ?? [, 'en', 'EN'];

    if (!lang || !country) {
        return defaultWikiLanguage;
    }

    lang = lang.toLowerCase();
    country = country.toUpperCase();

    // only available languages in online-Help
    if (!availableWikiLanguages.some((availableLang) => availableLang === lang)) {
        return defaultWikiLanguage;
    }

    return `${lang}-${country === 'US' || country === 'GB' ? 'EN' : country}`;
};

export const getWikiApiLanguageKey = (languageCountry: string): string => {
    const capitalLanguageCountry = languageCountry.replace('-', '_').toUpperCase();
    switch (capitalLanguageCountry) {
        case 'DE_DE':
            return 'de_DE';

        case 'EN_US':
            return 'en_DE';

        case 'FR_FR':
            return 'fr_FR';

        default:
            return 'en_DE';
    }
};

/**
 * Method to unflatten an Object
 *
 * @param obj e.g. {"test.bla": "Hallo", "test.blub": "Hi"}
 * @returns e.g. {test: {bla: "Hallo", blub: "Hi"}}
 */
export const unflatJson = <T extends Record<string, unknown>>(obj: T): T => {
    const unflattedJson: T = {} as T;
    for (const [key, value] of Object.entries(obj)) {
        const keys = key.split('.');
        let currentObject: Record<string, unknown> = unflattedJson;
        for (let i = 0; i < keys.length; i++) {
            const currentKey = keys[i];
            if (i === keys.length - 1) {
                currentObject[currentKey] = value;
            } else {
                if (!currentObject[currentKey]) {
                    currentObject[currentKey] = {};
                }
                currentObject = currentObject[currentKey] as Record<string, unknown>;
            }
        }
    }

    return unflattedJson;
};
