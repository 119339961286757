import { ActionBarService, CheckableRowHeader } from '../../services/action-bar.service';
import { Component, Input, OnDestroy, OnInit, TrackByFunction } from '@angular/core';
import { ProductCompareDetail, RowHeader } from '../../compare.interface';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    // To provide copy&paste of native html tables it is necessary to use only native html
    // elements instead of angular components. So we have to use an attribute component selector.
    selector: '[osc-attributes-section]',
    templateUrl: './attributes-section.component.html',
    styleUrls: ['./attributes-section.component.scss'],
})
export class AttributesSectionComponent implements OnInit, OnDestroy {
    @Input() public heading = '';
    @Input() public products: ProductCompareDetail[] = [];
    @Input() public set labels(labels: RowHeader[] | null) {
        if (labels && labels.length > 0) {
            this.labelsCheckable = labels.map(
                (label): CheckableRowHeader =>
                    ({
                        isChecked: false,
                        rowHeader: label,
                    } as CheckableRowHeader)
            ) as CheckableRowHeader[];
        } else {
            this.labelsCheckable = [];
        }
    }

    @Input() public showHeading = true;

    public labelsCheckable: CheckableRowHeader[] = [];
    public showAttributes = true;
    public showCheckbox = false;

    protected destroySubject = new Subject<void>();

    public constructor(public actionBarService: ActionBarService) {}

    public ngOnInit(): void {
        this.actionBarService.isCompareRowHeadersCheckableChanged
            .pipe(takeUntil(this.destroySubject))
            .subscribe((showCheckbox) => (this.showCheckbox = showCheckbox));

        this.actionBarService.checkableRowHeaderArrayChanged
            .pipe(takeUntil(this.destroySubject))
            .subscribe((rowHeaders) => {
                if (rowHeaders.length === 0) {
                    this.labelsCheckable.forEach(
                        (checkableRowHeader) => (checkableRowHeader.isChecked = false)
                    );
                }
            });
    }

    public ngOnDestroy(): void {
        this.destroySubject.next();
        this.destroySubject.complete();
    }

    public toggleAttributes() {
        this.showAttributes = !this.showAttributes;
    }

    public getProductValue(columnId: string, product: ProductCompareDetail) {
        return product.productValues.find((item) => item.columnId === columnId);
    }

    public trackByProductId: TrackByFunction<ProductCompareDetail> = (_, item) => item.productId;

    public trackByColumnId: TrackByFunction<CheckableRowHeader> = (_, item: CheckableRowHeader) =>
        item.rowHeader.columnId;

    public toggleCheckbox(add: boolean, labelCheckable: CheckableRowHeader) {
        if (add) {
            this.actionBarService.addCheckableRowHeader(labelCheckable);
        } else {
            this.actionBarService.removeCheckableRowHeader(labelCheckable);
        }
    }
}
