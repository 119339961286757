import { DOCUMENT, KeyValue } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { LanguageService } from '../../../services/language/language.service';
import { CompareTable } from '../compare.interface';
import {
    CAAS_CONFIG,
    CaasConfig,
    DOM_PARSER,
    PropertyInformationResponse,
} from './property-information.interface';

@Injectable({
    providedIn: null,
})
export class PropertyInformationService {
    public constructor(
        private http: HttpClient,
        private languageService: LanguageService,
        @Inject(CAAS_CONFIG) private caasConfig: CaasConfig,
        @Inject(DOCUMENT) private document: Document,
        @Inject(DOM_PARSER) private domParser: DOMParser
    ) {}

    public getProductPropertiesInformation(
        compareTable: CompareTable
    ): Observable<KeyValue<string, string>[]> {
        const filterKey = 'page.formData.pt_property_id.value';
        const filterProperties: string[] = [];
        compareTable.rowHeadings.customerAttributes.forEach((rowHeader) => {
            filterProperties.push(`{'${filterKey}':'${rowHeader.columnId}'}`);
        });
        compareTable.rowHeadings.productInfoAttributes.forEach((rowHeader) => {
            filterProperties.push(`{'${filterKey}':'${rowHeader.columnId}'}`);
        });
        compareTable.rowHeadings.technicalAttributes.forEach((rowHeader) => {
            filterProperties.push(`{'${filterKey}':'${rowHeader.columnId}'}`);
        });
        // if filterProperties are not set the http request will run into an error, cause the filter value for the rest heart is invalid
        if (filterProperties.length <= 0) {
            return of([]);
        }

        return this.languageService.onLanguageChange.pipe(
            first(),
            switchMap((language) => {
                const params = new HttpParams().set(
                    'filter',
                    `{'$and': [{'locale.identifier':'${language.toUpperCase()}'},{'page.template.uid':'product_property_description'}]}`
                );

                return this.http
                    .get<PropertyInformationResponse>(`${this.caasConfig.baseUrl}${this.caasConfig.tenant}`, {
                        params,
                    })
                    .pipe(
                        map((response: PropertyInformationResponse) => {
                            const propertiesInformation: KeyValue<string, string>[] = [];
                            response._embedded['rh:doc']
                                .filter(
                                    (value) => value.page && value.page.formData.pt_description.value.length
                                )
                                .forEach((propInformationPageRef) => {
                                    propertiesInformation.push({
                                        key: propInformationPageRef.page.formData.pt_property_id.value,
                                        value: propInformationPageRef.page.formData.pt_description.value,
                                    });
                                });

                            return propertiesInformation;
                        }),
                        map((result) => {
                            result
                                .filter((entry) =>
                                    entry.value.includes('<div data-fs-type="link.external_link">')
                                )
                                .forEach((element) => {
                                    element.value = this.convertLinksToHtml(
                                        this.domParser.parseFromString(element.value, 'text/html')
                                    );
                                });

                            return result;
                        })
                    );
            })
        );
    }

    private convertLinksToHtml(htmlElement: Document) {
        const scriptElements = htmlElement.getElementsByTagName('script');
        const body = htmlElement.getElementsByTagName('body').item(0) as HTMLBodyElement;

        Array.from(scriptElements).forEach((script) => {
            const linkValue = JSON.parse(script.innerText);
            const linkElement = this.document.createElement('a');
            linkElement.href = linkValue.lt_link.value;
            linkElement.target = linkValue.lt_target.value.identifier;
            linkElement.title = linkValue.lt_title.value;
            linkElement.innerText = linkValue.lt_text.value;
            ((script.parentNode as Node).parentNode as Node).replaceChild(
                linkElement,
                script.parentNode as Node
            );
        });

        return body.innerHTML.toString();
    }
}
