/**
 * Converts a string date with the Format `YYYYMMDD` to a Date-Object.
 *
 * @param date `YYYYMMDD` as string date
 * @returns date as string
 */
export const convertStringToDateObject = (date: string) => {
    if (
        date &&
        !isNaN(parseInt(date.substring(0, 4), 10)) &&
        !isNaN(parseInt(date.substring(4, 6), 10)) &&
        !isNaN(parseInt(date.substring(6, 8), 10))
    ) {
        return new Date(
            parseInt(date.substring(0, 4), 10),
            parseInt(date.substring(4, 6), 10) - 1,
            parseInt(date.substring(6, 8), 10)
        );
    }

    return undefined;
};
