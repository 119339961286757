import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IframeDialogComponent } from '../../../dialogs/iframe-dialog/iframe-dialog.component';
import { EventService } from '../../services/event.service';
import { IconRegistryService } from './../../../../services/icon-registry/icon-registry.service';

@Component({
    selector: 'osc-compare-product-info',
    templateUrl: './compare-product-info.component.html',
    styleUrls: ['./compare-product-info.component.scss'],
})
export class CompareProductInfoComponent {
    @Input() public productId = '';
    @Input() public productDataUrl = '';
    @Input() public technicalDocUrl = '';
    @Input() public cadUrl = '';
    @Input() public showOpen = true;
    @Input() public showShoppingCart = true;

    public constructor(
        iconRegistry: IconRegistryService,
        private dialog: MatDialog,
        private eventServie: EventService
    ) {
        iconRegistry.addSvgIcon('chevron_right', 'share', 'shopping_cart', 'launch');
    }

    public openProductDetailTab() {
        this.eventServie.openProduct(this.productId);
    }

    public openOSFrame(url: string) {
        this.dialog.open(IframeDialogComponent, {
            maxWidth: '1200px',
            width: '90vw',
            height: '90vh',
            data: { url },
        });
    }

    public addToShoppingCart() {
        this.eventServie.addToShoppingCart(this.productId);
    }
}
