import { InjectionToken } from '@angular/core';

export interface Description {
    fsType: string;
    name: string;
    value: string;
}

export interface PropertyId {
    fsType: string;
    name: string;
    value: string;
}

/* eslint-disable @typescript-eslint/naming-convention */
export interface PropertyInformationFormData {
    pt_description: Description;
    pt_property_id: PropertyId;
}
/* eslint-enable @typescript-eslint/naming-convention */

export interface PropertyInformationPage {
    formData: PropertyInformationFormData;
}

export interface Locale {
    identifier: string;
}
export interface PropertyInformationPageRef {
    locale: Locale;
    page: PropertyInformationPage;
}

export interface Embedded {
    'rh:doc': PropertyInformationPageRef[];
}

/* eslint-disable @typescript-eslint/naming-convention */
export interface PropertyInformationResponse {
    _returned: number;
    _embedded: Embedded;
}
/* eslint-enable @typescript-eslint/naming-convention */

export interface CaasConfig {
    tenant: string;
    baseUrl: string;
}

export const CAAS_CONFIG = new InjectionToken<CaasConfig>('CAAS_CONFIG');
export const DOM_PARSER = new InjectionToken<DOMParser>('DOM_PARSER');
