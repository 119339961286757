import { Directive, Input } from '@angular/core';
import { provideMediaQuerySwitchCase } from '../../media-query.helper';
import { MediaQueryMatchPriority } from '../../media-query.interface';
import { mediaQueryMatch } from '../common/media-query-match.decorator';
import { MediaQueryToggleDirective } from '../common/media-query-toggle.directive';

/**
 * Defines the smallest media query to begin displaying the item.
 * The item will be displayed for the given media queries and all those that are larger.
 */
@Directive()
abstract class MediaQueryMin extends MediaQueryToggleDirective {
    public abstract minMediaQuery?: string;

    public match(matchingMediaQueries: string[]): boolean {
        const min = this.minMediaQuery;

        return typeof min !== 'undefined' && matchingMediaQueries.includes(min);
    }

    public get matchExpression() {
        return this.minMediaQuery;
    }

    public get matchPriority() {
        return MediaQueryMatchPriority.MIN;
    }
}

/**
 * @see {@link MediaQueryMin}
 */
@Directive({
    selector: '[oscMediaQuerySwitchMin]',
    providers: [provideMediaQuerySwitchCase(MediaQuerySwitchMinDirective)],
})
export class MediaQuerySwitchMinDirective extends MediaQueryMin {
    @Input('oscMediaQuerySwitchMin') public minMediaQuery?: string;
}

/**
 * @see {@link MediaQueryMin}
 */
@Directive({
    selector: '[oscMediaQueryMin]',
})
@mediaQueryMatch<MediaQueryMinDirective>('minMediaQuery')
export class MediaQueryMinDirective extends MediaQueryMin {
    @Input('oscMediaQueryMin') public minMediaQuery?: string;
}
