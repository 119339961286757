import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class StaticPictureService {
    private _pathPrefix = '/api/productId';

    public constructor(private http: HttpClient) {}

    public fetchStaticImageURL(productID: string): Observable<string> {
        return this.http
            .get(`${this._pathPrefix}/${productID}/picture`, {
                responseType: 'text',
            })
            .pipe(catchError(() => of('')));
    }

    public set pathPrefix(url: string) {
        this._pathPrefix = url;
    }
}
