import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { distinctUntilChanged, map, withLatestFrom } from 'rxjs/operators';
import { RootStoreService } from '../root-store.service';
import * as ResultCountActions from './result-count.actions';

@Injectable()
export class ResultCountEffects {
    public calculateResultCounter$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ResultCountActions.calculateResultCount),
            withLatestFrom(
                this.rootStoreService.getCountries(),
                this.rootStoreService.getSelectedCountry(),
                this.rootStoreService.getSelectedComponent(),
                this.rootStoreService.getSelectedRegulation(),
                (_, countries, country, component, regulation) => {
                    if (regulation) {
                        return regulation.count;
                    }
                    if (component) {
                        return component.count;
                    }
                    if (country) {
                        return country.count;
                    }

                    return countries.reduce((count, c) => count + c.count, 0);
                }
            ),
            distinctUntilChanged(),
            map((resultCount) => ResultCountActions.updateResultCount({ payload: { resultCount } }))
        )
    );

    public constructor(private actions$: Actions, private rootStoreService: RootStoreService) {}
}
