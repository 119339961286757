import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { map, withLatestFrom } from 'rxjs/operators';
import { ListFilterItemService } from '../../list-filter-item.service';
import { TextFilterValueSelector } from './text-filter.interface';

@Component({
    selector: 'osc-text-filter',
    templateUrl: './text-filter.component.html',
    styleUrls: ['./text-filter.component.scss'],
})
export class TextFilterComponent implements AfterViewInit {
    @ViewChild('input') public input!: ElementRef<HTMLInputElement>;

    /**
     * Allows to use a custom placeholder for the input field. If not set the standard placeholder will be used.
     */
    @Input() public placeholder = 'LIST_FILTER.SEARCH';

    public constructor(private filterService: ListFilterItemService) {}

    /**
     * This basic value selector selects the given property of an item.
     *
     * @see TextFilterValueSelector for how to implement your own value selector.
     */
    @Input() public valueSelector: TextFilterValueSelector = (item, property) => item[property]?.toString();

    public ngAfterViewInit(): void {
        const filterFn = fromEvent<InputEvent>(this.input.nativeElement, 'input').pipe(
            map((event) => (event.target as HTMLInputElement).value.trim()),
            withLatestFrom(this.filterService.getFilterProperties()),
            map(([searchTerm, properties]) =>
                !searchTerm
                    ? null
                    : (item: any) =>
                          properties.some((property) =>
                              this.valueSelector(item, property)
                                  ?.toLowerCase()
                                  .includes(searchTerm.toLowerCase())
                          )
            )
        );

        this.filterService.setFilterFnSource(filterFn);

        this.filterService
            .getFilterReset()
            .subscribe(
                () => (
                    (this.input.nativeElement.value = ''),
                    this.input.nativeElement.dispatchEvent(new Event('input'))
                )
            );
    }
}
