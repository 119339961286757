import { ProjectMetaData } from '@sew/ui-components';
import { BehaviorSubject, NEVER, Observable, of } from 'rxjs';

export const mockLanguageSubject = new BehaviorSubject<Observable<never> | string>(NEVER);

export const mockProjectMetaDataSubject = new BehaviorSubject<ProjectMetaData>({
    displayBlogPostFooter: true,
} as ProjectMetaData);

export class MockHeaderConfigService {
    public get language() {
        return mockLanguageSubject.asObservable();
    }

    public get cmsProject() {
        return of('SEWDE');
    }

    public get countryShort() {
        return of('de');
    }

    public get initComplete() {
        return of(true);
    }

    public get permissionsChange() {
        return of([{ auth: '', status: '' }]);
    }

    public get projectMetaDataChange() {
        return mockProjectMetaDataSubject.asObservable();
    }
}
