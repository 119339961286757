import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'osc-compare-label-tooltip',
    templateUrl: './compare-label-tooltip.component.html',
    styleUrls: ['./compare-label-tooltip.component.scss'],
})
export class CompareLabelTooltipComponent implements OnInit {
    @Input() public tooltip = '';

    public safeHtml: SafeHtml = '';

    public constructor(private domSanitizer: DomSanitizer) {}

    public ngOnInit() {
        this.safeHtml = this.domSanitizer.bypassSecurityTrustHtml(this.tooltip);
    }
}
