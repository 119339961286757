import { InjectionToken } from '@angular/core';
import { ModalRef } from './modal-ref';

export const MODAL_TOKEN = new InjectionToken<ModalRef<any>>('ModalWrapper');
export const MODAL_CONTENT_TOKEN = new InjectionToken<InjectionData<any, any>>('ModalContent');

/**
 * Data that gets injected into a component
 *
 * **IMPORTANT**: Has to be the _first_ constructor parameter of the component class
 */
export type InjectionData<Input, Output = never> = {
    data: Input;
    close: (returnData?: Output) => void;
};

export type Constructor = new (...args: any[]) => any;
type FirstParameter<F extends Constructor> = ConstructorParameters<F>[0];

/**
 * Type of data that gets injected with InjectionData
 */
export type ClassInjectionData<F extends Constructor> = FirstParameter<F> extends InjectionData<infer Input>
    ? Input
    : never;

/**
 * Type of data that gets returned when a modal closes
 */
export type ModalReturnType<F extends Constructor> =
    | (FirstParameter<F> extends InjectionData<any, infer Output> ? Output : never)
    | null;

export interface ModalConfiguration {
    headline: string;
    /**
     * @default 'info'
     */
    type?: 'info' | 'attention' | 'warning' | 'warning-ansi' | 'error' | 'valid';
    /**
     * @default true
     */
    open?: boolean;
    /**
     * @default 'm'
     */
    size?: 'xs' | 's' | 'm' | 'l';
    /**
     * @default true
     */
    closeButton?: boolean;
    label?: string;
    status?: string;
    help?: OnlineHelp;
}

export interface ModalWrapper<C extends Constructor> {
    component: C;
    configuration: ModalConfiguration;
    data: ClassInjectionData<C>;
}

export interface OnlineHelp {
    url?: string;
    tooltip?: string;
    product?: string;
    data?: string;
    api?: boolean;
}
