/**
 * Keys for the product approval state
 */
export enum ApprovalStateKeys {
    ALL = 'ALL',
    UNASSIGNED = 'UNASSIGNED',
    APPROVED = 'APPROVED',
    UNDER_CLARIFICATION = 'UNDER_CLARIFICATION',
    DISCONTINUED = 'DISCONTINUED',
}
