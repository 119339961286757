import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HeaderEventsModule, TrackingModule } from '@os-components';
import { environment } from '@reg-environment';
import { SewUiComponentsModule } from '@sew/ui-components-angular';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { LandingPageModule } from './features/landing-page/landing-page.module';
import { tracking } from './helpers';
import { reducers } from './stores/router/router.reducer';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        LandingPageModule,
        HttpClientModule,
        SewUiComponentsModule,
        StoreModule.forRoot(reducers),
        EffectsModule.forRoot(),
        HeaderEventsModule,
        TrackingModule.forRoot({ appName: tracking.app, events: tracking.step }),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        CoreModule,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
