/* istanbul ignore file */
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
    MediaQueryExactDirective,
    MediaQuerySwitchExactDirective,
} from './matchers/exact/media-query-exact.directive';
import {
    MediaQueryMinDirective,
    MediaQuerySwitchMinDirective,
} from './matchers/min/media-query-min.directive';
import { MediaQuerySwitchDefaultDirective } from './matchers/switch-default/media-query-switch-default.directive';
import { MediaQueryMatchService } from './media-query-match.service';
import { MediaQuerySwitchDirective } from './media-query-switch.directive';
import { BREAKPOINTS, MEDIA_QUERIES } from './media-query.helper';
import { Breakpoints } from './media-query.interface';

export {
    MediaQueryExactDirective,
    MediaQuerySwitchExactDirective,
} from './matchers/exact/media-query-exact.directive';
export {
    MediaQueryMinDirective,
    MediaQuerySwitchMinDirective,
} from './matchers/min/media-query-min.directive';
export { MediaQuerySwitchDefaultDirective } from './matchers/switch-default/media-query-switch-default.directive';
export { MediaQuerySwitchDirective } from './media-query-switch.directive';
export { Breakpoints } from './media-query.interface';

const mediaQuerySwitchDirectives = [
    MediaQuerySwitchDirective,
    MediaQuerySwitchDefaultDirective,
    MediaQuerySwitchMinDirective,
    MediaQuerySwitchExactDirective,
];

const mediaQueryStandaloneDirectives = [MediaQueryMinDirective, MediaQueryExactDirective];

const mediaQueryDirectives = [...mediaQuerySwitchDirectives, ...mediaQueryStandaloneDirectives];

/**
 * Includes the directives necessary for toggling elements based on media queries.
 *
 * It's necessary to provide the application's breakpoints and the according media queries
 * using the `.forRoot()` import at the app root level (once).
 */
@NgModule({
    declarations: mediaQueryDirectives,
    exports: mediaQueryDirectives,
    imports: [CommonModule],
})
export class MediaQueryModule {
    /**
     * Provide breakpoints and media queries for the media query switch directives.
     * The object keys are what is used in the directives.
     *
     * @param config Includes breakpoints and media queries
     */
    public static forRoot({
        breakpoints,
        mediaQueries,
    }: {
        breakpoints: Breakpoints;
        mediaQueries: Breakpoints;
    }): ModuleWithProviders<MediaQueryModule> {
        return {
            ngModule: MediaQueryModule,
            providers: [
                { provide: BREAKPOINTS, useValue: breakpoints },
                { provide: MEDIA_QUERIES, useValue: mediaQueries },
                MediaQueryMatchService,
            ],
        };
    }
}
