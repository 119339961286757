import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApprovalStateKeys } from '../../../interfaces/approval-state.enum';
import { DropdownOption } from '../dropdowns/dropdown.interface';

/**
 * This component is a select for the approval state attribute of a product
 */
@Component({
    selector: 'osc-approval-state',
    templateUrl: './approval-state.component.html',
    styleUrls: ['./approval-state.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApprovalStateComponent implements OnInit {
    /**
     * Selected state key
     */
    @Input() public approvalStateKey: ApprovalStateKeys | string = ApprovalStateKeys.UNASSIGNED;

    /**
     * Flag to enable editing
     */
    @Input() public editable = false;

    /**
     * Output for approval state changes
     */
    @Output() public changeValue = new EventEmitter<string>();

    /**
     * When ApprovalStateComponent is used in filter, it adds a field "All"
     */
    @Input() public isFilter = false;

    /**
     * If the currently selected index is clicked, this event will be emmited again.
     * Default value is `false`.
     *
     * Introduced due to requirement #364136
     */
    @Input() public reEmitSelectedIndex = false;

    public items: DropdownOption[] = [
        {
            key: 'R',
            textKey: ApprovalStateKeys.DISCONTINUED,
            icon: 'cancel',
            iconColor: '#b40000',
        },
        {
            key: 'G',
            textKey: ApprovalStateKeys.APPROVED,
            icon: 'check_circle',
            iconColor: '#6fc42d',
        },
        {
            key: 'Y',
            textKey: ApprovalStateKeys.UNDER_CLARIFICATION,
            icon: 'help',
            iconColor: '#ff7300',
        },
        {
            key: '',
            textKey: ApprovalStateKeys.UNASSIGNED,
            icon: 'lens',
            iconColor: '#a3acb5',
        },
    ];

    public ngOnInit(): void {
        if (this.isFilter) {
            this.items.unshift({
                key: '**ALL**',
                icon: 'checklist',
                iconColor: '#4a535a',
                textKey: ApprovalStateKeys.ALL,
            } as DropdownOption);
        }
    }

    public emitValueChange(key: string): void {
        this.changeValue.emit(key);
    }
}
