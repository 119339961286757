import { Directive } from '@angular/core';
import { MediaQueryMatchPriority } from '../../media-query.interface';
import { ToggleableDirective } from './toggleable.directive';

@Directive()
export abstract class MediaQueryToggleDirective extends ToggleableDirective {
    /**
     * Check if this element matches for the given media queries
     *
     * @param matchingMediaQueries The currently matching media query name(s)
     */
    public abstract match(matchingMediaQueries: string[]): boolean;

    /**
     * The priority this case has when multiple elements match the same media query.
     * The lower the value, the higher the priority.
     */
    public abstract get matchPriority(): MediaQueryMatchPriority;

    /**
     * Expression(s) to match against the app's breakpoints
     */
    public abstract get matchExpression(): string | string[] | undefined;
}

export interface MQToggleWithExpression extends MediaQueryToggleDirective {
    matchExpression: string | string[];
}

export type MQToggle = MediaQueryToggleDirective;
