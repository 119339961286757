import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { MonoTypeOperatorFunction, Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

type ToastrType = 'success' | 'info' | 'warning' | 'error';
type ToastrMessageConfig = [type: ToastrType, message: string];
type ToastrMessage = string | ToastrMessageConfig;
type ToastrTapParam = { next?: ToastrMessage; error: ToastrMessage };

@Injectable({
    providedIn: 'root',
})
export class ToastrWrapperService {
    public constructor(private toastrService: ToastrService, private translateService: TranslateService) {}

    public toastrTap<T>({ next, error }: ToastrTapParam): MonoTypeOperatorFunction<T> {
        return (source: Observable<T>) =>
            source.pipe(
                tap(() => next && this.toastr(next, 'success')),
                catchError((e) => {
                    this.toastr(error, 'error');

                    return throwError(() => e);
                })
            );
    }

    public success(text: string) {
        return this.toastrService.success(this.translateService.instant(text));
    }

    public info(text: string) {
        return this.toastrService.info(this.translateService.instant(text));
    }

    public warning(text: string) {
        return this.toastrService.warning(this.translateService.instant(text));
    }

    public error(text: string) {
        return this.toastrService.error(this.translateService.instant(text));
    }

    private toastr(message: ToastrMessage, fallbackType: ToastrType) {
        if (typeof message === 'string') {
            this[fallbackType](message);

            return;
        }

        this[message[0]](message[1]);
    }
}
