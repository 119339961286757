<sew-map
    #sewMap
    [apiKey]="'AIzaSyDR-JXAmWxrzBIkWoNTtiqMCoTHDADt6A8'"
    [configStr]="mapConfig | json"
    [mapStateStr]="mapState | json"
    [localizationStr]="localization | json"
    [innerHTML]="'<p>' + labels?.CMS_MAP_CONSENT_DESCRIPTION + '</p>'"
    [consentButtonText]="labels?.CMS_MAP_CONSENT_BUTTON"
    (zoomChanged)="zoomChanged($event)"
    (markerClicked)="markerClicked($event)"
>
</sew-map>
