import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

@Pipe({
    name: 'withLoading',
})
export class WithLoadingPipe implements PipeTransform {
    public transform<T>(val: Observable<T>): Observable<{ loading: boolean; value?: T; error?: Error }> {
        return (
            val &&
            val.pipe(
                map((value) => ({ loading: false, value })),
                startWith({ loading: true }),
                catchError((error) => of({ loading: false, error }))
            )
        );
    }
}
