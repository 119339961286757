import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { HeaderConfigService } from '../../../../../lib-os-components/src/public-api';
import { Labels } from './label.types';

@Injectable({ providedIn: 'root' })
export class LabelsService {
    private labelsSubject: ReplaySubject<Labels> = new ReplaySubject(1);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public readonly labels = this.labelsSubject.asObservable();

    public constructor(private configService: HeaderConfigService) {
        this.configService.labelsChange.subscribe((labels) => {
            this.labelsSubject.next(labels as unknown as Labels);
        });
    }
}
