import { Directive, HostListener, Input } from '@angular/core';
import { EntryType, OmniChannelManagerService, Options } from '../../services/public-services';

@Directive({
    selector: '[oscOcmCreateEntry]',
})
export class OcmCreateEntryDirective {
    @Input() public templateName?: string;
    @Input() public entryType?: EntryType;
    @Input() public options?: Options;

    public constructor(private ocmService: OmniChannelManagerService) {}

    @HostListener('click') public onClick() {
        if (this.entryType === undefined || this.templateName === undefined) {
            return;
        }

        this.handleClick(this.entryType, this.templateName);
    }

    private handleClick(entryType: EntryType, templateName: string) {
        switch (entryType) {
            case 'PAGE':
                // can be implemented for blog in future
                console.log('PAGE');
                break;
            case 'SECTION':
                // can be implemented for blog in future
                console.log('SECTION');
                break;
            case 'DATASET':
                console.log('DATASET');
                this.ocmService.createDataset(templateName, this.options);
                break;
            default:
                const exhaustiveCheck: never = entryType;
                throw new Error(`Unkown action type: ${exhaustiveCheck}`);
        }
    }
}
