import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

/**
 * Provided by the {@link FilterAccumulatorComponent}, this service allows for
 * easy interaction (like resetting) between the accumulator itself and all of the filters it contains.
 */
@Injectable({
    providedIn: null,
})
export class ListFilterService {
    private resetAll = new Subject<void>();

    public constructor() {}

    public triggerResetAllFilters() {
        this.resetAll.next();
    }

    public resetAllFilters() {
        return this.resetAll.asObservable();
    }

    public destroy() {
        this.resetAll.complete();
    }
}
