import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { TrackingConfig } from './tracking.interface';
import { SEW_TRACKING_APP, SEW_TRACKING_CONFIG, TrackingService } from './tracking.service';

/**
 * Module to preset the tracking config
 */
@NgModule({
    providers: [TrackingService],
})
export class TrackingModule {
    public constructor(@Optional() @SkipSelf() public parentModule: TrackingModule) {
        if (parentModule) {
            throw new Error('TrackingModule is already loaded. Import it once only!');
        }
    }

    /**
     *
     * @param config Object with the app name and the track events for this app to use the [TrackClickDirective]{@link TrackClickDirective}
     */
    public static forRoot(config: {
        appName: string;
        events: TrackingConfig;
    }): ModuleWithProviders<TrackingModule> {
        return {
            ngModule: TrackingModule,
            providers: [
                { provide: SEW_TRACKING_APP, useValue: config.appName },
                { provide: SEW_TRACKING_CONFIG, useValue: config.events },
            ],
        };
    }
}
