import { SimpleChanges, ɵɵdirectiveInject } from '@angular/core';
import { Subscription } from 'rxjs';
import { MediaQueryMatchService } from '../../media-query-match.service';
import { MediaQueryToggleDirective } from './media-query-toggle.directive';

type Ctor<T> = new (...args: any[]) => T;

/**
 * Set up the directive to observe its matching state with the
 * configured breakpoints and toggle itself accordingly.
 *
 * **NOTE**: seems to be more reliable when used _above_ the `@Directive()` decorator.
 *
 * @param watchInputProp input property to watch for changes using Angular's change detection (OnChanges)
 * @returns the directive, set up to toggle itself based on the observed breakpoint matching state
 */
export const mediaQueryMatch = <T extends MediaQueryToggleDirective>(watchInputProp: keyof T) => (
    componentType: Ctor<T>
) => {
    const originalFactory = (componentType as any).ɵfac;
    const protoOnChanges = componentType.prototype.ngOnChanges;
    const protoOnDestroy = componentType.prototype.ngOnDestroy;

    // make sure the Angular lifecycle methods are present on the
    // prototype so Angular can set them up to actually be called
    // eslint-disable-next-line
    componentType.prototype.ngOnChanges = protoOnChanges || function () {};
    // eslint-disable-next-line
    componentType.prototype.ngOnDestroy = protoOnDestroy || function () {};

    (componentType as any).ɵdir.factory = (...args: any[]) => {
        const component = originalFactory(...args);
        // inject MediaQueryMatchService
        const mqService = ɵɵdirectiveInject(MediaQueryMatchService);
        const originalOnChanges = component.ngOnChanges;
        const originalOnDestroy = component.ngOnDestroy;
        let observe: Subscription;

        // overwrite ngOnChanges implementation (or simply set it if it's not present)
        component.ngOnChanges = function (changes: SimpleChanges) {
            if (originalOnChanges) {
                originalOnChanges.call(this, changes);
            }

            if (changes[watchInputProp as string]) {
                if (observe) {
                    observe.unsubscribe();
                }

                observe = mqService.observeMatch(this).subscribe((match) => this.setActive(match));
            }
        };

        // overwrite ngOnDestroy implementation (or simply set it if it's not present)
        component.ngOnDestroy = function () {
            if (originalOnDestroy) {
                originalOnDestroy.call(this);
            }

            observe?.unsubscribe();
        };

        return component;
    };

    return componentType;
};
