import { Component, Input } from '@angular/core';
import { EventService } from '../../services/event.service';
import { IconRegistryService } from './../../../../services/icon-registry/icon-registry.service';

@Component({
    selector: 'osc-compare-product-control-bar',
    templateUrl: './compare-product-control-bar.component.html',
    styleUrls: ['./compare-product-control-bar.component.scss'],
})
export class CompareProductControlBarComponent {
    @Input() public isReferenceProduct = false;
    @Input() public productId = '';

    public constructor(iconRegistry: IconRegistryService, private eventService: EventService) {
        iconRegistry.addSvgIcon('close', 'push_pin');
    }

    public removeProduct() {
        this.eventService.removeProduct(this.productId);
    }

    public changeRefProduct() {
        this.eventService.changeReferenceProduct(this.productId);
    }
}
