import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Tracking } from '@sew/ui-components';
import { TrackingConfig } from './tracking.interface';

/**
 * App to track events for when using the TrackingService.
 */
export const SEW_TRACKING_APP = new InjectionToken<string>('SEW_TRACKING_APP');
export const SEW_TRACKING_CONFIG = new InjectionToken<TrackingConfig>('SEW_TRACKING_CONFIG');

/**
 * Wrapper service for the SewTrackerService provided by the sew-web-features package.
 *
 * Provide a value for the SEW_TRACKING_APP injection token
 * so that the events are associated with the correct application.
 */
@Injectable({
    providedIn: null,
})
export class TrackingService {
    private tracker?: Tracking;

    public constructor(
        @Inject(SEW_TRACKING_APP) private trackingApp: string,
        @Inject(SEW_TRACKING_CONFIG) private trackingEvents: TrackingConfig
    ) {
        // add global javascript variables for etracker auto tracking
        (window as any).et_seg2 = trackingApp;
        (window as any).et_seg3 = 'INIT';
    }

    public trackEvent(event: string) {
        if (this.isLocalHost()) {
            return;
        }

        if (this.tracker !== undefined) {
            this.tracker.startTracking(this.trackingApp, event);
        } else {
            console.warn('Tracker is not set.');
        }
    }

    public getEventByPath(dotNotationPath: string): string | null {
        const resolved = dotNotationPath
            .split('.')
            .reduce<string | TrackingConfig>((a, r) => (a as TrackingConfig)[r], this.trackingEvents);

        return typeof resolved === 'string' ? resolved : null;
    }

    public setTracker(tracker: Tracking) {
        this.tracker = tracker;
        /**
         * Track the load of the application for WZ tracker.
         * E-Tracker is doing this automatically.
         */
        if (!this.isLocalHost()) {
            this.tracker.trackSapEvent(this.trackingApp, 'INIT');
        }
    }

    /**
     * prevent tracking in localhost
     */
    private isLocalHost(): boolean {
        return window.location.href.includes('localhost.sew-d.de');
    }
}
