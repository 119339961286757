<table
    *ngIf="compareTable?.productCompareDetails && compareTable.productCompareDetails.length > 0"
    [ngClass]="{ 'hide-headings': hideAttributes }"
>
    <thead>
        <tr>
            <th scope="row" class="control-panel">
                <osc-filter-accumulator [displayShowAllButton]="false" #filterListLabels>
                    <osc-text-filter
                        [placeholder]="'COMPARE.FILTER_PLACEHOLDER'"
                        [oscListFilter]="['longDescription']"
                    ></osc-text-filter>
                </osc-filter-accumulator>
                <osc-control-panel></osc-control-panel>
            </th>
            <td
                *ngFor="let product of compareTable.productCompareDetails; trackBy: trackByProductId"
                [ngClass]="{
                    'height-large': heightLarge,
                    'width-large':
                        productsWithActiveCadPreview !== undefined &&
                        productsWithActiveCadPreview.includes(product.productId)
                }"
            >
                <osc-compare-product-control-bar
                    [productId]="product.productId"
                    [isReferenceProduct]="product.productId === compareTable.referenceProduct"
                    *ngIf="!hideAttributes"
                >
                </osc-compare-product-control-bar>
                <osc-compare-product-header
                    [product]="product"
                    [isLoggedIn]="!hideAttributes"
                    [ngClass]="{
                        'height-large': heightLarge,
                    }"
                ></osc-compare-product-header>
            </td>
        </tr>
    </thead>

    <tbody>
        <tr *ngIf="!hideAttributes">
            <th scope="row" class="attributes-group">
                <div class="group-title product-infos" translate>COMPARE.PRODUCT_INFO</div>
                <div class="product-infos-labels">
                    <div class="link-list" translate>COMPARE.DUD</div>
                    <!-- will be used later <div class="price-indicatorl"></div> -->
                    <!-- <div class="product-lifecycle" translate>COMPARE.PRODUCT_LIFECYCLE</div> -->
                    <!-- will be used later <div class="related-products"></div> -->
                </div>
            </th>
            <td
                *ngFor="let product of compareTable.productCompareDetails; trackBy: trackByProductId"
                class="attributes-group"
            >
                <osc-compare-product-info
                    [productId]="product.productId"
                    [productDataUrl]="product.productDataUrl"
                    [technicalDocUrl]="product.technicalDocUrl"
                    [cadUrl]="product.cadUrl"
                    [showOpen]="showOpen"
                    [showShoppingCart]="showShoppingCart"
                ></osc-compare-product-info>
            </td>
        </tr>
    </tbody>

    <tbody
        osc-attributes-section
        [showHeading]="false"
        [labels]="compareTable.rowHeadings.productInfoAttributes"
        [products]="compareTable.productCompareDetails"
        *ngIf="!hideAttributes && compareTable.rowHeadings.productInfoAttributes.length > 0"
    ></tbody>

    <tbody
        osc-attributes-section
        heading="COMPARE.CUSTOMER_ATTRIBUTES"
        [labels]="compareTable.rowHeadings.customerAttributes | filter : (filterListLabels.filters | async)"
        [products]="compareTable.productCompareDetails"
        *ngIf="!hideAttributes && compareTable.rowHeadings.customerAttributes.length > 0"
    ></tbody>

    <tbody
        osc-attributes-section
        heading="COMPARE.TECHNICAL_ATTRIBUTES"
        [labels]="compareTable.rowHeadings.technicalAttributes | filter : (filterListLabels.filters | async)"
        [products]="compareTable.productCompareDetails"
        *ngIf="!hideAttributes && compareTable.rowHeadings.technicalAttributes.length > 0"
    ></tbody>
</table>
