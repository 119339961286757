import { Component, Input } from '@angular/core';
import { formatValue } from '../../../../services/value-formatter/value-formatter.helper';
import { CompareAttribute, RowHeader } from '../../compare.interface';
import { EventService } from '../../services/event.service';

@Component({
    selector: 'osc-compare-attribute',
    templateUrl: './compare-attribute.component.html',
    styleUrls: ['./compare-attribute.component.scss'],
})
export class CompareAttributeComponent {
    @Input() public set rowHeading(rowHeading: RowHeader) {
        this.rowHeaderProps = rowHeading;
        this.setCompareAttributeProperties(this.attribute, rowHeading);
    }

    public get rowHeading(): RowHeader {
        return this.rowHeaderProps;
    }

    @Input() public set productAttribute(attribute: CompareAttribute | undefined) {
        this.setCompareAttributeProperties(attribute, this.rowHeaderProps);
    }

    @Input() public productId!: string;

    public attribute!: CompareAttribute;
    public productValue!: string;
    private rowHeaderProps!: RowHeader;

    public constructor(private eventService: EventService) {}

    public handleChangeValue(value: string) {
        const productChange = {
            productId: this.productId,
            columnId: this.attribute.columnId,
            value: [value],
        };

        this.eventService.editProduct([productChange]);
    }

    private setCompareAttributeProperties(attribute: CompareAttribute | undefined, rowHeader: RowHeader) {
        this.attribute = attribute ?? { value: [], columnId: '' };
        this.productValue = formatValue(this.attribute.value, rowHeader);
    }
}
