import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRegulations from './regulations.reducer';

export const selectRegulationsState = createFeatureSelector<fromRegulations.RegulationState>(
    fromRegulations.regulationsFeatureKey
);

export const selectRegulations = createSelector(
    selectRegulationsState,
    (regulationState: fromRegulations.RegulationState) => regulationState.regulations
);

export const selectSelectedRegulation = createSelector(
    selectRegulationsState,
    (regulationState: fromRegulations.RegulationState) => regulationState.selectedRegulation
);
