import { Component, Input, TemplateRef } from '@angular/core';

@Component({
    selector: 'osc-custom-tooltip',
    templateUrl: './custom-tooltip.component.html',
    styleUrls: ['./custom-tooltip.component.scss'],
})
export class CustomTooltipComponent {
    @Input() public templateRef: TemplateRef<any> | null = null;
}
