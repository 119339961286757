<div class="container">
    <sew-ws-button>
        <button oscOcmCreateEntry [templateName]="'SEW_EURODRIVE.market_regulation'" [entryType]="'DATASET'">
            Add new regulation
        </button>
    </sew-ws-button>
    <sew-ws-button>
        <button oscOcmCreateEntry [templateName]="'SEW_EURODRIVE.market_product'" [entryType]="'DATASET'">
            Add new product
        </button>
    </sew-ws-button>
</div>
<div class="container">
    <sew-combobox
        ws
        (itemSelected)="changeCountry($any($event).detail.value)"
        [placeholder]="'Select Country'"
    >
        <select title="Select Country">
            <option></option>
            <option *ngFor="let country of allAvailableCmsCountries" [value]="country.countryCode">
                {{ country.name }}
            </option>
        </select>
    </sew-combobox>

    <sew-combobox
        ws
        (itemSelected)="selectedLangauge = $any($event).detail.value"
        [placeholder]="'Select Language'"
    >
        <select title="Select Country">
            <option></option>
            <option
                *ngFor="let language of allAvailableLanguages"
                [value]="language.code"
                [attr.selected]="language.code === selectedLangauge ? true : null"
            >
                {{ language.name }}
            </option>
        </select>
    </sew-combobox>

    <sew-ws-button>
        <button (click)="changeLocalization()">Switch</button>
    </sew-ws-button>
</div>
