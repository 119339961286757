<osc-dropdown
    [readonly]="!editable"
    [items]="dropdownOptions"
    [optionTemplate]="content"
    [selectedIndex]="selectedIndex"
    [reEmitSelectedIndex]="reEmitSelectedIndex"
    (selectedIndexChange)="selectedIndex = $event; selectedIndexChange($event)"
></osc-dropdown>

<ng-template #content let-item let-readonly="readonly">
    <div class="item" [class.readonly]="readonly">
        <mat-icon *ngIf="item.icon" [style.color]="item.iconColor" [svgIcon]="item.icon"></mat-icon>
        {{ (translateKeyPrefix ? translateKeyPrefix + '.' : '') + item.textKey | translate }}
    </div>
</ng-template>
