<ng-container [ngSwitch]="true">
    <osc-approval-state
        class="dropdown"
        (changeValue)="handleChangeValue($event)"
        [approvalStateKey]="attribute.value[0]"
        [editable]="rowHeading.editable === true"
        [reEmitSelectedIndex]="true"
        *ngSwitchCase="attribute.columnId === 'P_CUSTOMER_APPROVAL_STATUS'"
    ></osc-approval-state>

    <osc-boolean-attribute
        class="dropdown"
        *ngSwitchCase="rowHeading.columnType === 'boolean'"
        [value]="attribute.value[0] === 'X' ? 'X' : ''"
        (valueChange)="handleChangeValue($event)"
        [editable]="rowHeading.editable === true"
    ></osc-boolean-attribute>

    <div *ngSwitchDefault class="product-attribute">
        <osc-editable-text
            *ngIf="rowHeading.editable; else readonly"
            [value]="attribute.value"
            [formatProps]="rowHeading"
            [showWhiteSpace]="true"
            (changeValue)="handleChangeValue($event)"
        ></osc-editable-text>
        <ng-template #readonly
            ><span class="show-white-space" [title]="productValue">{{ productValue }}</span></ng-template
        >
    </div>
</ng-container>
