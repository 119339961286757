import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { OnDestroyBase } from '../../helpers/public-helpers';
import { ToastrWrapperService } from '../../services/public-services';
import { InteractiveProductPreviewService } from './interactive-product-preview.service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/naming-convention
declare const CCViewer: any;

@Component({
    selector: 'osc-interactive-product-preview',
    templateUrl: './interactive-product-preview.component.html',
    styleUrls: ['./interactive-product-preview.component.scss'],
})
export class InteractiveProductPreviewComponent extends OnDestroyBase implements OnInit {
    @ViewChild('dialogContainer') public div: ElementRef<HTMLDivElement> | undefined;

    @Input() public productID?: string;
    @Output() public noPreviewAvailable = new EventEmitter<void>();

    public interactivePreviewUrl?: string;
    public loading = true;

    public uniqueContainerId = `interactive-preview-container-${uuidv4()}`;
    public uniqueFrameId = `interactive-preview-frame-${uuidv4()}`;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private ccViewer: any = null;

    public constructor(
        private interactiveProductPreviewService: InteractiveProductPreviewService,
        private translateService: TranslateService,
        private toastrService: ToastrWrapperService
    ) {
        super();
    }

    public ngOnInit(): void {
        if (this.productID !== undefined) {
            this.interactiveProductPreviewService
                .fetchCadModelURL(this.productID)
                .pipe(takeUntil(this.destroySubject))
                .subscribe((cadModel) => {
                    if (cadModel.url !== undefined) {
                        this.loading = false;
                        this.interactivePreviewUrl = cadModel.url;
                        this.showCCViewer(this.interactivePreviewUrl);
                    } else {
                        this.noPreviewAvailable.emit();
                        this.toastrService.warning('CAD.NO_INTERACTIVE_PREVIEW');
                    }
                });
        }
    }

    /* istanbul ignore next */
    public showCCViewer(url: string) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        /* istanbul ignore next */
        this.ccViewer = new CCViewer({
            container: this.uniqueContainerId,
            frameID: this.uniqueFrameId,
            pathToModel: '',
            file: url,
            frameWidth: 500, // minimum  500px
            frameHeight: 410, // minimum  410px
            params: {
                modelTree: false,
                dimensions: { enabled: true },
                fullscreen: true,
                showDefaultViewAfterLoadModel: false,
                // Due to problems with the camera position beeing inconsistent, commented out the following
                // line and replaced it with `this.ccViewer.setCameraPos({ x: -250, y: -250, z: 100 });` right below
                // setCameraPos: new CCViewer.THREE.Vector3(-250, -250, 100),
                labels: [
                    {
                        id: 'options-mouse-label',
                        value: this.translateService.instant('CAD.VIEWER.OPT.MOUSE_LABEL'),
                    },
                    {
                        id: 'options-coordinates-label',
                        value: this.translateService.instant('CAD.VIEWER.OPT.COOR_LABEL'),
                    },
                    {
                        id: 'options-highlighted-hover-label',
                        value: this.translateService.instant('CAD.VIEWER.OPT.HOVER_LABEL'),
                    },
                    {
                        id: 'options-tree-label',
                        value: this.translateService.instant('CAD.VIEWER.OPT.TREE_LABEL'),
                    },
                    {
                        id: 'options-camera-label',
                        value: this.translateService.instant('CAD.VIEWER.OPT.CAMERA_LABEL'),
                    },
                    {
                        id: 'options-controls-label',
                        value: this.translateService.instant('CAD.VIEWER.OPT.CONTROLS_LABEL'),
                    },
                ],
                tooltip: [
                    {
                        id: 'tooltip-view-folder',
                        value: this.translateService.instant('CAD.VIEWER.VIEWS'),
                    },
                    {
                        id: 'tooltip-view-top',
                        value: this.translateService.instant('CAD.VIEWER.TOOLTIP.TOP'),
                    },
                    {
                        id: 'tooltip-view-front',
                        value: this.translateService.instant('CAD.VIEWER.TOOLTIP.FRONT'),
                    },
                    {
                        id: 'tooltip-view-right',
                        value: this.translateService.instant('CAD.VIEWER.TOOLTIP.RIGHT'),
                    },
                    {
                        id: 'tooltip-view-bottom',
                        value: this.translateService.instant('CAD.VIEWER.TOOLTIP.BOTTOM'),
                    },
                    {
                        id: 'tooltip-view-back',
                        value: this.translateService.instant('CAD.VIEWER.TOOLTIP.BACK'),
                    },
                    {
                        id: 'tooltip-view-left',
                        value: this.translateService.instant('CAD.VIEWER.TOOLTIP.LEFT'),
                    },
                    {
                        id: 'tooltip-view-iso',
                        value: this.translateService.instant('CAD.VIEWER.TOOLTIP.ISO'),
                    },
                    {
                        id: 'tooltip-rotation',
                        value: this.translateService.instant('CAD.VIEWER.TOOLTIP.ROTATION'),
                    },
                    {
                        id: 'tooltip-rotation-disabled',
                        value: this.translateService.instant('CAD.VIEWER.TOOLTIP.ROTATION'),
                    },

                    {
                        id: 'tooltip-render-folder',
                        value: this.translateService.instant('CAD.VIEWER.REPRESENTATION'),
                    },
                    {
                        id: 'tooltip-transparency',
                        value: this.translateService.instant('CAD.VIEWER.OPT.TRANSPARENCY_LABEL'),
                    },
                    {
                        id: 'tooltip-transparency-disabled',
                        value: this.translateService.instant('CAD.VIEWER.OPT.TRANSPARENCY_LABEL'),
                    },
                    {
                        id: 'tooltip-render-normal',
                        value: this.translateService.instant('CAD.VIEWER.OPT.SHADED_LABEL'),
                    },
                    {
                        id: 'tooltip-render-edges',
                        value: this.translateService.instant('CAD.VIEWER.OPT.SHADED_W_EDGES_LABEL'),
                    },
                    {
                        id: 'tooltip-render-wireframe',
                        value: this.translateService.instant('CAD.VIEWER.OPT.WIREFRAME_LABEL'),
                    },

                    {
                        id: 'tooltip-measurement-folder',
                        value: this.translateService.instant('CAD.VIEWER.MEASURE'),
                    },
                    {
                        id: 'tooltip-measurement-edges',
                        value: this.translateService.instant('CAD.VIEWER.MEASURE_EDGES'),
                    },
                    {
                        id: 'tooltip-measurement-free',
                        value: this.translateService.instant('CAD.VIEWER.MEASURE_FREE'),
                    },
                    {
                        id: 'tooltip-measurement-undo',
                        value: this.translateService.instant('CAD.VIEWER.UNDO'),
                    },
                    {
                        id: 'tooltip-measurement-delete',
                        value: this.translateService.instant('CAD.VIEWER.DELETE_ALL'),
                    },

                    {
                        id: 'tooltip-dimensions-folder',
                        value: this.translateService.instant('CAD.VIEWER.DIMENSIONS'),
                    },

                    {
                        id: 'tooltip-clipping-folder',
                        value: this.translateService.instant('CAD.VIEWER.CROSS_SECTION'),
                    },
                    {
                        id: 'tooltip-clipping-folder-disabled',
                        value: this.translateService.instant('CAD.VIEWER.CROSS_SECTION'),
                    },
                    {
                        id: 'tooltip-clipping-folder-hover-disabled',
                        value: this.translateService.instant('CAD.VIEWER.CROSS_SECTION'),
                    },
                    {
                        id: 'tooltip-clipping-folder-modeltree-disabled',
                        value: this.translateService.instant('CAD.VIEWER.CROSS_SECTION'),
                    },
                    {
                        id: 'tooltip-clipping-folder-missing-model-disabled',
                        value: this.translateService.instant('CAD.VIEWER.CROSS_SECTION'),
                    },
                    {
                        id: 'tooltip-clip-x-plane',
                        value: this.translateService.instant('CAD.VIEWER.PLANE_YZ'),
                    },
                    {
                        id: 'tooltip-clip-y-plane',
                        value: this.translateService.instant('CAD.VIEWER.PLANE_XZ'),
                    },
                    {
                        id: 'tooltip-clip-z-plane',
                        value: this.translateService.instant('CAD.VIEWER.PLANE_XY'),
                    },
                    {
                        id: 'tooltip-clip-reset',
                        value: this.translateService.instant('CAD.VIEWER.ZURUECKSETZEN'),
                    },
                    {
                        id: 'tooltip-clip-reset-disabled',
                        value: this.translateService.instant('CAD.VIEWER.ZURUECKSETZEN'),
                    },
                    {
                        id: 'tooltip-clip-invert',
                        value: this.translateService.instant('CAD.VIEWER.INVERT'),
                    },
                    {
                        id: 'tooltip-clip-invert-disabled',
                        value: this.translateService.instant('CAD.VIEWER.INVERT'),
                    },

                    {
                        id: 'tooltip-screenshot',
                        value: this.translateService.instant('CAD.VIEWER.TOOLTIP.SCREENSHOT'),
                    },
                    {
                        id: 'tooltip-view-start',
                        value: this.translateService.instant('CAD.VIEWER.TOOLTIP.STARTVIEW'),
                    },

                    {
                        id: 'tooltip-settings-folder',
                        value: this.translateService.instant('CAD.VIEWER.TOOLTIP.SETTINGS'),
                    },

                    {
                        id: 'tooltip-close',
                        value: this.translateService.instant('CAD.VIEWER.CLOSE'),
                    },

                    {
                        id: 'tooltip-left-mouse',
                        value: this.translateService.instant('CAD.VIEWER.TOOLTIP.LEFT_MOUSEB'),
                    },
                    {
                        id: 'tooltip-middle-mouse',
                        value: this.translateService.instant('CAD.VIEWER.TOOLTIP.MIDDLE_MOUSEB'),
                    },
                    {
                        id: 'tooltip-right-mouse',
                        value: this.translateService.instant('CAD.VIEWER.TOOLTIP.RIGHT_MOUSEB'),
                    },
                    {
                        id: 'tooltip-fullscreen',
                        value: this.translateService.instant('CAD.VIEWER.TOOLTIP.FULLSCREEN_TOGGLE'),
                    },
                ],
            },
        });
        /* istanbul ignore next */
        // this.ccViewer.setCameraPos({ x: -250, y: -250, z: 100 });
    }

    @HostListener('window:message', ['$event'])
    /* istanbul ignore next */
    public onMesssage(event: MessageEvent) {
        /* istanbul ignore next  */
        if (event.data.messageType === 'ccViewer.loaded' && !!this.ccViewer?.Scene) {
            // Folgende Zeile ist eine Alternative für die Verwendung von 'setCameraPos' bei der Initialisierung des ccViewers
            /* istanbul ignore next  */
            this.ccViewer.Scene.Camera.setCameraToViewId(17);
            this.ccViewer.Scene.Camera.fitZoom();
        }
    }
}
