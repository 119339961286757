import { Component, Input } from '@angular/core';
import { Labels } from '../../../../services/labels/label.types';

@Component({
    selector: 'reg-energy',
    templateUrl: './energy.component.html',
    styleUrls: ['./energy.component.scss'],
})
export class EnergyComponent {
    @Input() public labels?: Labels;
}
