import { createAction, props } from '@ngrx/store';
import { Components, MarketComponent } from '../../models/data.types';

const prefixComponents = '[Components]';

export const REQUEST_COMPONENTS = `${prefixComponents} Trigger get components`;
export const requestComponents = createAction(REQUEST_COMPONENTS);

export const RECEIVE_COMPONENTS = `${prefixComponents} Receive components`;
export const receiveComponents = createAction(
    RECEIVE_COMPONENTS,
    props<{
        payload: { components: Components };
    }>()
);

export const UPDATE_SELECTED_COMPONENT = `${prefixComponents} Update selected component`;
export const updateSelectedComponent = createAction(
    UPDATE_SELECTED_COMPONENT,
    props<{
        payload: { selectedComponent: MarketComponent | null };
    }>()
);

export const UPDATE_SELECTED_COMPONENT_BY_PROPERTY = `${prefixComponents} Update selected component by property`;
export const updateSelectedComponentByProperty = createAction(
    UPDATE_SELECTED_COMPONENT_BY_PROPERTY,
    props<{
        payload: { value: number | string | null };
        property: keyof MarketComponent;
    }>()
);

export const REPLACE_SELECTED_COMPONENT = `${prefixComponents} Replace selected component`;
export const replaceSelectedComponent = createAction(
    REPLACE_SELECTED_COMPONENT,
    props<{
        payload: { selectedComponent: MarketComponent | null };
    }>()
);

export const REPLACE_SELECTED_COMPONENT_BY_PROPERTY = `${prefixComponents} Replace selected component by property`;
export const replaceSelectedComponentByProperty = createAction(
    REPLACE_SELECTED_COMPONENT_BY_PROPERTY,
    props<{
        payload: { value: number | string | null };
        property: keyof MarketComponent;
    }>()
);
