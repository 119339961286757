import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

type Icon = string | null | undefined;

@Injectable({
    providedIn: 'root',
})
export class IconRegistryService {
    private registeredIcons = new Set<string>();

    public constructor(private iconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

    /**
     * Register one or more icons with Angular's MatIconRegistry
     * using `assets/icons` as path.
     *
     * Will not register any icons which have been registered already.
     *
     * @param icons icon(s) to register
     */
    public addSvgIcon(...icons: Icon[]): void {
        this.addSvgIconInPath('assets/icons', ...icons);
    }

    /**
     * Register given icons (except those which have already been registered)
     * with the MatIconRegistry.
     *
     * @param icons icons to register
     */
    public addSvgIcons(icons: Icon[]): void {
        this.addSvgIcon(...icons);
    }

    /**
     * Register one or more icons with Angular's MatIconRegistry
     * in the given path.
     *
     * Will not register any icons which have been registered already.
     *
     * @param path path to the SVG files for the icons to register
     * @param icons icon(s) to register
     */
    public addSvgIconInPath(path: string, ...icons: Icon[]): void {
        for (const icon of icons) {
            this.addSingleSVGIcon(path, icon);
        }
    }

    private addSingleSVGIcon(path: string, icon: Icon): void {
        if (!icon || this.registeredIcons.has(icon)) {
            return;
        }

        this.iconRegistry.addSvgIcon(
            icon,
            this.domSanitizer.bypassSecurityTrustResourceUrl(`${path}/${icon}.svg`)
        );
        this.registeredIcons.add(icon);
    }
}
