import { Component, Inject, Injector, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ModalRef } from '../../../services/modal/modal-ref';
import {
    ClassInjectionData,
    Constructor,
    InjectionData,
    MODAL_CONTENT_TOKEN,
    MODAL_TOKEN,
    ModalReturnType,
    OnlineHelp,
} from '../../../services/modal/modal.helper';

@Component({
    selector: 'osc-modal-wrapper',
    templateUrl: './modal-wrapper.component.html',
    styleUrls: ['./modal-wrapper.component.scss'],
})
export class ModalWrapperComponent<C extends Constructor> implements OnInit {
    public component!: C;
    public headline!: string;
    public contentInjector!: Injector;

    public type?: string;
    public label?: string;
    public status?: string;
    public open?: boolean;
    public size?: string;
    public closeButton?: boolean;
    public help?: OnlineHelp;

    public constructor(
        @Inject(MODAL_TOKEN) private modalRef: ModalRef<C>,
        private injector: Injector,
        private translateService: TranslateService
    ) {}

    public ngOnInit() {
        this.type = this.modalRef.configuration.type;
        this.headline = this.translateService.instant(this.modalRef.configuration.headline);
        this.label = this.modalRef.configuration.label;
        this.status = this.modalRef.configuration.status;
        this.open = this.modalRef.configuration.open;
        this.size = this.modalRef.configuration.size;
        this.closeButton = this.modalRef.configuration.closeButton;
        this.help = this.modalRef.configuration.help;

        this.component = this.modalRef.component;
        this.contentInjector = this.createInjector(this.modalRef, this.injector);
    }

    public close = (data?: ModalReturnType<C>) => {
        this.modalRef.close(data);
    };

    private createInjector(modalRef: ModalRef<C>, injector: Injector) {
        const injectionData: InjectionData<ClassInjectionData<C>, ModalReturnType<C>> = {
            data: modalRef.data,
            close: this.close,
        };

        return Injector.create({
            providers: [
                {
                    provide: MODAL_CONTENT_TOKEN,
                    useValue: injectionData,
                },
            ],
            parent: injector,
        });
    }
}
