<tr class="group-title" *ngIf="showHeading">
    <th scope="row" (click)="toggleAttributes()" [ngClass]="{ show: showAttributes }">
        <div translate>{{ heading }}<mat-icon>arrow_drop_down</mat-icon></div>
    </th>
    <td *ngFor="let product of products; trackBy: trackByProductId"></td>
</tr>
<ng-container *ngIf="showAttributes">
    <tr *ngFor="let labelCheckable of labelsCheckable; trackBy: trackByColumnId" class="attribute">
        <th scope="row" [ngClass]="{ diff: labelCheckable.rowHeader.diff }">
            <div class="th-flex-wrapper">
                <input
                    *ngIf="showCheckbox"
                    [(ngModel)]="labelCheckable.isChecked"
                    (ngModelChange)="this.toggleCheckbox($event, labelCheckable)"
                    type="checkbox"
                />
                <osc-compare-label [headerItem]="labelCheckable.rowHeader"></osc-compare-label>
            </div>
        </th>
        <td
            *ngFor="let product of products; trackBy: trackByProductId"
            [ngClass]="{ diff: getProductValue(labelCheckable.rowHeader.columnId, product)?.diff }"
        >
            <osc-compare-attribute
                [productId]="product.productId"
                [productAttribute]="getProductValue(labelCheckable.rowHeader.columnId, product)"
                [rowHeading]="labelCheckable.rowHeader"
            ></osc-compare-attribute>
        </td>
    </tr>
</ng-container>
