import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { unflatJson } from './translation.helper';

export const TRANSLATION_FILES = new InjectionToken<string[]>('TranslateApps');
@Injectable({
    providedIn: 'root',
})
export class TranslationService {
    public constructor(private http: HttpClient, @Inject(TRANSLATION_FILES) private apps: string[]) {}

    public getTranslation(lang: string): Observable<any> {
        return this.http
            .get(`/translate/${lang}?applications=${this.apps.join(';')}`)
            .pipe(map((response: any) => unflatJson(response)));
    }
}
