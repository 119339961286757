/* istanbul ignore file */
// ignore from coverage, cause we don't want to test module logic
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { OsApiAuthInterceptor, OsApiAuthModule, TRANSLATION_FILES, TranslationService } from '@os-components';
import { TranslateInterceptor } from '../interceptors/translate.interceptor';
import { environment } from './../../environments/environment';
import { WINDOW } from './constants';

/**
 * Core Module that contains all application wide singletons
 *
 * https://angular.io/guide/singleton-services
 *
 */
@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        HttpClientModule,
        TranslateModule.forRoot({
            defaultLanguage: environment.defaultLang,
            loader: {
                provide: TranslateLoader,
                useClass: TranslationService,
            },
        }),
        OsApiAuthModule.forRoot(
            {
                xCsrfTokenUrl: `${environment.baseURLs.api.domain}${environment.baseURLs.api.token}`,
            },
            [
                {
                    path: '/translate',
                    apiUrl: `${environment.baseURLs.api.domain}${environment.baseURLs.api.translation}`,
                    useXCsrfToken: false,
                    withCredentials: false,
                },
            ]
        ),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: OsApiAuthInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TranslateInterceptor,
            multi: true,
        },
        TranslationService,
        { provide: TRANSLATION_FILES, useValue: ['REG'] },
        {
            provide: WINDOW,
            useValue: window,
        },
    ],
    exports: [TranslateModule],
})
export class CoreModule {
    public constructor(@Optional() @SkipSelf() public parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only!');
        }
    }
}
