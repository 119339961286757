import { createAction, props } from '@ngrx/store';
import { Regulation, Regulations } from '../../models/data.types';

const prefixRegulations = '[Regulations]';

export const RECEIVE_REGULATIONS = `${prefixRegulations} Receive regulations`;
export const receiveRegulations = createAction(
    RECEIVE_REGULATIONS,
    props<{
        payload: { regulations: Regulations };
    }>()
);

export const UPDATE_SELECTED_REGULATION = `${prefixRegulations} Update selected regulation`;
export const updateSelectedRegulation = createAction(
    UPDATE_SELECTED_REGULATION,
    props<{
        payload: { selectedRegulation: Regulation | null };
    }>()
);

export const UPDATE_SELECTED_REGULATION_BY_PROPERTY = `${prefixRegulations} Update selected regulation by property`;
export const updateSelectedRegulationByProperty = createAction(
    UPDATE_SELECTED_REGULATION_BY_PROPERTY,
    props<{
        payload: { value: string | number | null };
        property: keyof Regulation;
    }>()
);

export const REPLACE_SELECTED_REGULATION = `${prefixRegulations} Replace selected regulation`;
export const replaceSelectedRegulation = createAction(
    REPLACE_SELECTED_REGULATION,
    props<{
        payload: { selectedRegulation: Regulation | null };
    }>()
);

export const REPLACE_SELECTED_REGULATION_BY_PROPERTY = `${prefixRegulations} Replace selected regulation by property`;
export const replaceSelectedRegulationByProperty = createAction(
    REPLACE_SELECTED_REGULATION_BY_PROPERTY,
    props<{
        payload: { value: number | string | null };
        property: keyof Regulation;
    }>()
);
