import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OcmCreateEntryDirective } from './ocm-create-entry.directive';

/**
 * Module with TrackClick directive
 */
@NgModule({
    declarations: [OcmCreateEntryDirective],
    exports: [OcmCreateEntryDirective],
    imports: [CommonModule],
})
export class OcmCreateEntryModule {}
