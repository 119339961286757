import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromResultCount from './result-count.reducer';

export const selectResultCountState = createFeatureSelector<fromResultCount.ResultCountState>(
    fromResultCount.resultCountFeatureKey
);

export const selectResultCount = createSelector(
    selectResultCountState,
    (resultCountState: fromResultCount.ResultCountState) => resultCountState.resultCount
);
