import { Pipe, PipeTransform } from '@angular/core';
import { Country, MarketComponent, Regulation } from '@reg/models/data.types';
import { SelectOption } from '@reg/models/dropdown.types';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

type ResultTypes = MarketComponent | Country | Regulation;

@Pipe({
    name: 'dropdownInput',
})
export class DropdownInputPipe implements PipeTransform {
    public transform(value: Observable<ResultTypes[]>): any {
        if (!value) {
            return;
        }

        return value.pipe(
            map((items) =>
                items.map((item) => ({
                    name: item.name,
                    value: item.id.toString(),
                }))
            ),
            tap((items: SelectOption[]) => items.sort((a, b) => a.name.localeCompare(b.name)))
        );
    }
}
