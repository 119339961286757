import { TemplateRef, ViewContainerRef } from '@angular/core';

/**
 * Toggle visibility of an element by adding/removing its template.
 */
export class ToggleableView {
    private created = false;

    public constructor(private viewContainerRef: ViewContainerRef, private template: TemplateRef<any>) {}

    public updateState(display: boolean) {
        if (!this.created && display) {
            this.create();
        } else if (this.created && !display) {
            this.destroy();
        }
    }

    private create() {
        this.created = true;
        this.viewContainerRef.createEmbeddedView(this.template);
    }

    private destroy() {
        this.created = false;
        this.viewContainerRef.clear();
    }
}
