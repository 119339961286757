<sew-dialog
    type="{{ type }}"
    headline="{{ headline }}"
    label="{{ label }}"
    status="{{ status }}"
    open="{{ open }}"
    size="{{ size }}"
    closeButton="{{ closeButton }}"
    (close)="close()"
>
    <osc-online-help
        *ngIf="help"
        [url]="help.url"
        [tooltip]="help.tooltip"
        [product]="help.product"
        [data]="help.data"
        [isApi]="help.api ?? false"
        slot="help"
    ></osc-online-help>
    <ng-container *ngComponentOutlet="component; injector: contentInjector"></ng-container>
</sew-dialog>
