import { AfterViewInit, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IconRegistryService } from '../../../services/icon-registry/icon-registry.service';
import { IframeDialogData } from '../dialogs.interface';

@Component({
    selector: 'osc-iframe-dialog',
    templateUrl: './iframe-dialog.component.html',
    styleUrls: ['./iframe-dialog.component.scss'],
})
export class IframeDialogComponent implements AfterViewInit {
    public constructor(
        @Inject(MAT_DIALOG_DATA) public data: IframeDialogData,
        iconRegistry: IconRegistryService,
        private sanitizer: DomSanitizer,
        private ref: ChangeDetectorRef
    ) {
        iconRegistry.addSvgIcon('close');
    }

    public ngAfterViewInit(): void {
        this.ref.detach();
    }

    public cleanUrl(url: string): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
        // Todo: besser sanitize nutzen und nicht bypass -> Probleme beim Test
        // this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, url);
    }
}
