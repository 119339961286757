import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromComponents from './components.reducer';
import { ComponentsState } from './components.reducer';

export const selectComponentsState = createFeatureSelector<fromComponents.ComponentsState>(
    fromComponents.componentsFeatureKey
);

export const selectComponents = createSelector(
    selectComponentsState,
    (componentsState: ComponentsState) => componentsState.components
);

export const selectSelectedComponent = createSelector(
    selectComponentsState,
    (componentsState: ComponentsState) => componentsState.selectedComponent
);
