import { Component, Input } from '@angular/core';

@Component({
    selector: 'osc-headline-content',
    templateUrl: './headline-content.component.html',
    styleUrls: ['./headline-content.component.scss'],
})
export class HeadlineContentComponent {
    @Input() public text!: string;
    @Input() public subtext!: string;
}
