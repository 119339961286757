import { Component, Input } from '@angular/core';
import { IconRegistryService } from '../../../services/icon-registry/icon-registry.service';

@Component({
    selector: 'osc-icon-button',
    templateUrl: './icon-button.component.html',
    styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent {
    @Input() public disabled = false;
    @Input() public tooltip!: string;
    @Input() public padding = 'unset';

    @Input() public set icon(name: string) {
        this.iconName = name;
        this.iconRegistry.addSvgIcon(name);
    }

    public iconName!: string;

    public constructor(private iconRegistry: IconRegistryService) {}
}
