import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MapComponent } from './components/map/map.component';

const routes: Routes = [
    {
        path: '',
        component: MapComponent,
    },
    {
        path: 'result/:country/:component/:regulation',
        loadChildren: () => import('../result/result.module').then((m) => m.ResultModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
    exports: [RouterModule],
})
export class LandingPageRoutingModule {}
