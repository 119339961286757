import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, of } from 'rxjs';

interface CadModel {
    url?: string;
    errorCode?: number;
}

@Injectable({ providedIn: null })
export class InteractiveProductPreviewService {
    private _pathPrefix = '/api/productId';
    private cadActive: string[] = [];
    private cadActive$ = new BehaviorSubject<string[]>([]);

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public readonly productsWithActiveCadPreview$ = this.cadActive$.asObservable();
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public readonly someProductHasActivatedCadPreview$ = this.productsWithActiveCadPreview$.pipe(
        map((products) => products !== undefined && products.length > 0)
    );

    public constructor(private http: HttpClient) {}

    public fetchCadModelURL(productID: string): Observable<CadModel> {
        return this.http.get(`${this._pathPrefix}/${productID}/cad`).pipe(
            catchError((error: HttpErrorResponse) => of({ errorCode: error.status })),
            map((value) => (typeof value === 'string' ? of({ url: value }) : value))
        );
    }

    public set pathPrefix(url: string) {
        this._pathPrefix = url;
    }

    public turnOnCadPreviewForProduct(productId: string) {
        this.cadActive.push(productId);
        this.cadActive$.next(this.cadActive);
    }
}
