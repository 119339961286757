import { Component, Input, OnInit } from '@angular/core';
import { Observable, takeUntil } from 'rxjs';
import { OnDestroyBase } from '../../helpers/public-helpers';
import { InteractiveProductPreviewService } from '../interactive-product-preview/interactive-product-preview.service';
import { StaticPictureService } from './static-picture.service';

/**
 * Load a product image from an endpoint (base64 encoded) and render it
 */
@Component({
    selector: 'osc-product-image',
    templateUrl: './product-image.component.html',
    styleUrls: ['./product-image.component.scss'],
})
export class ProductImageComponent extends OnDestroyBase implements OnInit {
    /**
     * ID of the product to generate the url for the request
     */
    @Input() public productID!: string;
    @Input() public enableCadPreview = false;

    public showCadPreview = false;
    public staticPictureUrl!: Observable<string>;
    public large = false;

    public constructor(
        private staticPictureService: StaticPictureService,
        private interactivePreviewService: InteractiveProductPreviewService
    ) {
        super();
    }

    public ngOnInit() {
        this.staticPictureUrl = this.staticPictureService.fetchStaticImageURL(this.productID);
        this.interactivePreviewService.someProductHasActivatedCadPreview$
            .pipe(takeUntil(this.destroySubject))
            .subscribe((someProductHasActivatedCadPreview) => {
                this.large = someProductHasActivatedCadPreview;
            });
    }

    public turnOnCadPreview() {
        this.showCadPreview = true;
        this.interactivePreviewService.turnOnCadPreviewForProduct(this.productID);
    }
}
