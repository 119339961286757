import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { translationsSingleton } from './translate.factory';

@NgModule({
    imports: [
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: translationsSingleton.factory,
                deps: [HttpClient],
            },
        }),
    ],
    exports: [TranslateModule],
})
export class TranslateChildModule {}
