import { createAction, props } from '@ngrx/store';

const prefixCoutner = '[Counter]';

export const CALCULATE_RESULT_COUNT = `${prefixCoutner} Calculate result count`;
export const calculateResultCount = createAction(CALCULATE_RESULT_COUNT);

export const UPDATE_RESULT_COUNT = `${prefixCoutner} Update result count`;
export const updateResultCount = createAction(
    UPDATE_RESULT_COUNT,
    props<{
        payload: { resultCount: number };
    }>()
);
