import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { ProductChanges } from '../compare.interface';

@Injectable({
    providedIn: 'root',
})
export class EventService implements OnDestroy {
    private editEmitter = new EventEmitter<ProductChanges[]>();
    private removeEmitter = new EventEmitter<string>();
    private showOnlyDifferencesEmitter = new EventEmitter<boolean>();
    private changeReferenceProductEmitter = new EventEmitter<string>();
    private addToShoppingCartEmitter = new EventEmitter<string>();
    private openProductEmitter = new EventEmitter<string>();

    public ngOnDestroy() {
        this.editEmitter.complete();
        this.removeEmitter.complete();
        this.showOnlyDifferencesEmitter.complete();
        this.changeReferenceProductEmitter.complete();
        this.addToShoppingCartEmitter.complete();
        this.openProductEmitter.complete();
    }

    public editProduct(changes: ProductChanges[]) {
        this.editEmitter.emit(changes);
    }

    public get changes() {
        return this.editEmitter.asObservable();
    }

    public removeProduct(prodictId: string) {
        this.removeEmitter.emit(prodictId);
    }

    public get deletions() {
        return this.removeEmitter.asObservable();
    }

    public showOnlyDifferences(value: boolean) {
        this.showOnlyDifferencesEmitter.emit(value);
    }

    public get showOnlyDifferencesChange() {
        return this.showOnlyDifferencesEmitter.asObservable();
    }

    public changeReferenceProduct(productId: string) {
        this.changeReferenceProductEmitter.emit(productId);
    }

    public get referenceProductChange() {
        return this.changeReferenceProductEmitter.asObservable();
    }

    public addToShoppingCart(productId: string) {
        this.addToShoppingCartEmitter.emit(productId);
    }

    public get addToShoppingCartRequest() {
        return this.addToShoppingCartEmitter.asObservable();
    }

    public openProduct(productId: string) {
        this.openProductEmitter.emit(productId);
    }

    public get openProductRequest() {
        return this.openProductEmitter.asObservable();
    }
}
