import * as fromRouter from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { routerFeatureKey, RouterStateUrl } from './router.reducer';

export const selectRouterState = createFeatureSelector<fromRouter.RouterReducerState<RouterStateUrl>>(
    routerFeatureKey
);

export const selectParams = createSelector(
    selectRouterState,
    (routerState: fromRouter.RouterReducerState<RouterStateUrl>) => routerState && routerState.state.params
);
