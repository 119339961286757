export const allAvailableCmsCountries = [
    {
        countryCode: 'ae',
        name: 'United Arab Emirates',
    },
    {
        countryCode: 'ar',
        name: 'Argentina',
    },
    {
        countryCode: 'at',
        name: 'Austria',
    },
    {
        countryCode: 'au',
        name: 'Australia',
    },
    {
        countryCode: 'be',
        name: 'Belgium',
    },
    {
        countryCode: 'br',
        name: 'Brazil',
    },
    {
        countryCode: 'ca',
        name: 'Canada',
    },
    {
        countryCode: 'ch',
        name: 'Switzerland',
    },
    {
        countryCode: 'ci',
        name: 'Ivory Coast',
    },
    {
        countryCode: 'cl',
        name: 'Chile',
    },
    {
        countryCode: 'cn',
        name: 'China',
    },
    {
        countryCode: 'co',
        name: 'Colombia',
    },
    {
        countryCode: 'cz',
        name: 'Czech Republic',
    },
    {
        countryCode: 'de',
        name: 'Germany',
    },
    {
        countryCode: 'dk',
        name: 'Denmark',
    },
    {
        countryCode: 'es',
        name: 'Spain',
    },
    {
        countryCode: 'fi',
        name: 'Finland',
    },
    {
        countryCode: 'fr',
        name: 'France',
    },
    {
        countryCode: 'hu',
        name: 'Hungary',
    },
    {
        countryCode: 'id',
        name: 'Indonesia',
    },
    {
        countryCode: 'in',
        name: 'India',
    },
    {
        countryCode: 'it',
        name: 'Italy',
    },
    {
        countryCode: 'jp',
        name: 'Japan',
    },
    {
        countryCode: 'kr',
        name: 'South Korea',
    },
    {
        countryCode: 'kz',
        name: 'Kazakhstan',
    },
    {
        countryCode: 'ma',
        name: 'Morocco',
    },
    {
        countryCode: 'mx',
        name: 'Mexico',
    },
    {
        countryCode: 'my',
        name: 'Malaysia',
    },
    {
        countryCode: 'nl',
        name: 'Netherlands',
    },
    {
        countryCode: 'no',
        name: 'Norway',
    },
    {
        countryCode: 'nz',
        name: 'New Zealand',
    },
    {
        countryCode: 'pe',
        name: 'Peru',
    },
    {
        countryCode: 'pl',
        name: 'Poland',
    },
    {
        countryCode: 'pt',
        name: 'Portugal',
    },
    {
        countryCode: 'py',
        name: 'Paraguay',
    },
    {
        countryCode: 'se',
        name: 'Sweden',
    },
    {
        countryCode: 'sg',
        name: 'Singapore',
    },
    {
        countryCode: 'sk',
        name: 'Slovakia',
    },
    {
        countryCode: 'th',
        name: 'Thailand',
    },
    {
        countryCode: 'tr',
        name: 'Turkey',
    },
    {
        countryCode: 'tz',
        name: 'Tanzania',
    },
    {
        countryCode: 'ua',
        name: 'Ukraine',
    },
    {
        countryCode: 'uk',
        name: 'Great Britain',
    },
    {
        countryCode: 'us',
        name: 'USA',
    },
    {
        countryCode: 'uy',
        name: 'Uruguay',
    },
    {
        countryCode: 've',
        name: 'Venezuela',
    },
    {
        countryCode: 'vn',
        name: 'Vietnam',
    },
    {
        countryCode: 'za',
        name: 'South Africa',
    },
];
