import { Directive, Input, OnDestroy } from '@angular/core';
import { ListFilterItemService } from './list-filter-item.service';

/**
 * Make filter function from a filter component available to the filter accumulator it's placed in.
 */
@Directive({
    selector: '[oscListFilter]',
    providers: [ListFilterItemService],
})
export class ListFilterDirective implements OnDestroy {
    /**
     * Helper that provides one or more strings to the filter component.
     * These can then be used to select properties of an object to apply filters to.
     *
     * **Note:** This needs to be implemented by the filter component.
     */
    @Input('oscListFilter') public set filterProperties(properties: string | string[]) {
        this.filterService.setFilterProperties(typeof properties === 'string' ? [properties] : properties);
    }

    public constructor(private filterService: ListFilterItemService) {}

    public ngOnDestroy() {
        this.filterService.unsubscribeFilterFnSource();
    }

    /**
     * @returns filter function set by the filter component
     */
    public getFilterFunction() {
        return this.filterService.getFilterFn();
    }
}
