import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { DropdownOption } from '../dropdown.interface';

@Directive({ selector: '[oscDropdownItem]' })
export class DropdownItemDirective {}

type TemplateDropdownOption = DropdownOption & {
    templateId: string;
};

let optionId = 0;

@Component({
    selector: 'osc-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements AfterViewInit {
    @Input() public optionTemplate!: TemplateRef<unknown>;
    @Input() public selectedIndex = 0;
    @Input() public readonly = true;
    @Input() public set items(items: readonly DropdownOption[]) {
        this.dropdownOptions = items.map((item) => ({ ...item, templateId: `dd-option-${optionId++}` }));
    }

    /**
     * If the currently selected index is clicked, this event will be emmited again.
     * Default value is `false`.
     *
     * Introduced due to requirement #364136
     */
    @Input() public reEmitSelectedIndex = false;

    @Output() public selectedIndexChange = new EventEmitter<number>();

    public opened = false;
    public dropdownOptions: TemplateDropdownOption[] = [];

    @ViewChild('dropdownElement', { read: ElementRef })
    private dropdownElement!: ElementRef<HTMLDivElement>;

    @ViewChildren(DropdownItemDirective, { read: ElementRef, emitDistinctChangesOnly: true })
    private itemElements!: QueryList<ElementRef<HTMLElement>>;

    public constructor(private elementRef: ElementRef<HTMLElement>) {}

    public ngAfterViewInit(): void {
        this.itemElements.changes.subscribe(() => this.focusElement(this.selectedIndex));
    }

    public focusElement(index: number, event?: Event): void {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        this.applyFocus(this.itemElements.get(index)?.nativeElement);
    }

    public toggleOpen(open = !this.opened, focusDropdown = false): void {
        if (this.readonly) {
            this.opened = false;

            return;
        }

        this.opened = open;

        if (open) {
            this.focusElement(this.selectedIndex);
        }

        if (focusDropdown) {
            this.applyFocus(this.dropdownElement.nativeElement);
        }
    }

    public updateSelection(index: number): void {
        this.toggleOpen(false, true);

        if (this.reEmitSelectedIndex === false && index === this.selectedIndex) {
            return;
        }

        if (this.readonly) {
            return;
        }

        this.selectedIndex = index;
        this.selectedIndexChange.emit(index);
    }

    public checkClose(relatedTarget: EventTarget | null): void {
        if (!this.opened) {
            return;
        }

        if (!relatedTarget || !this.elementRef.nativeElement.contains(relatedTarget as HTMLElement)) {
            this.toggleOpen(false);
        }
    }

    public trackOptionId(_: number, item: TemplateDropdownOption): string {
        return item.templateId;
    }

    private applyFocus(element?: HTMLElement): void {
        element?.focus();
    }
}
