import { DOCUMENT } from '@angular/common';
import {
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    ElementRef,
    HostListener,
    Inject,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Renderer2,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { CustomTooltipComponent } from './custom-tooltip.component';

/**
 * @deprecated Use TooltipDirective (`oscTooltip`)
 *
 * Display a custom tooltip on mouse over and/or click.
 * The tooltip's content is defined by an ng-template (or a TemplateRef object) that is passed via
 * the [oscCustomTooltip] input.
 */
@Directive({
    selector: '[oscCustomTooltip]',
    providers: [
        {
            provide: 'window',
            useValue: window,
        },
    ],
})
export class CustomTooltipDirective implements OnInit, OnDestroy {
    /**
     * Show a custom tooltip on mouseover and on click.
     * Provide a TemplateRef to use as template for the tooltip content.
     *
     * @deprecated Use `oscTooltip`
     */
    @Input('oscCustomTooltip') public tooltipContentRef: TemplateRef<any> | null = null;

    private isOpen = false;
    private needsClickClose = false;
    private tooltipComponentRef: ComponentRef<CustomTooltipComponent> | null = null;
    private adjustOffsetTimer: number | null = null;

    public constructor(
        private renderer: Renderer2,
        private elementRef: ElementRef,
        private viewContainerRef: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        @Inject('window') private window: Window,
        private zone: NgZone,
        @Inject(DOCUMENT) private document: Document
    ) {}

    @HostListener('mouseover')
    public onMouseEnter() {
        this.toggleOpen(false, true);
    }

    @HostListener('mouseleave')
    public onMouseLeave() {
        this.toggleOpen(false, false);
    }

    public ngOnInit(): void {
        this.renderer.addClass(this.elementRef.nativeElement, 'custom-tooltip-parent');

        // add click listener outside the Angular scope for performance reasons
        // see https://stackoverflow.com/a/39705497
        this.zone.runOutsideAngular(() => {
            this.document.addEventListener('click', this.onClickListener);
            this.window.addEventListener('resize', this.onResize);
        });
    }

    public ngOnDestroy(): void {
        this.zone.runOutsideAngular(() => {
            this.document.removeEventListener('click', this.onClickListener);
            this.window.removeEventListener('resize', this.onResize);
        });
    }

    public onResize = () => {
        if (this.isOpen) {
            this.checkOffsets();
        }
    };

    public onClickListener = ({ target }: MouseEvent) => target && this.onClick(target);

    public onClick(target: EventTarget) {
        const el = this.elementRef.nativeElement;

        if (this.isOpen && !el.contains(target)) {
            this.toggleOpen(true, false);
        } else if (el.contains(target)) {
            this.toggleOpen(true, true);
        }
    }

    private toggleOpen(byClick: boolean, open: boolean) {
        if (open && !this.isOpen) {
            this.showTooltip();
        } else if (!open && this.isOpen && this.needsClickClose === byClick) {
            this.hideTooltip();

            return;
        }
        this.needsClickClose = this.needsClickClose || byClick;
    }

    private showTooltip() {
        const parent = this.elementRef.nativeElement;
        this.tooltipComponentRef = this.viewContainerRef.createComponent(
            this.componentFactoryResolver.resolveComponentFactory(CustomTooltipComponent)
        );
        this.tooltipComponentRef.instance.templateRef = this.tooltipContentRef;
        this.renderer.appendChild(parent, this.tooltipComponentRef.location.nativeElement);
        this.checkOffsets();
        this.isOpen = true;
    }

    private hideTooltip() {
        this.viewContainerRef.clear();
        this.isOpen = false;
        this.needsClickClose = false;
    }

    private checkOffsets() {
        if (this.adjustOffsetTimer) {
            cancelAnimationFrame(this.adjustOffsetTimer);
        }

        this.adjustOffsetTimer = requestAnimationFrame(() => this.adjustOffsets());
    }

    private adjustOffsets() {
        const tooltipElement: HTMLElement = (this.tooltipComponentRef as ComponentRef<CustomTooltipComponent>)
            .location.nativeElement;

        const rect = tooltipElement.getBoundingClientRect();
        const innerWidth = this.window.innerWidth;
        const currentMarginOffset = parseFloat(tooltipElement.style.marginLeft) || 0;
        let marginOffset: number | undefined;

        if (rect.left - currentMarginOffset < 0) {
            marginOffset = Math.abs(rect.left - currentMarginOffset);
        } else if (rect.right - currentMarginOffset > innerWidth) {
            marginOffset = -(rect.right - innerWidth - currentMarginOffset);
        }

        if (marginOffset !== undefined) {
            this.renderer.setStyle(tooltipElement, 'margin-left', `${Math.floor(marginOffset)}px`);
        }
    }
}
