import { createAction, props } from '@ngrx/store';
import { Countries, Country } from '../../models/data.types';

const prefixCountries = '[Countries]';

export const REQUEST_COUNTRIES = `${prefixCountries} Trigger get countries`;
export const requestCountries = createAction(REQUEST_COUNTRIES);

export const RECEIVE_COUNTRIES = `${prefixCountries} Receive countries`;
export const receiveCountries = createAction(
    RECEIVE_COUNTRIES,
    props<{
        payload: { countries: Countries };
    }>()
);

export const UPDATE_SELECTED_COUNTRY = `${prefixCountries} Update selected country`;
export const updateSelectedCountry = createAction(
    UPDATE_SELECTED_COUNTRY,
    props<{
        payload: { selectedCountry: Country | null };
    }>()
);

export const UPDATE_SELECTED_COUNTRY_BY_PROPERTY = `${prefixCountries} Update selected country by property`;
export const updateSelectedCountryByProperty = createAction(
    UPDATE_SELECTED_COUNTRY_BY_PROPERTY,
    props<{
        payload: { value: string | number | null };
        property: keyof Country;
    }>()
);

export const REPLACE_SELECTED_COUNTRY = `${prefixCountries} Replace selected country`;
export const replaceSelectedCountry = createAction(
    REPLACE_SELECTED_COUNTRY,
    props<{
        payload: { selectedCountry: Country | null };
    }>()
);

export const REPLACE_SELECTED_COUNTRY_BY_PROPERTY = `${prefixCountries} Replace selected country by property`;
export const replaceSelectedCountryByProperty = createAction(
    REPLACE_SELECTED_COUNTRY_BY_PROPERTY,
    props<{
        payload: { value: string | number | null };
        property: keyof Country;
    }>()
);

/**
 * Dispatched when we actually don't want to do/change anything.
 * Used as fallback when conditionally returning actions in
 * effects in cases where we usually wouldn't want to dispatch an action.
 */
export const NO_OP = 'Do nothing';
/**
 * @see NO_OP
 */
export const noOpAction = createAction(NO_OP);
