import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { MQToggle } from './matchers/common/media-query-toggle.directive';
import {
    BREAKPOINTS,
    getMediaQuerySorter,
    isMQToggleWithExpression,
    MediaQuerySorter,
    MEDIA_QUERIES,
} from './media-query.helper';
import { Breakpoints } from './media-query.interface';

@Injectable({
    providedIn: null,
})
export class MediaQueryMatchService {
    public readonly breakpointChange: Observable<BreakpointState>;

    private sortMediaQueries: MediaQuerySorter = getMediaQuerySorter(this.breakpoints);

    public constructor(
        private breakpointObserver: BreakpointObserver,
        @Inject(BREAKPOINTS) private breakpoints: Breakpoints,
        @Inject(MEDIA_QUERIES) private mediaQueries: Breakpoints
    ) {
        this.breakpointChange = this.breakpointObserver.observe(Object.values(this.mediaQueries));
    }

    /**
     * Of all provided elements, return the first one that matches or `undefined` if there is no match.
     *
     * @param mqCases elements to check for a match
     * @returns the matching element or `undefined`
     */
    public observeFirstMatch(mqCases: MQToggle[]): Observable<MQToggle | undefined> {
        return this.breakpointChange.pipe(
            map((breakpointMatches) => this.getFirstMatch(breakpointMatches, mqCases)),
            distinctUntilChanged()
        );
    }

    /**
     * Observe whether the given element matches the current breakpoint state.
     *
     * @param mqToggle element to observe matching state of
     * @returns true if it matches, false if it doesn't
     */
    public observeMatch(mqToggle: MQToggle): Observable<boolean> {
        return this.breakpointChange.pipe(
            map((breakpointMatches) => this.getToggleActive(breakpointMatches, mqToggle)),
            distinctUntilChanged()
        );
    }

    /**
     * Of the provided cases, get the FIRST one that matches given breakpoints.
     *
     * @param breakpointMatches Matching breakpoints
     * @param mqSwitchCases Cases to match breakpoints against
     * @returns the first matching case
     */
    private getFirstMatch(
        breakpointMatches: BreakpointState,
        mqSwitchCases: MQToggle[]
    ): MQToggle | undefined {
        return this.getMatch(breakpointMatches, mqSwitchCases, true);
    }

    /**
     * Check if the given MQ toggle matches given breakpoints.
     *
     * @param breakpointMatches Matching breakpoints
     * @param mqToggle MQ toggle to match breakpoints against
     * @returns whether the MQ toggle matches the breakpoints
     */
    private getToggleActive(breakpointMatches: BreakpointState, mqToggle: MQToggle): boolean {
        const match = this.getMatch(breakpointMatches, [mqToggle], false);

        return match.includes(mqToggle);
    }

    private getMatch(
        breakpointMatches: BreakpointState,
        mqSwitchCases: MQToggle[],
        single: true
    ): MQToggle | undefined;
    private getMatch(
        breakpointMatches: BreakpointState,
        mqSwitchCases: MQToggle[],
        single: false
    ): MQToggle[];
    private getMatch(
        breakpointMatches: BreakpointState,
        mqSwitchCases: MQToggle[],
        single: boolean
    ): MQToggle | MQToggle[] | undefined {
        const sortedMatches = this.getSortedMatchingBreakpoints(breakpointMatches);
        const operator = single ? 'find' : 'filter';
        mqSwitchCases = this.sortCasesByPriority(mqSwitchCases);
        mqSwitchCases = this.sortCasesByExpression(mqSwitchCases);

        return mqSwitchCases[operator]((item) => item.match(sortedMatches));
    }

    /**
     * Get matching breakpoint names, largest to smallest.
     *
     * @param breakpointMatches the Angular CDK's breakpoint state
     * @returns array of matching media queries by name, sorted from largest to smallest
     */
    private getSortedMatchingBreakpoints(breakpointMatches: BreakpointState): string[] {
        const mappedMatches = Object.entries(breakpointMatches.breakpoints)
            .filter(([, match]) => match)
            .map(([query]) => Object.entries(this.mediaQueries).find(([, mqValue]) => mqValue === query)?.[0])
            .filter((mappedName): mappedName is string => typeof mappedName !== 'undefined');

        const sortedMatches = mappedMatches.sort(this.sortMediaQueries);

        return sortedMatches;
    }

    private sortCasesByPriority(cases: MQToggle[]): MQToggle[] {
        return cases.sort((a, b) => a.matchPriority - b.matchPriority);
    }

    /**
     * Sort cases by their media query (largest to smallest)
     *
     * @param cases Cases to sort
     * @returns sorted cases
     */
    private sortCasesByExpression(cases: MQToggle[]): MQToggle[] {
        return cases
            .filter(isMQToggleWithExpression)
            .sort((a, b) => this.sortMediaQueries(a.matchExpression, b.matchExpression));
    }
}
