import { convertStringToDateObject, formatDate } from '../../helpers/public-helpers';
import { ValueFormatProps } from './value-formatter.interface';

export const createValueFormatter = (props?: ValueFormatProps, language?: string) => {
    const formatValues = (values: string[] | string) => {
        // agGrid returns strings after editing but we need an array
        if (!Array.isArray(values)) {
            values = [values];
        }

        if (!props) {
            return values;
        }

        const columnDecimals =
            typeof props.columnDecimals === 'string' ? props.columnDecimals : props.columnDecimals.toString();
        const columnType = props.columnType || props.valueType;

        switch (columnType) {
            case 'number':
                return values.map((v) => parseFloat(v).toFixed(parseInt(columnDecimals, 10)));
            case 'link':
                return values.map((v) => 'link: ' + v);
            case 'alpha_num':
                return values.map((v) => v.replace(/^0+/, ''));
            case 'date':
                return values.map((v) => {
                    const date = convertStringToDateObject(v);

                    return date ? formatDate(date, false, language) : '';
                });
            default:
                return values;
        }
    };
    const valueUnit = props?.unit ? ' ' + props.unit : '';

    return <T extends { value: string[] | string }>({ value }: T): string => {
        const formattedValue = formatValues(value ?? []).join(' / ');

        return formattedValue ? formattedValue + valueUnit : '';
    };
};

export const formatValue = (values: string | string[], formatProps?: ValueFormatProps, language?: string) =>
    createValueFormatter(formatProps, language)({ value: values });
