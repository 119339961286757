import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ChangedContent, Options } from './omni-channel-manager.interface';

declare const TPP_SNAP: any;

@Injectable()
export class OmniChannelManagerService {
    private contentChangeSubject = new ReplaySubject<ChangedContent>(1);
    // eslint-disable-next-line @typescript-eslint/member-ordering
    public readonly contentChange = this.contentChangeSubject.asObservable();

    public constructor() {
        this.initOCM();
    }

    public createDataset(template: string, options?: Options) {
        if (typeof TPP_SNAP === 'undefined') {
            return;
        }

        TPP_SNAP.createDataset(template, options);
    }

    private initOCM(): void {
        setTimeout(() => {
            if (typeof TPP_SNAP !== 'undefined') {
                TPP_SNAP.onContentChange(($node: HTMLElement, previewId: string, content: any) => {
                    this.contentChangeSubject.next({ node: $node, previewId, content });
                    console.log('onContentChange', { node: $node, previewId, content });

                    return false;
                });
            } else {
                this.initOCM();
            }
        }, 300);
    }
}
