import { Component, Input } from '@angular/core';
import { RowHeader } from '../../compare.interface';

@Component({
    selector: 'osc-compare-label',
    templateUrl: './compare-label.component.html',
    styleUrls: ['./compare-label.component.scss'],
})
export class CompareLabelComponent {
    @Input() public headerItem!: RowHeader;
}
