<div class="energy-saving-box">
    <sew-ws-richtext>
        <sew-markdown
            [markdown]="'\<h2>' + labels?.CMS_MARKET_REGULATION_ENERGY_SAVING_HEADLINE + '\</h2>'"
        ></sew-markdown>
    </sew-ws-richtext>
    <ng-container *ngIf="labels">
        <sew-ws-paragraph size="2">
            {{ labels.CMS_MARKET_REGULATION_ENERGY_SAVING_DESCRIPTION }}
        </sew-ws-paragraph>
        <sew-ws-link>
            <a href="/os/efficiency/Amortisation">{{
                labels.CMS_MARKET_REGULATION_ENERGY_SAVING_LINK_TEXT
            }}</a>
        </sew-ws-link>
        <sew-ws-paragraph size="2">
            {{ labels.CMS_MARKET_REGULATION_CONVERSION_AID_DESCRIPTION }}
        </sew-ws-paragraph>
        <sew-ws-link>
            <a href="/os/efficiency/ConversionAid">{{
                labels.CMS_MARKET_REGULATION_CONVERSION_AID_LINK_TEXT
            }}</a>
        </sew-ws-link>
    </ng-container>
</div>
