import { HttpHeaders, HttpRequest, HttpResponseBase } from '@angular/common/http';
import { InjectionToken } from '@angular/core';

export const OS_API_TOKEN_ENDPOINT = '/os/api/os-core/v1/token';

export const cloneRequestHeaders = (
    request: HttpRequest<unknown> | HttpResponseBase,
    addHeaders: { [key: string]: string },
    removeHeaders: string[] = []
) => {
    // Copy all headers from original request to the new request headers
    const newHeaders = request.headers
        .keys()
        .reduce((headers, header) => ({ ...headers, [header]: request.headers.get(header) as string }), {
            ...addHeaders,
        });

    for (const header of removeHeaders) {
        delete newHeaders[header];
    }

    return new HttpHeaders(newHeaders);
};

/**
 * Token for translate request intercepter to refresh requests after language changes
 */
export const TRANSLATE_REQUEST_HEADER = 'translateRequest';

/**
 * Token for api gateway request intercepter to add the api key
 */
export const API_GATEWAY_REQUEST_API_KEY = 'apiGatewayRequestApiKey';

/**
 * Headername to recognize caas-requests in interceptor for translation handling
 * When no Language is sent as Parameter => Interceptor adds fs_language. Otherwise, Interceptor Changes the value in the filter-header of 'fs_language'
 */
export const CAAS_REQUEST_HEADER = 'sew-caas';

/**
 * Expected language for response content
 */
export const REQUESTED_LANGUAGE_HEADER = 'X-Language';

/**
 * Whether or not to include the {@link REQUESTED_LANGUAGE_HEADER} in "language aware" requests
 */
export const ADD_REQUESTED_LANGUAGE_HEADER = new InjectionToken<boolean>('add requested language header');

/**
 * Header object for a request to indicate it as a translation request.
 * It will cause a refresh of the request after language changes.
 * See also {@link TRANSLATE_REQUEST_HEADER const REQUESTED_LANGUAGE_HEADER}
 */
export const getOptionsWithTranslateHeader = () => ({
    headers: {
        [TRANSLATE_REQUEST_HEADER]: 'true',
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
    },
});

/**
 * Header object for a request to indicate it as a translation request.
 * It will cause a refresh of the request after language changes.
 * See also {@link TRANSLATE_REQUEST_HEADER const REQUESTED_LANGUAGE_HEADER}
 */
export const getCaasTranslateHeader = () => ({
    headers: {
        [TRANSLATE_REQUEST_HEADER]: 'true',
        [CAAS_REQUEST_HEADER]: 'true',
    },
});

/**
 * Header object for a request to indicate it as an api gateway request that needs an api key.
 * See also {@link API_GATEWAY_REQUEST_API_KEY const API_GATEWAY_REQUEST_API_KEY}
 */
export const getApiGatewayHeaderWithApiKey = () => ({
    headers: {
        [API_GATEWAY_REQUEST_API_KEY]: 'true',
    },
});
