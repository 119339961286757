import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { OmniChannelManagerService } from '@os-components';
import { environment } from '../../../environments/environment';
import { DropdownInputPipe } from '../../pipes/dropdown-input.pipe';
import { CountriesEffects } from '../../stores/countries/countries.effects';
import * as fromCountries from '../../stores/countries/countries.reducer';
import { ComponentsEffects } from '../../stores/market-components/components.effects';
import * as fromComponents from '../../stores/market-components/components.reducer';
import { RegulationsEffects } from '../../stores/regulations/regulations.effects';
import * as fromRegulations from '../../stores/regulations/regulations.reducer';
import { ResultCountEffects } from '../../stores/result-count/result-count.effects';
import * as fromResultCount from '../../stores/result-count/result-count.reducer';
import * as fromRouter from '../../stores/router/router.reducer';
import { EnergyComponent } from './components/energy/energy.component';
import { MapComponent } from './components/map/map.component';
import { PreviewDashboardModule } from './components/preview-dashboard/preview-dashboard.module';
import { LandingPageRoutingModule } from './landing-page-routing.module';
import { LandingPageComponent } from './landing-page.component';

let onlyPreviewModules = [PreviewDashboardModule];
let onlyPreviewServices = [OmniChannelManagerService];

if (!environment.preview) {
    onlyPreviewModules = [];
    onlyPreviewServices = [];
}
@NgModule({
    declarations: [LandingPageComponent, MapComponent, EnergyComponent, DropdownInputPipe],
    imports: [
        CommonModule,
        LandingPageRoutingModule,
        StoreModule.forFeature(fromCountries.countriesFeatureKey, fromCountries.reducer),
        StoreModule.forFeature(fromRegulations.regulationsFeatureKey, fromRegulations.reducer),
        StoreModule.forFeature(fromComponents.componentsFeatureKey, fromComponents.reducer),
        StoreModule.forFeature(fromResultCount.resultCountFeatureKey, fromResultCount.reducer),
        EffectsModule.forFeature([
            CountriesEffects,
            RegulationsEffects,
            ComponentsEffects,
            ResultCountEffects,
        ]),
        StoreRouterConnectingModule.forRoot(),
        ...onlyPreviewModules,
    ],
    providers: [
        { provide: RouterStateSerializer, useClass: fromRouter.CustomSerializer },
        ...onlyPreviewServices,
    ],
    exports: [LandingPageComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LandingPageModule {}
