import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { Country, MarketComponent, Regulation } from '../models/data.types';
import {
    replaceSelectedCountryByProperty,
    requestCountries,
    updateSelectedCountryByProperty,
} from './countries/countries.actions';
import { selectCountries, selectSelectedCountry } from './countries/countries.selectors';
import {
    replaceSelectedComponentByProperty,
    requestComponents,
    updateSelectedComponentByProperty,
} from './market-components/components.actions';
import { selectComponents, selectSelectedComponent } from './market-components/components.selectors';
import {
    replaceSelectedRegulation,
    replaceSelectedRegulationByProperty,
    updateSelectedRegulationByProperty,
} from './regulations/regulations.actions';
import { selectRegulations, selectSelectedRegulation } from './regulations/regulations.selectors';
import { selectResultCount } from './result-count/result-count.selectors';
import { selectParams, selectRouterState } from './router/router.selectors';

@Injectable({
    providedIn: 'root',
})
export class RootStoreService {
    public constructor(private store: Store) {}

    public fetchCountries() {
        this.store.dispatch(requestCountries());
    }

    public updateSelectedCountryById(selectedCountryId: number | null) {
        this.store.dispatch(
            updateSelectedCountryByProperty({ payload: { value: selectedCountryId }, property: 'id' })
        );
    }

    public updateSelectedCountryByProperty(value: number | string | string, property: keyof Country) {
        this.store.dispatch(updateSelectedCountryByProperty({ payload: { value }, property }));
    }

    public replaceSelectedCountryById(selectedCountryId: number | null) {
        this.store.dispatch(
            replaceSelectedCountryByProperty({ payload: { value: selectedCountryId }, property: 'id' })
        );
    }

    public getCountries() {
        return this.store.select(selectCountries);
    }

    public getSelectedCountry() {
        return this.store.select(selectSelectedCountry);
    }

    public fetchComponents() {
        this.store.dispatch(requestComponents());
    }

    public updateSelectedComponentById(selectedComponentId: number | null) {
        this.store.dispatch(
            updateSelectedComponentByProperty({ payload: { value: selectedComponentId }, property: 'id' })
        );
    }

    public updateSelectedComponentByProperty(value: number | string | null, property: keyof MarketComponent) {
        this.store.dispatch(updateSelectedComponentByProperty({ payload: { value }, property }));
    }

    public replaceSelectedComponentById(selectedComponentId: number | null) {
        this.store.dispatch(
            replaceSelectedComponentByProperty({ payload: { value: selectedComponentId }, property: 'id' })
        );
    }

    public getComponents() {
        return this.store.select(selectComponents);
    }

    public getSelectedComponent() {
        return this.store.select(selectSelectedComponent);
    }

    public updateSelectedRegulationById(selectedRegulationId: string | null) {
        this.store.dispatch(
            updateSelectedRegulationByProperty({ payload: { value: selectedRegulationId }, property: 'id' })
        );
    }

    public updateSelectedRegulationByProperty(value: string | number | null, property: keyof Regulation) {
        this.store.dispatch(updateSelectedRegulationByProperty({ payload: { value }, property }));
    }

    public replaceSelectedRegulationById(selectedRegulationId: string | null) {
        this.store.dispatch(
            replaceSelectedRegulationByProperty({ payload: { value: selectedRegulationId }, property: 'id' })
        );
    }

    public replaceSelectedRegulation(selectedRegulation: Regulation) {
        this.store.dispatch(replaceSelectedRegulation({ payload: { selectedRegulation } }));
    }

    public getRegulations() {
        return this.store.select(selectRegulations);
    }

    public getSelectedRegulation() {
        return this.store.select(selectSelectedRegulation);
    }

    public getResultCount() {
        return this.store.select(selectResultCount);
    }

    public getSelections() {
        return combineLatest([
            this.getSelectedCountry(),
            this.getSelectedComponent(),
            this.getSelectedRegulation(),
        ]);
    }

    public getRouterSate() {
        return this.store.select(selectRouterState);
    }

    public getRouterParams() {
        return this.store.select(selectParams);
    }
}
