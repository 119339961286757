import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@reg-environment';
import { Countries, Country, MarketComponent, Regulation } from '@reg/models/data.types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getLanguageParam, getParams } from '../../helpers/http.helper';
import { getOptionsWithTranslateHeader } from '../../interceptors/translate.interceptor';
import { ComponentsResponse } from './types/components-filter-data';
import { CountriesResponse } from './types/country-filter-data';
import { RegulationsResponse } from './types/regulations-filter-data';

@Injectable({
    providedIn: 'root',
})
export class FilterDataService {
    public constructor(private http: HttpClient) {}

    public getCountries(): Observable<Countries> {
        const params = getParams('avars');

        return this.http
            .get<CountriesResponse>(
                `${environment.caas.baseUrl}/direct/InternationalRegulations/market_regulation_mapping/_aggrs/countries`,
                { ...getOptionsWithTranslateHeader(), params }
            )
            .pipe(
                map((response: CountriesResponse) => {
                    const countries: Country[] = [];
                    if (response._embedded) {
                        response._embedded['rh:result'].forEach((country) => {
                            countries.push({
                                id: country._id.country_id,
                                cofu: country._id.cofu,
                                code: country._id.country_code,
                                name: country._id.name,
                                image: country._id.image,
                                info: country._id.info,
                                coordinates: country._id.coordinates,
                                count: country.count,
                                continent: country._id.continent,
                                zoom: country._id.zoom,
                                tradeZone: country._id.trade_zone,
                                previewId: country._id.preview_id,
                            });
                        });
                    }

                    return countries;
                })
            );
    }

    public getComponentsByCountry(countryId: number): Observable<MarketComponent[]> {
        const params = getParams('avars', `'country': ${countryId}`);

        return this.http
            .get<ComponentsResponse>(
                `${environment.caas.baseUrl}/direct/InternationalRegulations/market_regulation_mapping/_aggrs/components_by_country`,
                {
                    ...getOptionsWithTranslateHeader(),
                    params,
                }
            )
            .pipe(
                map((response: ComponentsResponse) => {
                    const components: MarketComponent[] = [];
                    response._embedded['rh:result'].forEach((component) => {
                        components.push({
                            id: component._id.component_id,
                            name: component._id.name,
                            count: component.count,
                        });
                    });

                    return components;
                })
            );
    }

    public getFilterDataRegulations(countryId: number, componentId: number): Observable<Regulation[]> {
        const params = new HttpParams()
            .set(
                'filter',
                `{'$and':[{'visible':true},{'countries':${countryId}},{'components':${componentId}},{${getLanguageParam()}}]}`
            )
            .set('keys', "{ 'shortTitle': 1, 'regulation_id': 1 }");

        return this.http
            .get<RegulationsResponse>(
                `${environment.caas.baseUrl}/direct/InternationalRegulations/market_regulations`,
                {
                    ...getOptionsWithTranslateHeader(),
                    params,
                }
            )
            .pipe(
                map((response: RegulationsResponse) => {
                    const regulations: Regulation[] = [];
                    response._embedded['rh:doc'].forEach((regulation) => {
                        regulations.push({
                            id: regulation._id,
                            dbId: regulation.regulation_id,
                            name: regulation.shortTitle,
                            count: 1,
                        });
                    });

                    return regulations;
                })
            );
    }
}
