import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { combineLatest, of } from 'rxjs';
import { filter, first, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { FilterDataService } from '../../services/filter/filter-data.service';
import * as ComponentsActions from '../market-components/components.actions';
import * as ResultCountActions from '../result-count/result-count.actions';
import { RootStoreService } from '../root-store.service';
import * as RegulationsActions from './regulations.actions';

@Injectable()
export class RegulationsEffects {
    public requestRegulations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ComponentsActions.updateSelectedComponent),
            switchMap(() =>
                combineLatest([
                    this.rootStoreService.getSelectedCountry(),
                    this.rootStoreService.getSelectedComponent(),
                ]).pipe(first())
            ),
            filter(([country, component]) => country !== null && component !== null),
            switchMap(([country, component]) => {
                if (country && component) {
                    return this.filterDataService.getFilterDataRegulations(country.id, component.id);
                } else {
                    return of([]);
                }
            }),
            withLatestFrom(
                this.rootStoreService.getRouterParams(),
                this.rootStoreService.getSelectedRegulation(),
                (regulations, params, selectedRegulation) => ({
                    regulations,
                    params,
                    selectedRegulation,
                })
            ),
            tap(({ params, selectedRegulation }) => {
                if (selectedRegulation === null) {
                    const regulation = params.regulation;
                    if (regulation) {
                        this.rootStoreService.updateSelectedRegulationByProperty(regulation, 'name');
                    }
                } else {
                    this.rootStoreService.updateSelectedRegulationByProperty(selectedRegulation.dbId, 'dbId');
                }
            }),
            map(({ regulations }) => RegulationsActions.receiveRegulations({ payload: { regulations } }))
        )
    );

    public updateSelectedRegulationByProperty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RegulationsActions.updateSelectedRegulationByProperty),
            switchMap(({ payload: { value }, property }) =>
                this.rootStoreService.getRegulations().pipe(
                    map((regulations) => {
                        const filteredRegulations = regulations.filter(
                            (regulation) => regulation[property] === value
                        );

                        return filteredRegulations.length ? filteredRegulations[0] : null;
                    })
                )
            ),
            switchMap((selectedRegulation) => [
                RegulationsActions.updateSelectedRegulation({ payload: { selectedRegulation } }),
                ResultCountActions.calculateResultCount(),
            ])
        )
    );

    public replaceSelectedRegulationByProperty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(RegulationsActions.replaceSelectedRegulationByProperty),
            switchMap(({ payload: { value }, property }) =>
                this.rootStoreService.getRegulations().pipe(
                    filter((regulations) => regulations.length > 0),
                    map((regulations) => {
                        const filteredRegulations = regulations.find(
                            (regulation) => regulation[property] === value
                        );

                        return filteredRegulations ?? null;
                    })
                )
            ),
            switchMap((selectedRegulation) => [
                RegulationsActions.replaceSelectedRegulation({ payload: { selectedRegulation } }),
                ResultCountActions.calculateResultCount(),
            ])
        )
    );

    public constructor(
        private actions$: Actions,
        private filterDataService: FilterDataService,
        private rootStoreService: RootStoreService
    ) {}
}
