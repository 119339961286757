import { forwardRef, InjectionToken } from '@angular/core';
import {
    MediaQueryToggleDirective,
    MQToggle,
    MQToggleWithExpression,
} from './matchers/common/media-query-toggle.directive';
import { Breakpoints } from './media-query.interface';

/**
 * Application breakpoints
 */
export const BREAKPOINTS = new InjectionToken<Breakpoints>('App breakpoints');
/**
 * Application media queries
 */
export const MEDIA_QUERIES = new InjectionToken<Breakpoints>('App media queries');

/**
 * Create a sorting function for given breakpoints
 *
 * @param breakpoints breakpoints to sort given media query names after
 * @returns function to sort media queries from largest to smallest
 */
export const getMediaQuerySorter = (breakpoints: Breakpoints) => {
    const sortMediaQueries: (a: string | string[], b: string | string[]) => number = (mqA, mqB) => {
        mqA = Array.isArray(mqA) ? mqA.sort(sortMediaQueries)[0] : mqA;
        mqB = Array.isArray(mqB) ? mqB.sort(sortMediaQueries)[0] : mqB;

        return parseInt(breakpoints[mqB], 10) - parseInt(breakpoints[mqA], 10);
    };

    return sortMediaQueries;
};

/**
 * @see {@link getMediaQuerySorter}
 */
export type MediaQuerySorter = ReturnType<typeof getMediaQuerySorter>;

export const provideMediaQuerySwitchCase = (component: any) => ({
    provide: MediaQueryToggleDirective,
    useExisting: forwardRef(() => component),
});

export const isMQToggleWithExpression = (item: MQToggle): item is MQToggleWithExpression => {
    const expression = item.matchExpression;

    if (typeof expression === 'undefined') {
        return false;
    }

    return (
        (typeof expression === 'string' && expression.length > 0) ||
        (Array.isArray(expression) && expression.filter((value) => value).length > 0)
    );
};
