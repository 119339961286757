import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { delay, distinctUntilChanged, expand, map, Observable, ReplaySubject, tap } from 'rxjs';
import { ApiTokenEmitter, Tokens } from './os-api-auth.interface';
import { HeaderLoginService } from '../public-services';

export const TOKEN_BASE_HREF = new InjectionToken<string>('OS_API:TOKEN_BASE_HREF');

/**
 * Service for fetching a x-csrf token for the user
 */
@Injectable({
    providedIn: null,
})
export class OsApiAuthService {
    private _xCsrfToken = new ReplaySubject<string>(1);
    private oAuthAccessToken$ = new ReplaySubject<Tokens>(1);
    private _oAuthAccessToken: Tokens = {};

    public constructor(
        private http: HttpClient,
        @Inject(TOKEN_BASE_HREF) private tokenURL: string,
        headerLoginService: HeaderLoginService
    ) {
        headerLoginService.loggedIn.subscribe((isLoggedIn) => {
            if (!isLoggedIn) {
                this._oAuthAccessToken = {};
                this.oAuthAccessToken$.next(this._oAuthAccessToken);
            }
        });
    }

    /**
     * Fetch the token from the server on initial app load
     * and later after the refresh runs out
     */
    public fetchXCsrfToken() {
        // @TODO: Error handling
        return this.fetchXCsrfTokenResponse().pipe(
            expand((response) => this.fetchXCsrfTokenResponse().pipe(delay((response.body ?? 1800) * 900))),
            map((response) => response.headers.get('x-csrf-token') as string),
            tap((token) => this.setXCsrfToken(token))
        );
    }

    public get allOAuthAccessToken(): Observable<Tokens> {
        return this.oAuthAccessToken$.asObservable().pipe(distinctUntilChanged());
    }

    public setOAuthAccessToken(accessToken: ApiTokenEmitter) {
        this._oAuthAccessToken[accessToken.api] = accessToken.token;
        this.oAuthAccessToken$.next(this._oAuthAccessToken);
    }

    private fetchXCsrfTokenResponse() {
        return this.http.get<number>(this.tokenURL, {
            headers: new HttpHeaders({
                'x-csrf-token': 'fetch',
            }),
            withCredentials: true,
            observe: 'response',
        });
    }

    public get xCsrfToken(): Observable<string> {
        return this._xCsrfToken.asObservable().pipe(distinctUntilChanged());
    }

    private setXCsrfToken(newToken: string) {
        this._xCsrfToken.next(newToken);
    }
}
