import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { OnDestroyBase, getWikiApiLanguageKey, getWikiLanguageKey } from '../../helpers/public-helpers';
import { TranslateChildModule } from '../../helpers/translations/translate-child.module';
import { HeaderConfigService } from '../../services/public-services';

@Component({
    standalone: true,
    selector: 'osc-online-help',
    templateUrl: './online-help.component.html',
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    styleUrls: ['./online-help.component.scss'],
    imports: [TranslateChildModule],
})
export class OnlineHelpComponent extends OnDestroyBase implements OnInit {
    @Input() public url?: string;
    @Input() public tooltip?: string;
    @Input() public product?: string;
    @Input() public data?: string;
    /** Es gibt verschiedene Sprachkürzel für direktaufruf und API-Aufruf
     * z.B. direkt = en-EN, api = en-DE
     */
    @Input() public isApi = true;

    public wikiHelpLink?: string;
    private wikiLanguage?: string;

    public constructor(public sewConfigService: HeaderConfigService) {
        super();
    }

    public ngOnInit(): void {
        this.sewConfigService.language.pipe(takeUntil(this.destroySubject)).subscribe((language) => {
            this.handleLanguageChange(language);
        });
    }

    /**
     * Handles a language change by detecting the new wiki language and setting the help link accordingly
     *
     * @param value os language
     */
    private handleLanguageChange(value: string) {
        this.wikiLanguage = this.isApi ? getWikiApiLanguageKey(value) : getWikiLanguageKey(value); //TODO: #418811 wieder neue Sprache - Have Fun! :-)
        this.wikiHelpLink = this.createHelpLinkForWiki(this.product, this.data);
    }

    /**
     *
     * @param product
     * @param data
     * @returns
     */
    private createHelpLinkForWiki(product: string | undefined, data: string | undefined) {
        return data && product
            ? `${this.url}/?country=${this.sewConfigService.getCountryShort().toUpperCase()}&language=${
                  this.wikiLanguage
              }&product=${product}&role=tool&data=${data}&devFamily=&devPartNum=&devVersion=&version`
            : `${this.url}/${this.wikiLanguage}/index.html`;
    }
}
