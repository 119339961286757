import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { API_AUTH_REALMS, OsApiAuthInterceptor } from './os-api-auth.interceptor';
import { OsApiAuthConfig, OsApiAuthRealms } from './os-api-auth.interface';
import { OsApiAuthService, TOKEN_BASE_HREF } from './os-api-auth.service';
import { OsApiRetryUnauthorizedInterceptor } from './os-api-retry-unauthorized.interceptor';

@NgModule({})
/* istanbul ignore file */
export class OsApiAuthModule {
    public constructor(@Optional() @SkipSelf() public parentModule: OsApiAuthModule) {
        if (parentModule) {
            throw new Error('OsApiAuthModule is already loaded. Import it once only!');
        }
    }

    /**
     * Configure and provide {@link OsApiAuthService} and {@link OsApiAuthInterceptor}.
     *
     * The former will fetch an authentication token to use as X-CSRF-Token in
     * all API realms that are configured to use it (useXCsrfToken = true) from the URL
     * provided in `config`.
     *
     * The latter will intercept requests made to API realms their respective `path`.
     * It will then add tokens and headers as configured and rewrite
     * the path to their configured URL.
     *
     * @param config Global API/auth configuration, regardless of API realm
     * @param realms Configurations for individual API "areas"
     */
    public static forRoot(
        config: OsApiAuthConfig,
        realms: OsApiAuthRealms
    ): ModuleWithProviders<OsApiAuthModule> {
        return {
            ngModule: OsApiAuthModule,
            providers: [
                { provide: TOKEN_BASE_HREF, useValue: config.xCsrfTokenUrl },
                { provide: API_AUTH_REALMS, useValue: realms },
                OsApiAuthInterceptor,
                OsApiAuthService,
                OsApiRetryUnauthorizedInterceptor,
            ],
        };
    }
}
