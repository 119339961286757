import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ElementRef, Input, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { mediaQueries } from '@reg-helpers';
import { Country, MarketComponent, Regulation } from '@reg/models/data.types';
import { EMPTY, Observable } from 'rxjs';
import { delay, filter, first, skip, switchMap, withLatestFrom } from 'rxjs/operators';
import {
    HeaderConfigService,
    OmniChannelManagerService,
} from '../../../../../lib-os-components/src/public-api';
import { environment } from '../../../environments/environment';
import { FilterDataService } from '../../services/filter/filter-data.service';
import { Labels } from '../../services/labels/label.types';
import { LabelsService } from '../../services/labels/labels.service';
import { RootStoreService } from '../../stores/root-store.service';

export type CurrentPage = 'landingPage' | 'resultPage';

interface DialoagSettings {
    change: 'Country' | 'Component';
    id: number;
}

@Component({
    selector: 'reg-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit, OnDestroy {
    @Input() public currentPage: CurrentPage = 'landingPage';

    public allRegulations!: number;
    public count?: Observable<number>;
    public countries!: Observable<Country[]>;
    public components!: Observable<MarketComponent[]>;
    public regulations!: Observable<Regulation[]>;

    public selectedCountryId!: number | null;
    public selectedComponentId!: number | null;
    public selectedRegulationId!: string | null;

    public isMobile!: boolean;
    public labels?: Labels;
    public displayCancelDialog = false;
    public isPreview = false;

    @ViewChild('regulationsDropdown') private regulationsDropdown?: ElementRef<HTMLSelectElement>;
    @ViewChild('componentDropdown') private componentDropdown?: ElementRef<HTMLSelectElement>;

    private dialogSettings?: DialoagSettings;

    public constructor(
        private router: Router,
        private breakpointObserver: BreakpointObserver,
        private rootStoreService: RootStoreService,
        private filterDataSerice: FilterDataService,
        private configService: HeaderConfigService,
        private labelService: LabelsService,
        @Optional() private ocmService: OmniChannelManagerService
    ) {}

    public ngOnInit() {
        this.isPreview = environment.preview;

        if (environment.preview) {
            this.rootStoreService
                .getCountries()
                // skip first result, caused by initial load
                .pipe(skip(1), delay(10000))
                .subscribe(() => {
                    // refresh the country view in the browser
                    this.rootStoreService.updateSelectedCountryById(this.selectedCountryId);
                });

            this.ocmService.contentChange.pipe(delay(10000)).subscribe(() => {
                this.rootStoreService.fetchCountries();
            });
        }

        this.configService.cmsProject
            .pipe(
                filter((cmsProject) => cmsProject.length > 0),
                first()
            )
            .subscribe(() => {
                this.rootStoreService.fetchCountries();

                this.labelService.labels.subscribe((labels) => (this.labels = labels));

                this.breakpointObserver.observe(mediaQueries.mq2).subscribe(({ matches }) => {
                    this.isMobile = !matches;
                });

                this.count = this.rootStoreService.getResultCount();
                this.countries = this.rootStoreService.getCountries();
                this.components = this.rootStoreService.getComponents();
                this.regulations = this.rootStoreService.getRegulations();

                this.countries.subscribe((countries) => {
                    this.allRegulations = countries.reduce((sum, country) => sum + country.count, 0);
                });

                this.rootStoreService.getSelectedCountry().subscribe((country) => {
                    this.selectedCountryId = country ? country.id : null;
                });

                this.rootStoreService.getSelectedComponent().subscribe((component) => {
                    this.selectedComponentId = component ? component.id : null;
                    if (this.selectedComponentId === null && this.componentDropdown !== undefined) {
                        this.componentDropdown.nativeElement.value = '';
                    }
                });

                this.rootStoreService.getSelectedRegulation().subscribe((regulation) => {
                    this.selectedRegulationId = regulation ? regulation.id : null;
                    if (this.selectedRegulationId === null && this.regulationsDropdown !== undefined) {
                        this.regulationsDropdown.nativeElement.value = '';
                    }
                });
            });
    }

    public ngOnDestroy(): void {
        this.breakpointObserver.ngOnDestroy();
    }

    public countrySelected(event: any) {
        const value = event.detail.value;
        const newSelectedCountryId = value !== null ? parseInt(value, 10) : value;
        if (this.selectedCountryId === newSelectedCountryId) {
            return;
        }
        if (this.currentPage === 'resultPage') {
            if (newSelectedCountryId) {
                if (this.selectedCountryId) {
                    this.filterDataSerice
                        .getComponentsByCountry(newSelectedCountryId)
                        .pipe(
                            withLatestFrom(
                                this.rootStoreService.getSelectedComponent(),
                                (components, selectedComponent) => ({ components, selectedComponent })
                            ),
                            switchMap(({ components, selectedComponent }) => {
                                const comp: MarketComponent | undefined = components.find(
                                    (component) => component.id === selectedComponent?.id
                                );

                                if (this.selectedCountryId && comp) {
                                    return this.filterDataSerice
                                        .getFilterDataRegulations(newSelectedCountryId, comp?.id)
                                        .pipe(
                                            withLatestFrom(
                                                this.rootStoreService.getSelectedRegulation(),
                                                (regulations, selectedRegulation) => ({
                                                    regulations,
                                                    selectedRegulation,
                                                    selectedComponent: comp,
                                                })
                                            )
                                        );
                                }
                                this.showCountryChangeDialog(newSelectedCountryId);

                                return EMPTY;
                            })
                        )
                        .subscribe(({ regulations, selectedRegulation, selectedComponent }) => {
                            const reg = regulations.find(
                                // if the language was changed we find the same regulation with the dbId and
                                // if the country was changed we find it via the name property
                                (regulation) =>
                                    regulation.dbId === selectedRegulation?.dbId ||
                                    regulation.name === selectedRegulation?.name
                            );

                            if (reg) {
                                this.rootStoreService.replaceSelectedCountryById(newSelectedCountryId);
                                this.rootStoreService.replaceSelectedComponentById(selectedComponent.id);
                                this.rootStoreService.replaceSelectedRegulation(reg);
                            } else {
                                this.showCountryChangeDialog(newSelectedCountryId);
                            }
                        });
                }

                return;
            } else {
                this.router.navigate(['']);
            }
        }
        this.rootStoreService.updateSelectedCountryById(newSelectedCountryId);
    }

    public componentSelected(event: any) {
        const value = event.detail.value;
        const newSelectedComponent = value !== null ? parseInt(value, 10) : value;
        if (this.selectedComponentId === newSelectedComponent) {
            return;
        }

        if (this.currentPage === 'resultPage') {
            if (newSelectedComponent) {
                if (this.selectedCountryId && this.selectedComponentId) {
                    this.filterDataSerice
                        .getFilterDataRegulations(this.selectedCountryId, newSelectedComponent)
                        .pipe(
                            withLatestFrom(
                                this.rootStoreService.getSelectedRegulation(),
                                (regulations, selectedRegulation) => ({ regulations, selectedRegulation })
                            )
                        )
                        .subscribe(({ regulations, selectedRegulation }) => {
                            const reg = regulations.find(
                                // if the language was changed we find the same regulation with the dbId and
                                // if the component was changed we find it via the name property
                                (regulation) =>
                                    regulation.dbId === selectedRegulation?.dbId ||
                                    regulation.name === selectedRegulation?.name
                            );
                            // check if new regulations contain already selected regulation
                            if (reg) {
                                // if true, just replace component and regulation
                                this.rootStoreService.replaceSelectedComponentById(newSelectedComponent);
                                this.rootStoreService.replaceSelectedRegulation(reg);
                            } else {
                                // if not -> show dialog
                                this.dialogSettings = {
                                    change: 'Component',
                                    id: newSelectedComponent,
                                };
                                this.displayCancelDialog = true;
                            }
                        });
                }

                return;
            } else {
                this.router.navigate(['']);
            }
        }

        this.rootStoreService.updateSelectedComponentById(newSelectedComponent);
    }

    public regulationSelected(event: any) {
        const value = event.detail.value;
        const newSelectedRegulation = value !== null ? value : value;
        if (this.selectedRegulationId === newSelectedRegulation) {
            return;
        }
        if (newSelectedRegulation === null && this.currentPage === 'resultPage') {
            this.router.navigate(['']);
        }
        this.rootStoreService.updateSelectedRegulationById(newSelectedRegulation);
    }

    public handleButtonClick() {
        if (this.currentPage === 'landingPage') {
            this.rootStoreService
                .getSelections()
                .pipe(first())
                .subscribe(([country, component, regulation]) => {
                    if (country && component && regulation) {
                        this.router.navigate(['/result/', country.name, component.name, regulation.name], {
                            queryParamsHandling: 'preserve',
                        });
                    } else {
                        this.router.navigate(['/result'], { queryParamsHandling: 'preserve' });
                    }
                });
        } else {
            this.router.navigate(['']);
        }
    }

    public scrollToFilterTop(element: HTMLSewComboboxElement) {
        if (this.isMobile) {
            // wait until dropdown is expanded completly to prevent scroll errors
            setTimeout(() => {
                element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 200);
        }
    }

    public confirmFilterChange() {
        if (this.dialogSettings?.change === 'Country') {
            const newValue = this.dialogSettings.id;
            if (this.selectedCountryId === newValue) {
                return;
            }
            this.selectedCountryId = newValue;
            this.selectedComponentId = 0;
            this.selectedRegulationId = '';
            this.rootStoreService.updateSelectedCountryById(this.selectedCountryId);
            this.router.navigate(['']);
        } else if (this.dialogSettings?.change === 'Component') {
            const newValue = this.dialogSettings.id;
            if (this.selectedComponentId === newValue) {
                return;
            }
            this.selectedComponentId = newValue;
            this.selectedRegulationId = '';
            this.rootStoreService.updateSelectedComponentById(this.selectedComponentId);
            this.router.navigate(['']);
        }
        this.dialogSettings = undefined;
        this.displayCancelDialog = false;
    }

    public cancelFilterChange() {
        if (this.dialogSettings?.change === 'Country') {
            const oldValue = this.selectedCountryId;
            this.selectedCountryId = 0;
            setTimeout(() => {
                this.selectedCountryId = oldValue;
            }, 0);
        } else if (this.dialogSettings?.change === 'Component') {
            const oldValue = this.selectedComponentId;
            this.selectedComponentId = 0;
            setTimeout(() => {
                this.selectedComponentId = oldValue;
            }, 0);
        }
        this.dialogSettings = undefined;
        this.displayCancelDialog = false;
    }

    public switchLanguage(event: any) {
        const value: string = event.detail.value;

        if (!value) {
            return;
        }

        this.configService.setLanguage(value.toLocaleLowerCase());
    }

    private showCountryChangeDialog(countryId: number) {
        this.dialogSettings = {
            change: 'Country',
            id: countryId,
        };
        this.displayCancelDialog = true;
    }
}
