import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateChildModule } from '../../../helpers/translations/translate-child.module';
import { OnlineHelpComponent } from '../../online-help/online-help.component';
import { ModalWrapperComponent } from './modal-wrapper.component';

@NgModule({
    declarations: [ModalWrapperComponent],
    imports: [CommonModule, MatDialogModule, TranslateChildModule, OnlineHelpComponent],
    exports: [ModalWrapperComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ModalWrapperModule {}
