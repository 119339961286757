<ng-container *ngIf="editing; else readonly">
    <input
        #editInput
        type="text"
        [(ngModel)]="stringValue"
        (focusout)="stopEditing()"
        (keyup.enter)="stopEditing()"
    />
    <osc-icon-button
        class="icon-button"
        icon="done"
        [tooltip]="'EDITABLE_TEXT.EDITING_DONE' | translate"
        (click)="stopEditing()"
    ></osc-icon-button>
</ng-container>

<ng-template #readonly>
    <span [class.show-white-space]="showWhiteSpace" [title]="formattedValue" (dblclick)="startEditing()">{{
        formattedValue
    }}</span>
    <osc-icon-button
        class="icon-button"
        icon="edit"
        [tooltip]="'EDITABLE_TEXT.EDITING_START' | translate"
        (click)="startEditing()"
    ></osc-icon-button>
</ng-template>
