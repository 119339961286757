import { createReducer, on } from '@ngrx/store';
import { Regulation, Regulations } from '../../models/data.types';
import * as RegulationsActions from './regulations.actions';

export const regulationsFeatureKey = 'regulations';

export interface RegulationState {
    regulations: Regulations;
    selectedRegulation: Regulation | null;
}

export const initialState: RegulationState = {
    regulations: [],
    selectedRegulation: null,
};

export const reducer = createReducer(
    initialState,

    on(RegulationsActions.receiveRegulations, (state, { payload: { regulations } }) => ({
        ...state,
        regulations,
    })),

    on(RegulationsActions.updateSelectedRegulation, (state, { payload: { selectedRegulation } }) => ({
        ...state,
        selectedRegulation,
    })),

    on(RegulationsActions.replaceSelectedRegulation, (state, { payload: { selectedRegulation } }) => ({
        ...state,
        selectedRegulation,
    }))
);
