import { AfterContentInit, Component, ContentChildren, Input, OnDestroy, QueryList } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map, share, startWith, switchMap, tap } from 'rxjs/operators';
import { ListFilterDirective } from '../list-filter.directive';
import { NullableListViewFilterFn } from '../list-filter.interface';
import { ListFilterService } from '../list-filter.service';
import { ListViewFilterFn } from './../list-filter.interface';

/**
 * Collects filters from `n` components and turns them into an Observable of up to `n` filter functions.
 * Since filter components may provide `null` for their filter function (i.e. they're disabled), the observable
 * can emit with less filter functions than there are filter components.
 */
@Component({
    selector: 'osc-filter-accumulator',
    templateUrl: './filter-accumulator.component.html',
    styleUrls: ['./filter-accumulator.component.scss'],
    providers: [ListFilterService],
})
export class FilterAccumulatorComponent implements OnDestroy, AfterContentInit {
    /**
     * Input to enable/disable the show all button in the template if not needed.
     */
    @Input() public displayShowAllButton = true;

    @ContentChildren(ListFilterDirective)
    private filterDirectives!: QueryList<ListFilterDirective>;

    private activeFilters = 0;
    private filterSource!: Observable<NonNullable<ListViewFilterFn>[]>;

    /**
     * Number of active filters in this accumulator.
     * This equals the number of filter components that currently provide a filter function (not null).
     */
    public get activeFilterCount() {
        return this.activeFilters;
    }

    /**
     * Number of total available filters in this accumulator.
     * This equals the number of filter components (using the {@link ListFilterDirective}).
     */
    public get availableFilterCount() {
        return this.filterDirectives?.length;
    }

    /**
     * Functions to apply to each item for filtering
     */
    public get filters() {
        return this.filterSource;
    }

    public constructor(private listFilterService: ListFilterService) {}

    /**
     * Trigger a reset for all filters in this accumulator
     */
    public resetAllFilters() {
        this.listFilterService.triggerResetAllFilters();
    }

    public ngOnDestroy() {
        this.listFilterService.destroy();
    }

    public ngAfterContentInit(): void {
        setTimeout(() => {
            this.filterSource = (this.filterDirectives.changes as Observable<
                QueryList<ListFilterDirective>
            >).pipe(
                startWith(this.filterDirectives),
                switchMap((filterDirectives) =>
                    filterDirectives.length > 0
                        ? combineLatest(
                              filterDirectives.map((directive) =>
                                  directive.getFilterFunction().pipe(startWith(null))
                              )
                          )
                        : of([] as ListViewFilterFn[])
                ),
                map<NullableListViewFilterFn[], ListViewFilterFn[]>((filterFns) =>
                    filterFns.filter<ListViewFilterFn>(
                        (filterFn): filterFn is ListViewFilterFn => filterFn !== null
                    )
                ),
                tap((filterFns) => (this.activeFilters = filterFns.length)),
                share()
            );
        });
    }
}
