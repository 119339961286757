import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { FilterDataService } from '../../services/filter/filter-data.service';
import * as ResultCountActions from '../result-count/result-count.actions';
import { RootStoreService } from '../root-store.service';
import * as CountriesActions from './countries.actions';

@Injectable()
export class CountriesEffects {
    public requestCountries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CountriesActions.requestCountries),
            switchMap(() => this.filterDataService.getCountries()),
            withLatestFrom(
                this.rootStoreService.getRouterParams(),
                this.rootStoreService.getSelectedCountry(),
                (countries, params, selectedCountry) => ({
                    countries,
                    params,
                    selectedCountry,
                })
            ),
            tap(({ params, selectedCountry }) => {
                if (selectedCountry === null) {
                    const country = params.country;
                    if (country) {
                        this.rootStoreService.updateSelectedCountryByProperty(country, 'name');
                    }
                } else {
                    this.rootStoreService.updateSelectedCountryByProperty(selectedCountry.id, 'id');
                }
            }),
            switchMap(({ countries }) => [
                CountriesActions.receiveCountries({ payload: { countries } }),
                ResultCountActions.calculateResultCount(),
            ])
        )
    );

    public updateSelectedCountryByProperty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CountriesActions.updateSelectedCountryByProperty),
            switchMap(({ payload: { value }, property }) =>
                this.rootStoreService.getCountries().pipe(
                    map((countries) => {
                        const filteredCountry = countries.find((country) => country[property] === value);

                        return filteredCountry ?? null;
                    })
                )
            ),
            tap((selectedCountry) => {
                if (selectedCountry === null) {
                    this.rootStoreService.fetchComponents();
                    this.rootStoreService.updateSelectedComponentById(null);
                }
            }),
            switchMap((selectedCountry) => [
                CountriesActions.updateSelectedCountry({ payload: { selectedCountry } }),
                ResultCountActions.calculateResultCount(),
            ])
        )
    );

    public replaceSelectedCountryByProperty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CountriesActions.replaceSelectedCountryByProperty),
            switchMap(({ payload: { value }, property }) =>
                this.rootStoreService.getCountries().pipe(
                    map((countries) => {
                        const filteredCountry = countries.find((country) => country[property] === value);

                        return filteredCountry ?? null;
                    })
                )
            ),
            switchMap((selectedCountry) => [
                CountriesActions.updateSelectedCountry({ payload: { selectedCountry } }),
                ResultCountActions.calculateResultCount(),
            ])
        )
    );

    public constructor(
        private actions$: Actions,
        private filterDataService: FilterDataService,
        private rootStoreService: RootStoreService
    ) {}
}
