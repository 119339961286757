import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HeaderEventsDirective } from './header-events.directive';

@NgModule({
    declarations: [HeaderEventsDirective],
    imports: [CommonModule],
    exports: [HeaderEventsDirective],
})
export class HeaderEventsModule {}
