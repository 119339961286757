import { Component, Input } from '@angular/core';

@Component({
    selector: 'osc-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
    @Input() public icon = '';
    @Input() public text = '';
    @Input() public disabled = false;
    @Input() public type: 'primary' | 'secondary' = 'primary';
}
