import { ColumnType } from '@os-components';

export interface HttpError {
    errorCode: string;
    errorMessage: string;
    errorStatus: string;
    errorStatusText: string;
}

export interface CompareDataResponseBase {
    referenceProduct?: string;
    showOnlyDifferences?: boolean;
    productCompareDetails: ProductCompareDetail[];
}

export interface CompareDataResponse extends CompareDataResponseBase {
    rowHeadings: RowHeader[];
    error?: HttpError;
}

export interface CompareTable extends CompareDataResponseBase {
    rowHeadings: GroupedCompareHeadings;
}

export interface ProductCompareDetail {
    productId: string;
    productName: string;
    catalogDescription: string;
    customerMatNo?: string;
    productValues: CompareAttribute[];
    cadUrl: string;
    productDataUrl: string;
    technicalDocUrl: string;
}

export interface CompareAttribute {
    // to extend from "Attribute" -> Product Details
    diff?: boolean;
    value: string[];
    columnId: string;
}

// TODO: rename to "ProductChange"
export interface ProductChanges {
    productId: string;
    columnId: string;
    value: string[];
}

export interface RowHeader {
    columnId: string;
    longDescription: string;
    editable?: boolean;
    columnType: ColumnType;
    columnLength: number;
    columnDecimals: number;
    unit: string;
    rowType?: ProductAttributesType;
    diff?: boolean;
    /**
     * Tooltip content
     */
    infoText?: string;
}

export enum ProductAttributesType {
    TECHNICAL = 'TECHNICAL',
    CUSTOMER = 'CUSTOMER',
    PROD_INFO = 'PROD_INFO',
}

export const MappedProductAttributesType = {
    [ProductAttributesType.TECHNICAL]: 'technicalAttributes',
    [ProductAttributesType.CUSTOMER]: 'customerAttributes',
    [ProductAttributesType.PROD_INFO]: 'productInfoAttributes',
} as const;

export interface GroupedCompareHeadings {
    [MappedProductAttributesType.TECHNICAL]: RowHeader[];
    [MappedProductAttributesType.CUSTOMER]: RowHeader[];
    [MappedProductAttributesType.PROD_INFO]: RowHeader[];
}

export interface UpdateCompare {
    productIds?: string[];
    referenceProduct?: string;
    showOnlyDifferences?: boolean;
}
