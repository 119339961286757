import { arrayEqualsShallow } from './../../helpers/arrays/array-equals-shallow';
import { EXCLUDE_FROM_SHOW_ONLY_DIFFERENCES } from './compare.configuration';
import {
    CompareAttribute,
    CompareDataResponse,
    CompareTable,
    GroupedCompareHeadings,
    MappedProductAttributesType,
    ProductAttributesType,
    ProductChanges,
    ProductCompareDetail,
    RowHeader,
} from './compare.interface';

const groupCompareHeadings = (headings: RowHeader[]) =>
    headings.reduce<GroupedCompareHeadings>(
        (headingGroups, heading) => {
            const rowType = heading.rowType ?? ProductAttributesType.TECHNICAL;

            if (!(rowType in MappedProductAttributesType)) {
                return headingGroups;
            }

            const rowTypeName = MappedProductAttributesType[rowType];

            return {
                ...headingGroups,
                [rowTypeName]: [...headingGroups[rowTypeName], { ...heading, rowType }],
            };
        },
        {
            [MappedProductAttributesType.TECHNICAL]: [],
            [MappedProductAttributesType.CUSTOMER]: [],
            [MappedProductAttributesType.PROD_INFO]: [],
        }
    );

export const getCompareData = (compareData: CompareDataResponse): CompareTable => {
    const referenceProduct =
        compareData.productCompareDetails.find(
            (product) => product.productId === compareData.referenceProduct
        ) ?? compareData.productCompareDetails[0];
    let rowHeadings = compareData.rowHeadings;

    const productCompareDetails = compareData.productCompareDetails
        .map((product) => ({
            ...product,
            productValues: compareData.rowHeadings.map<CompareAttribute>((rowHeading) => {
                const itemByColumnId = (item: CompareAttribute) => item.columnId === rowHeading.columnId;
                const attribute = product.productValues.find(itemByColumnId);
                const referenceValue = referenceProduct.productValues.find(itemByColumnId);

                const diff =
                    attribute !== referenceValue &&
                    !arrayEqualsShallow(attribute?.value, referenceValue?.value);

                if (diff) {
                    rowHeadings = getHeadingsWithDiff(rowHeadings, rowHeading.columnId);
                }

                return attribute
                    ? { ...attribute, diff }
                    : { columnId: rowHeading.columnId, diff, value: [] };
            }),
        }))
        .map((product) => ({
            ...product,
            productValues: filterProductValues(
                product.productValues,
                rowHeadings,
                compareData.showOnlyDifferences === true
            ),
        }));

    return {
        ...compareData,
        referenceProduct: referenceProduct?.productId,
        rowHeadings: groupCompareHeadings(
            filterHeadings(rowHeadings, compareData.showOnlyDifferences === true, productCompareDetails)
        ),
        productCompareDetails,
    };
};

/**
 * Checks wheter the attribute should also be visible when enabled "only show difference".
 * See #384262
 *
 * @param value attribute name
 * @returns true, if it should be always visible, else false
 */
const isAlwaysVisible = (value: string) => EXCLUDE_FROM_SHOW_ONLY_DIFFERENCES.includes(value);

const filterProductValues = (
    productValues: CompareAttribute[],
    rowHeadings: RowHeader[],
    onlyDifferences: boolean
) =>
    productValues.filter(
        (valueItem) =>
            !onlyDifferences ||
            rowHeadings.some((heading) => heading.diff && heading.columnId === valueItem.columnId) ||
            isAlwaysVisible(valueItem.columnId)
    );

const filterHeadings = (
    headings: RowHeader[],
    onlyDifferences: boolean,
    productCompareDetails: ProductCompareDetail[]
) =>
    headings
        .filter((heading) => !onlyDifferences || heading.diff || isAlwaysVisible(heading.columnId))
        .filter((heading) => checkHeadingVisibility(heading, productCompareDetails));

const getHeadingsWithDiff = (headings: RowHeader[], columnId: string) =>
    headings.map((heading) => (heading.columnId === columnId ? { ...heading, diff: true } : heading));

const checkHeadingVisibility = (heading: RowHeader, productCompareDetails: ProductCompareDetail[]) =>
    productCompareDetails.some(
        (product) =>
            // check if any of the products has a value for the given heading/attribute …
            (product.productValues.find((attr) => attr.columnId === heading.columnId) as CompareAttribute)
                .value.length ||
            // … or if the heading has a different type than TECHNICAL
            // (in which case it should always be shown)
            heading.rowType !== ProductAttributesType.TECHNICAL
    );

/**
 * Updates the product compare details.
 *
 * @param productCompareDetails current state
 * @param changes new changes
 * @param merge true, if the changes should be merged into the current state. False, if the changes should completely overwrite the current state.
 * @returns new state
 */
export const updateProductCompareDetails = (
    productCompareDetails: ProductCompareDetail[],
    changes: ProductChanges[],
    merge: boolean
) =>
    productCompareDetails &&
    changes.reduce(
        (details, change) =>
            details.map((detail) =>
                detail.productId === change.productId
                    ? {
                          ...detail,
                          productValues: detail.productValues.map((value) =>
                              value.columnId === change.columnId
                                  ? {
                                        ...value,
                                        value: merge
                                            ? [...new Set([...value.value, ...change.value.filter((v) => v)])]
                                            : change.value.filter((v) => v),
                                    }
                                  : value
                          ),
                      }
                    : detail
            ),
        productCompareDetails
    );
