import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { OcmCreateEntryModule } from '../../../../../../../lib-os-components/src/public-api';
import { PreviewDashboardComponent } from './preview-dashboard.component';
import { PreviewDashboardService } from './preview-dashboard.service';

@NgModule({
    declarations: [PreviewDashboardComponent],
    imports: [CommonModule, FormsModule, OcmCreateEntryModule],
    providers: [PreviewDashboardService],
    exports: [PreviewDashboardComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PreviewDashboardModule {}
