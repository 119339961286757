import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { ToggleableView } from './toggleable-view';

/**
 * Directive with the ability to toggle its display.
 */
@Directive()
export abstract class ToggleableDirective {
    private view: ToggleableView;

    public constructor(viewContainerRef: ViewContainerRef, template: TemplateRef<any>) {
        this.view = new ToggleableView(viewContainerRef, template);
    }

    public setActive(active: boolean): void {
        this.view.updateState(active);
    }
}
