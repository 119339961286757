import { HttpBackend } from '@angular/common/http';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';

/* istanbul ignore next */
// @NOTE: https://github.com/ngx-translate/http-loader/pull/61
class Translations {
    private files: string[] = [];

    public addFile(file: string) {
        if (!this.files.includes(file)) {
            this.files.push(file);
        }
    }

    /**
     * Returns a TranslateHttpLoaderFactory for ngx-translate modules
     */
    public get factory() {
        this.addFile('./assets/i18n-osc/');

        // Version ngx-translate-multi-http-loader@9.3.1 uses HttpBackend
        // Reason for the update: https://stackoverflow.com/a/75482045/20557548
        // New Format for the files: https://github.com/rbalet/ngx-translate-multi-http-loader/releases/tag/v9.0.0
        // example can be found here https://github.com/rbalet/ngx-translate-multi-http-loader#string
        return (http: HttpBackend) => new MultiTranslateHttpLoader(http, this.files);
    }
}

/**
 * Singleton with multiple translation files.
 * The `factory` property returns a translateHttpLoaderFactory for usage in ngx-translate child modules.
 *
 * Usage in modules in your app:
 *
 * @example
 * const translateLoader = translateSingleton;
 * translateLoader.addFile('./assets/i18n/my-own-translations/');
 *
 * ...
 * TranslateModule.forChild({
 *  loader: {
 *    provide: TranslateLoader,
 *    useFactory: translateLoader.factory,
 *    deps: [HttpClient],
 * })
 */
export const translationsSingleton = new Translations();
