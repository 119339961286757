/**
 * Meant to be used in one of the following cases
 * 1) definition of breakpoints, e. g.:
 *    ```
 *    const breakpoints: Breakpoints = {
 *      mq2: '768px',
 *      mq3: '1280px',
 *    }
 *    ```
 * 2) definition of media queries, e. g.:
 *    ```
 *    const breakpoints: Breakpoints = {
 *      mq2: '(min-width: 768px)',
 *      mq3: '(min-width: 1280px)',
 *    }
 *    ```
 */
export type Breakpoints = { [mq: string]: string };

/**
 * Priority used when deciding which case matches the media query.
 * A lower value means higher priority (will match first).
 */
export enum MediaQueryMatchPriority {
    EXACT = 10,
    MIN = 20,
}
