import { Pipe, PipeTransform } from '@angular/core';
import { ListViewFilterFn } from './list-filter.interface';

@Pipe({
    name: 'filter',
})
export class FilterPipe implements PipeTransform {
    /**
     * Apply a list of filter functions to each item of a list of items.
     * If any of the functions returns false for an item, it gets removed from the list.
     */
    public transform<T>(items: T[] | null, filterFns: ListViewFilterFn<T>[] | null): T[] | null {
        if (!items || !filterFns || !filterFns.length) {
            return items;
        }

        // some = OR; every = AND; configure via pipe parameter?
        return items.filter((item) => filterFns.every((filterFn) => filterFn(item)));
    }
}
