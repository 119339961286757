<div class="header" [ngClass]="{ reference: isReferenceProduct }">
    <span class="title" *ngIf="isReferenceProduct" translate>COMPARE.REFERENCEPRODUCT</span>
    <div class="icons">
        <mat-icon
            *ngIf="isReferenceProduct; else makeReference"
            class="icon -active"
            svgIcon="push_pin"
            [title]="'COMPARE.IS_REFERENCE_PRODUCT' | translate"
            [attr.aria-label]="'COMPARE.IS_REFERENCE_PRODUCT' | translate"
        ></mat-icon>
        <ng-template #makeReference>
            <osc-icon-button
                icon="push_pin"
                [tooltip]="'COMPARE.SET_REFERENCE_PRODUCT' | translate"
                (click)="changeRefProduct()"
                class="icon"
            ></osc-icon-button>
        </ng-template>
        <osc-icon-button
            class="icon"
            icon="close"
            [tooltip]="'COMPARE.REMOVE_PRODUCT' | translate"
            (click)="removeProduct()"
        ></osc-icon-button>
    </div>
</div>
