import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgUseDirective } from './ng-use.directive';

@NgModule({
    declarations: [NgUseDirective],
    exports: [NgUseDirective],
    imports: [CommonModule],
})
export class NgUseModule {}
