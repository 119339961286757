import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NullableListViewFilterFn } from './list-filter.interface';
import { ListFilterService } from './list-filter.service';

/**
 * Handle interactions between list filter components and their respective {@link ListFilterDirective}.
 */
@Injectable({
    providedIn: null,
})
export class ListFilterItemService {
    private properties = new ReplaySubject<string[]>(1);
    private filterFunc = new ReplaySubject<NullableListViewFilterFn>(1);
    private unsubscribeFilterFuncSource = new Subject<void>();
    private resetFilter = new Subject<void>();
    private endResetSubscription = new Subject<void>();

    public constructor(listFilterService: ListFilterService) {
        listFilterService
            .resetAllFilters()
            .pipe(takeUntil(this.endResetSubscription))
            .subscribe(() => this.resetFilter.next());
    }

    public setFilterProperties(properties: string[]): void {
        this.properties.next(properties);
    }

    public getFilterProperties(): Observable<string[]> {
        return this.properties.asObservable();
    }

    public getFilterReset(): Observable<void> {
        return this.resetFilter.asObservable();
    }

    public setFilterFn<T>(fn: NullableListViewFilterFn<T>): void {
        this.filterFunc.next(fn);
    }

    public getFilterFn(): Observable<NullableListViewFilterFn> {
        return this.filterFunc.asObservable();
    }

    public setFilterFnSource<T>(source: Observable<NullableListViewFilterFn<T>>): void {
        source.pipe(takeUntil(this.unsubscribeFilterFuncSource)).subscribe((fn) => this.setFilterFn(fn));
    }

    public unsubscribeFilterFnSource(destroy = true) {
        this.unsubscribeFilterFuncSource.next();

        if (destroy) {
            this.unsubscribeFilterFuncSource.complete();
            this.properties.complete();
            this.filterFunc.complete();
            this.endResetSubscription.next();
            this.endResetSubscription.complete();
            this.resetFilter.complete();
        }
    }
}
