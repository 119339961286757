<div class="header">
    <!-- <mat-icon class="icon" svgIcon="share"></mat-icon> -->
    <osc-icon-button
        *ngIf="showShoppingCart"
        class="icon"
        icon="shopping_cart"
        [tooltip]="'COMPARE.ADD_TO_BASKET' | translate"
        (click)="addToShoppingCart()"
    ></osc-icon-button>
    <osc-icon-button
        *ngIf="showOpen"
        class="icon"
        icon="launch"
        [tooltip]="'COMPARE.GO_TO_PRODUCT' | translate"
        (click)="openProductDetailTab()"
    ></osc-icon-button>
</div>
<div class="content">
    <div class="link-list">
        <ul>
            <li>
                <button class="link" (click)="openOSFrame(technicalDocUrl)">
                    <mat-icon>chevron_right</mat-icon>
                    {{ 'PRODUCT.TECHNICAL_DOCU' | translate }}
                </button>
            </li>
            <li>
                <button class="link" (click)="openOSFrame(productDataUrl)">
                    <mat-icon>chevron_right</mat-icon>
                    {{ 'PRODUCT.PRODUCT_DATA' | translate }}
                </button>
            </li>
            <li>
                <button class="link" (click)="openOSFrame(cadUrl)">
                    <mat-icon>chevron_right</mat-icon>
                    {{ 'PRODUCT.CAD_DATA' | translate }}
                </button>
            </li>
        </ul>
    </div>
</div>
